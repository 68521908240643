import { type CSSProperties, type FC, type MouseEvent, useCallback, useMemo, useRef, useState } from 'react';

import { VIDEO_FORMATS, type VideoFormat } from '@cofenster/constants';
import {
  Button,
  Checkbox,
  Icon,
  IconButton,
  type MultiSelect,
  NativeVideoPlayer,
  PopoverMenuItem,
  SpinningIcon,
  Tooltip,
  Typography,
  VisuallyHidden,
  preventForwardProps,
  styled,
  useI18n,
  withPopoverMenu,
} from '@cofenster/web-components';

import type { Contributor } from '../../../../api/generated';
import type { ContributionRequestById } from '../../../../api/hooks/contributionRequest/useContributionRequestsById';
import { useCreateSceneFromContributionRequestAsset } from '../../../../api/hooks/contributionRequest/useCreateSceneFromContributionRequestAsset';
import { useWebManagerTracking } from '../../../../hooks/useWebManagerTracking';
import { useDeleteContributionsWithConfirmation } from './hooks/useDeleteContributionsWithConfirmation';
import type { ContributionItem } from './hooks/useFilterContributionRequests';

type VideoAsset = ContributionRequestById['videoAssets'][number];
type ImageAsset = ContributionRequestById['imageAssets'][number];

const isVideoAsset = (asset: VideoAsset | ImageAsset): asset is VideoAsset => {
  return asset.__typename === 'VideoAsset';
};

// 1. The actions are placed above, and this needs to sit above the actions.
// 2. Make sure checked checkboxes remain visible even when the card they belong
//    to is not interacted with.
const CheckboxWrapper = styled('label')(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(2),
  left: theme.spacing(2),
  zIndex: theme.zIndex.above + 1, // 1

  '[data-selected="true"] & > *': {
    opacity: 1, // 2
  },
}));

export const ContributionAssetListItem: FC<{
  videoFormat: VideoFormat;
  title: string;
  contributors?: Contributor[];
  onSceneCreated?: (sceneId: string) => unknown;
  newSceneIndex?: number;
  canUpdateProject: boolean;
  item: ContributionItem;
  multiSelection: MultiSelect<ContributionItem>;
}> = ({
  videoFormat,
  title,
  contributors = [],
  onSceneCreated,
  newSceneIndex,
  canUpdateProject,
  multiSelection,
  item,
}) => {
  const { asset, contributionRequest } = item;
  const contributionRequestId = contributionRequest.id;

  return (
    <>
      <ContributionAssetContainer data-selected={multiSelection.selectedItems.length > 0}>
        {isVideoAsset(asset) ? (
          <VideoContribution
            asset={asset}
            newSceneIndex={newSceneIndex}
            videoFormat={videoFormat}
            contributionRequestId={contributionRequestId}
            onSceneCreated={onSceneCreated}
          />
        ) : (
          <ImageContribution
            asset={asset}
            newSceneIndex={newSceneIndex}
            videoFormat={videoFormat}
            contributionRequestId={contributionRequestId}
            onSceneCreated={onSceneCreated}
          />
        )}
        <CheckboxWrapper data-interactive>
          <Checkbox
            checked={multiSelection.isSelected(item)}
            onChange={() => multiSelection.toggleSelection(item)}
            id={`select-${item.asset.id}-${item.contributionRequest.id}`}
          />
          <VisuallyHidden i18nParams={{ name: title }}>
            i18n.projectContributions.contributions.bulk.label
          </VisuallyHidden>
        </CheckboxWrapper>
      </ContributionAssetContainer>
      <ContributionLabel
        item={item}
        contributors={contributors}
        contributionRequestTitle={title}
        canUpdateProject={canUpdateProject}
      />
    </>
  );
};

// 1. Mark interactive children as hidden until the thumbnail is interacted with
//    either hover or focus. It is important to use `> *` as there is a visual
//    glitch when the opacity and the blur are applied on different elements.
//    See: https://x.com/KittyGiraudel/status/1713861958733033489?s=20
const ContributionAssetContainer = styled('div')(() => ({
  position: 'relative',
  '> [data-interactive] > *': {
    opacity: 0, // 1
    transition: 'opacity 250ms',
  },
  '&:hover > [data-interactive] > *, &:focus-within > [data-interactive] > *': {
    opacity: 1, // 1
  },
}));

const Video = styled(
  NativeVideoPlayer,
  preventForwardProps(['assetReady'])
)<{ assetReady: boolean }>(({ theme, assetReady }) => ({
  aspectRatio: 'var(--aspect-ratio)',
  backgroundColor: assetReady ? '#000' : theme.palette.brand.linen50,
}));

const AddContributionToProjectButtonContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: theme.spacing(6),
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
}));

const LoadingAssetContainer = styled('div')(() => ({
  position: 'absolute',
  inset: 0,
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
}));

const VideoContribution: FC<{
  asset: VideoAsset;
  videoFormat: VideoFormat;
  contributionRequestId: string;
  onSceneCreated?: (sceneId: string) => unknown;
  newSceneIndex?: number;
}> = ({ asset, videoFormat, contributionRequestId, onSceneCreated, newSceneIndex }) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const format = VIDEO_FORMATS[videoFormat];
  const [hasControls, setHasControls] = useState(false);
  const tracking = useWebManagerTracking();

  const { createSceneFromContributionRequestAsset, loading } = useCreateSceneFromContributionRequestAsset();
  const onAddVideo = useCallback(async () => {
    const { data } = await createSceneFromContributionRequestAsset([
      {
        contributionRequestId,
        assetId: asset.id,
        index: newSceneIndex,
      },
    ]);

    tracking.trackEvent({
      event: 'addContributionsToProject',
      details: {
        assetIds: [asset.id],
      },
    });

    if (onSceneCreated) {
      const sceneId = data?.createSceneFromContributionRequestAsset?.[0]?.id;
      if (sceneId) {
        onSceneCreated(sceneId);
      }
    }
  }, [createSceneFromContributionRequestAsset, asset, contributionRequestId, onSceneCreated, newSceneIndex, tracking]);

  return (
    <>
      <Video
        ref={videoRef}
        assetReady={asset.status === 'Ready'}
        src={asset.videoUrl ?? undefined}
        poster={asset.thumbnailUrl ?? undefined}
        style={{ '--aspect-ratio': String(format.aspectRatio) } as CSSProperties}
        controls={hasControls}
        actions={['FULLSCREEN']}
        objectFit="contain"
        tabIndex={0}
        onFocus={() => setHasControls(true)}
        onBlur={() => setHasControls(false)}
        onMouseEnter={(event: MouseEvent<HTMLVideoElement> & { target: HTMLVideoElement }) => event.target.focus()}
        onMouseLeave={(event: MouseEvent<HTMLVideoElement> & { target: HTMLVideoElement }) => event.target.blur()}
      />
      {asset.status === 'Ready' ? (
        <AddContributionToProjectButtonContainer
          data-interactive
          onMouseEnter={() => videoRef.current?.focus()}
          onMouseLeave={() => videoRef.current?.blur()}
        >
          <Button variant="blurred" onClick={onAddVideo} loading={loading}>
            i18n.projectContributions.contributions.addToVideoButton
          </Button>
        </AddContributionToProjectButtonContainer>
      ) : (
        <LoadingAssetContainer>
          <SpinningIcon type="GearIcon" color="carbon" />
        </LoadingAssetContainer>
      )}
    </>
  );
};

const Image = styled(
  'img',
  preventForwardProps(['assetReady'])
)<{ assetReady: boolean }>(({ theme, assetReady }) => ({
  width: '100%',
  height: '100%',
  borderRadius: theme.shape['borderRadius-l'],
  aspectRatio: 'var(--aspect-ratio)',
  objectFit: 'contain',
  backgroundColor: assetReady ? '#000' : theme.palette.brand.linen50,
}));

const ImageContribution: FC<{
  asset: ImageAsset;
  videoFormat: VideoFormat;
  contributionRequestId: string;
  onSceneCreated?: (sceneId: string) => unknown;
  newSceneIndex?: number;
}> = ({ asset, videoFormat, contributionRequestId, onSceneCreated, newSceneIndex }) => {
  const { translate } = useI18n();
  const { aspectRatio } = VIDEO_FORMATS[videoFormat];
  const tracking = useWebManagerTracking();

  const { createSceneFromContributionRequestAsset, loading } = useCreateSceneFromContributionRequestAsset();
  const onAddImage = useCallback(async () => {
    const { data } = await createSceneFromContributionRequestAsset([
      {
        contributionRequestId,
        assetId: asset.id,
        index: newSceneIndex,
      },
    ]);
    tracking.trackEvent({
      event: 'addContributionsToProject',
      details: {
        assetIds: [asset.id],
      },
    });
    const sceneId = data?.createSceneFromContributionRequestAsset?.[0]?.id;
    if (sceneId) onSceneCreated?.(sceneId);
  }, [createSceneFromContributionRequestAsset, asset, contributionRequestId, onSceneCreated, newSceneIndex, tracking]);

  return (
    <>
      <Image
        assetReady={asset.status === 'Ready'}
        src={asset.imageUrl ?? undefined}
        style={{ '--aspect-ratio': String(aspectRatio) } as CSSProperties}
        alt={translate('projectContributions.contributions.imageContribution.alt', {
          date: new Date(asset.createdAt).toLocaleString(),
        })}
      />
      {asset.status === 'Ready' ? (
        <AddContributionToProjectButtonContainer data-interactive>
          <Button variant="blurred" onClick={onAddImage} loading={loading}>
            i18n.projectContributions.contributions.addToVideoButton
          </Button>
        </AddContributionToProjectButtonContainer>
      ) : (
        <LoadingAssetContainer>
          <SpinningIcon type="GearIcon" color="carbon" />
        </LoadingAssetContainer>
      )}
    </>
  );
};

const ContributionLabelContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: theme.spacing(1),
}));

const CenteredChildrenContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const RightColumn = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

const ContributionLabel: FC<{
  item: ContributionItem;
  contributors?: Contributor[];
  contributionRequestTitle: string;
  canUpdateProject: boolean;
}> = ({ contributors = [], contributionRequestTitle, canUpdateProject, item }) => {
  const { asset } = item;

  return (
    <ContributionLabelContainer>
      <div>
        <TwoLinesTypography variant="h6" component="p">
          {contributionRequestTitle}
        </TwoLinesTypography>

        {contributors.length > 0 && (
          <Typography variant="m" component="p">
            {contributors.map((it) => it.name).join(', ')}
          </Typography>
        )}
      </div>
      <RightColumn>
        {!!asset?.scenes?.length && (
          <Tooltip title="i18n.projectContributions.contributions.includedTooltip">
            {/* Without container for the icon tooltip does not work */}
            <CenteredChildrenContainer>
              <Icon tabIndex={0} type="CheckIcon" background={{ size: 's', color: 'blue' }} color="white" size="xs" />
            </CenteredChildrenContainer>
          </Tooltip>
        )}
        {canUpdateProject && <MoreOptions item={item} />}
      </RightColumn>
    </ContributionLabelContainer>
  );
};

const MoreOptions: FC<{ item: ContributionItem }> = ({ item }) => {
  const { asset, contributionRequest } = item;
  const deleteWithConfirmation = useDeleteContributionsWithConfirmation([item]);

  const downloadUrl = asset?.downloadUrl;
  const tracking = useWebManagerTracking();
  const trackDownload = useCallback(() => {
    tracking.trackEvent({
      event: 'requestAssetDownloaded',
      details: {
        requestId: contributionRequest.id,
        requestAssetId: asset.id,
      },
    });
  }, [tracking, contributionRequest.id, asset]);

  const MoreOptionsButton = useMemo(
    () =>
      withPopoverMenu(IconButton, {
        children: [
          downloadUrl && (
            <PopoverMenuItem
              icon="DownloadIcon"
              href={downloadUrl}
              target="_blank"
              download
              data-testid="contribution-recording-download-asset"
              onClick={trackDownload}
              component="a"
              key="download-asset"
            >
              i18n.global.download
            </PopoverMenuItem>
          ),
          <PopoverMenuItem key="delete" icon="TrashIcon" color="negative" onClick={() => deleteWithConfirmation()}>
            i18n.global.delete
          </PopoverMenuItem>,
        ].filter(Boolean),
      }),
    [downloadUrl, deleteWithConfirmation, trackDownload]
  );

  return <MoreOptionsButton icon="ThreeDotsIcon" label="i18n.global.more" iconSize="m" />;
};

const TwoLinesTypography = styled(Typography)(() => ({
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  whiteSpace: 'break-spaces',
  wordWrap: 'break-word',
}));
