import type { FC } from 'react';
import { useParams } from 'react-router-dom';

import { Redirect } from '../../../../components/common/Redirect';
import { type Project, useProject } from '../../../../contexts/project/useProject';
import type { ProjectEditorRouteParams } from '../../../../routes';

import { SidebarContent } from './components/SidebarContent';
import { SidebarHeader } from './components/SidebarHeader';
import { Thumbnails } from './components/Thumbnails';
import { useRedirectToScene } from './hooks/useRedirectToScene';
import { useScenePopoverActionShortcuts } from './hooks/useScenePopoverActionShortcuts';

export const EditorSceneAssetThumbs: FC = () => {
  const { sceneId } = useParams() as ProjectEditorRouteParams;
  const { project } = useProject();

  useRedirectToScene();

  if (!project) return <Redirect to="home" />;

  return (
    <>
      <SidebarHeader project={project} currentSceneId={sceneId} />

      <SidebarContent>
        <Thumbnails project={project} currentSceneId={sceneId} />
      </SidebarContent>

      {sceneId && <EditorSceneAssetShortcuts sceneId={sceneId} project={project} />}
    </>
  );
};

const EditorSceneAssetShortcuts: FC<{ project: Project; sceneId: string }> = ({ project, sceneId }) => {
  useScenePopoverActionShortcuts(project, sceneId);
  return null;
};
