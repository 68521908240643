import { type CSSProperties, type FC, useCallback } from 'react';

import {
  Checkbox,
  Icon,
  NativeVideoPlayer,
  Text,
  Translatable,
  VisuallyHidden,
  styled,
  useFormatTimeDistance,
  usePlayVideoOnHover,
} from '@cofenster/web-components';

import { useProjectThumbnail } from '../../../api/hooks/project/useProjectThumbnail';
import type { Project } from '../../../api/hooks/project/useProjects';
import { useBulkSelection } from '../../../contexts/bulkSelection/useBulkSelection';
import { useTeamPermissionStatus } from '../../../contexts/user/TeamPermissionRestriction';
import { useWebManagerTracking } from '../../../hooks/useWebManagerTracking';
import { RouterButton } from '../../button/RouterButton';
import { Masthead } from './Masthead';
import { PopoverMenu } from './PopoverMenu';
import { ProjectFormatInformation } from './ProjectFormatInformation';
import { Name } from './ProjectName';

// 1. Not ideal, this should be done at the container level, not inside the
//    component itself.
// 2. Create a new stacking context for each thumbnail. The checkbox container
//    has a higher z-index for instance, and this ensures it does not appear
//    above other layout elements like the bulk selector.
const Container = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(1), // 1
  isolation: 'isolate', // 2
}));

const ActionsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(1),
  padding: theme.spacing(2),

  position: 'absolute',
  inset: 0,
  zIndex: theme.zIndex.above,
}));

// 1. Instruct the image to cover as much of the container as possible without
//    overflowing at all.
// 2. Absolutely center the image in the container.
// 3. Make sure the image sits on top of the blurred background.
const Image = styled('img')(() => ({
  display: 'block',
  height: '100%', // 1
  width: '100%', // 1
  objectFit: 'contain', // 1
  margin: 'auto', // 2
  zIndex: 2, // 3
}));

const ContentWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: theme.spacing(1),
}));

// 1. The actions are placed above, and this needs to sit above the actions.
// 2. Make sure checked checkboxes remain visible even when the card they belong
//    to is not interacted with.
const CheckboxWrapper = styled('label')(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(2),
  left: theme.spacing(2),
  zIndex: theme.zIndex.above + 1, // 1

  '[data-selected="true"] & > *': {
    opacity: 1, // 2
  },
}));

export const ProjectThumbnail: FC<{
  project: Project;
  projectFolderId?: string;
  withOwner?: boolean;
  withBulkSelection?: boolean;
  refetchQueries?: string[];
}> = ({ project, projectFolderId, withOwner = true, withBulkSelection = true, refetchQueries = [] }) => {
  const formatTimeDistance = useFormatTimeDistance();
  const { selection, toggleProject } = useBulkSelection(withBulkSelection);
  const canUseBulkSelection = useTeamPermissionStatus({ or: ['ProjectUpdate', 'ProjectDelete'] }) === 'ALLOWED';
  const tracking = useWebManagerTracking();
  const { ref: videoRef, ...hoverProps } = usePlayVideoOnHover();

  const hasSelection = !!selection.projects.length;
  const isChecked = selection.projects.includes(project.id);

  const onChange = useCallback(() => {
    toggleProject(project.id);

    tracking.trackEvent({
      event: isChecked ? 'projectBulkDeselected' : 'projectBulkSelected',
    });
  }, [toggleProject, project.id, tracking, isChecked]);

  const thumbnailUrl = useProjectThumbnail(project);
  const videoUrl = project.videoAsset?.videoUrl;

  return (
    <Container data-selected={hasSelection} data-testid="project-tile" id={`project-${project.id}`}>
      <Masthead
        style={{ '--image': thumbnailUrl ? `url(${thumbnailUrl})` : undefined } as CSSProperties}
        onMouseEnter={videoUrl ? hoverProps.onMouseEnter : undefined}
        onMouseLeave={videoUrl ? hoverProps.onMouseLeave : undefined}
      >
        {videoUrl ? (
          <NativeVideoPlayer
            ref={videoRef}
            src={videoUrl}
            poster={thumbnailUrl ?? undefined}
            controls={false}
            muted
            loop
            objectFit="contain"
          />
        ) : (
          thumbnailUrl && <Image src={thumbnailUrl} alt="" loading="lazy" width={357} height={201} />
        )}

        <ActionsWrapper data-interactive>
          <RouterButton
            variant="blurred"
            to="project"
            data-testid="project-edit-button"
            params={{ projectId: project.id }}
            withViewTransition
            startIcon={<Icon size="s" type="ScissorsIcon" />}
            i18nParams={{ name: <VisuallyHidden>{project.name}</VisuallyHidden> }}
          >
            i18n.projects.projectCard.edit
          </RouterButton>
          {videoUrl && (
            <RouterButton
              variant="blurred"
              to="projectVideo"
              params={{ projectId: project.id }}
              withViewTransition
              startIcon={<Icon size="s" type="PlayIcon" />}
              i18nParams={{ name: <VisuallyHidden>{project.name}</VisuallyHidden> }}
            >
              i18n.projects.projectCard.watch
            </RouterButton>
          )}
        </ActionsWrapper>

        {withBulkSelection && canUseBulkSelection && (
          <CheckboxWrapper htmlFor={`select-${project.id}`} data-interactive>
            <Checkbox checked={isChecked} onChange={onChange} id={`select-${project.id}`} />
            <VisuallyHidden i18nParams={{ name: project.name }}>i18n.projects.projectCard.bulk.label</VisuallyHidden>
          </CheckboxWrapper>
        )}

        <ProjectFormatInformation completedSceneCount={project.completedSceneCount} videoFormat={project.videoFormat} />
      </Masthead>
      <ContentWrapper>
        <div>
          <Name variant="h5" component="p" color="inherit" data-testid="project-tile-name">
            {project.name}
          </Name>
          <Text variant="s" color="grey700">
            {withOwner && project.creator && (
              <>
                <Translatable i18nParams={{ name: project.creator.name }}>
                  i18n.projects.projectCard.creator
                </Translatable>{' '}
                ·{' '}
              </>
            )}
            <Translatable
              i18nParams={{
                timeAgo: formatTimeDistance(new Date(project.updatedAt ?? project.createdAt)),
              }}
            >
              i18n.projects.projectCard.updatedAt
            </Translatable>
          </Text>
        </div>
        <div>
          <PopoverMenu project={project} projectFolderId={projectFolderId} refetchQueries={refetchQueries} />
        </div>
      </ContentWrapper>
    </Container>
  );
};
