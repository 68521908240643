import { type FC, type PropsWithChildren, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { BlankButton, GlobalStyles, Icon, Typography, styled } from '@cofenster/web-components';

import { useUser } from '../../../contexts/user/useUser';

const Banner = styled('div')(({ theme }) => ({
  position: 'fixed',
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: theme.zIndex.top,

  backgroundColor: theme.palette.brand.admin,
  color: theme.palette.brand.white,
  padding: theme.spacing(1.5),

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(1),

  transform: 'translateY(100%) translateY(-5px)',
  transition: '100ms transform',

  '&:hover, &:focus-within': {
    transform: 'translateY(0%)',
  },
}));

const Button = styled(BlankButton)(({ theme }) => ({
  textDecoration: 'underline',
  font: 'inherit',
  color: 'inherit',
  borderRadius: theme.shape.borderRadius,

  '&:hover': {
    color: theme.palette.brand.blue100,
  },

  '&:focus-visible': theme.mixins.focusRing,
}));

const AttentionRing = {
  content: '""',
  position: 'fixed',
  inset: 0,
  border: '5px solid deeppink',
  zIndex: 1000,
  pointerEvents: 'none',
};

export const ImpersonationBanner: FC<PropsWithChildren> = ({ children }) => {
  const { user, signout } = useUser();
  const params = useParams();
  const isDynamicRoute = Object.keys(params).length > 0;

  const handleTerminate = useCallback(async () => {
    await signout();
    window.close();
  }, [signout]);

  const handleReload = useCallback(() => {
    // If currently on a dynamic route (any route that contains 1+ dynamic
    // parameter), literally rewrite the URL to go to the home page as that URL
    // won’t work when the impersonation is over. Otherwise, simply reload the
    // page.
    if (isDynamicRoute) window.location.href = '/';
    else window.location.reload();
  }, [isDynamicRoute]);

  if (!user?.isImpersonated) {
    return <>{children}</>;
  }

  return (
    <>
      <GlobalStyles styles={{ 'body::before': AttentionRing }} />
      <Banner>
        <Icon type="UserSwitchIcon" />
        <Typography color="white">
          You are currently impersonating <strong>{user.name}</strong>.{' '}
          <Button type="button" onClick={handleTerminate}>
            Terminate impersonation
          </Button>
          , or{' '}
          <Button type="button" onClick={handleReload}>
            reload
          </Button>{' '}
          if you have already.
        </Typography>
      </Banner>
    </>
  );
};
