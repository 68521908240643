import { format } from 'date-fns';
import { useCallback } from 'react';

import type { VideoFormat } from '@cofenster/constants';

import { useCreateProject } from '../../api/hooks/project/useCreateProject';
import { type SceneType, useCreateScene } from '../../api/hooks/scene/useCreateScene';
import { useI18n } from '../../i18n';
import { useWebManagerTracking } from '../useWebManagerTracking';

export const useCreateProjectWithScene = () => {
  const [createProject] = useCreateProject();
  const [createScene] = useCreateScene();
  const { translate } = useI18n();
  const tracking = useWebManagerTracking();

  return useCallback(
    async ({
      folderId,
      videoFormat,
      sceneType,
      source,
    }: {
      folderId: string;
      videoFormat: VideoFormat;
      sceneType: SceneType;
      source: 'screen record button' | 'webcam record button';
    }) => {
      const name = `${translate(`sceneTypes.${sceneType}`)}-${format(new Date(), 'yyyy-MM-dd')}`;

      const project = await createProject(folderId, {
        videoFormat,
        name,
      });

      const data = project.data?.createProject;
      const projectId = data?.id;
      const templateId = data?.template.id;

      if (!projectId || !templateId) return { error: true };

      const scene = await createScene(projectId, { index: 0, type: sceneType });

      const sceneId = scene.data?.createScene?.id;
      if (!sceneId) return { error: true };

      tracking?.trackEvent({
        event: 'projectCreated',
        details: {
          projectId,
          projectName: name,
          videoFormat,
          source,
          folderId: folderId,
        },
      });

      return {
        projectId,
        sceneId,
      };
    },
    [createProject, createScene, translate, tracking]
  );
};
