import { useField } from 'formik';
import { type FC, useCallback, useState } from 'react';

import { MimeTypes } from '@cofenster/constants';
import {
  AbsoluteDeleteIcon,
  AssetDropzone,
  Box,
  DropzoneContainer,
  DropzoneStatus,
  styled,
  useI18n,
} from '@cofenster/web-components';

import type { ImageAssetFragmentFragment as ImageAssetFragment } from '../../../../../api/generated';
import { useUpdateProject } from '../../../../../api/hooks/project/useUpdateProject';
import { useUpdateTheme } from '../../../../../api/hooks/theme/useUpdateTheme';
import { useImageAssetUpload } from '../../../../../hooks/upload/useImageAssetUpload';
import { useConfirmDialog } from '../../../../../hooks/useConfirmDialog';
import { Sentry } from '../../../../../sentry';

const LogoContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const Logo = styled('img')(({ theme }) => ({
  maxHeight: 130,
  width: '100%',
  padding: theme.spacing(1),
}));

const useThemeLogoUpdate = (themeId: string | undefined, projectId: string | undefined) => {
  const updateTheme = useUpdateTheme();
  // Updating the logo has an impact on the render description and the preview
  // so it needs to be refetched.
  const [updateProject] = useUpdateProject({ refetchQueries: ['Project', 'ProjectRenderDescription'] });

  return useCallback(
    async (logoAssetId: string | null) => {
      if (!themeId) return;
      await updateTheme(themeId, { logoAssetId });
      if (projectId) await updateProject(projectId, { withLogo: !!logoAssetId });
    },
    [themeId, projectId, updateTheme, updateProject]
  );
};

type ThemeLogoUploadProps = {
  themeId: string | undefined;
  imageAsset: ImageAssetFragment | null | undefined;
  projectId?: string | undefined;
  name?: string;
};

export const ThemeLogoUpload: FC<ThemeLogoUploadProps> = ({ imageAsset, themeId, projectId, name = 'logoAssetId' }) => {
  const [hasUploaded, setHasUploaded] = useState(false);
  const [imageAssetUpload, progress] = useImageAssetUpload();
  const themeLogoUpdate = useThemeLogoUpdate(themeId, projectId);
  const [fileName, setFileName] = useState<string | undefined>(undefined);
  const [, { error }, { setValue: setFieldValue, setError }] = useField({ name });
  const { translate } = useI18n();

  const onUpload = async (file: File) => {
    try {
      setError(undefined);
      const logoAssetId = await imageAssetUpload(file);
      await themeLogoUpdate(logoAssetId);
      setFieldValue(logoAssetId);
      setHasUploaded(!!logoAssetId);
      setFileName(file.name);
      return logoAssetId;
    } catch (error) {
      Sentry.captureException(error);
      setError(error instanceof Error ? error.message : 'i18n.global.error.generic.unknown');
    }
  };

  const confirmDeleteFile = useConfirmDialog({
    title: 'i18n.dialogs.DeleteAssetDialog.headline',
    content: 'i18n.dialogs.DeleteAssetDialog.text',
    confirm: 'i18n.dialogs.DeleteAssetDialog.button',
  });

  const handleClearField = useCallback(() => {
    setError(undefined);
    setFieldValue(null);
    setHasUploaded(false);
  }, [setError, setFieldValue]);

  const handleDeleteFile = useCallback(async () => {
    if (await confirmDeleteFile()) {
      await themeLogoUpdate(null);
      handleClearField();
    }
  }, [confirmDeleteFile, themeLogoUpdate, handleClearField]);

  if (error) {
    return (
      <DropzoneContainer size="medium">
        <DropzoneStatus status="error" text="i18n.sceneImage.error" onCancel={handleClearField} />
      </DropzoneContainer>
    );
  }

  if (imageAsset?.imageUrl) {
    return (
      <DropzoneContainer size="medium">
        <Box fullHeight backgroundColor="grey50">
          <LogoContainer>
            <Logo src={imageAsset.imageUrl} alt="logo" data-testid="logo" />
            <AbsoluteDeleteIcon onClick={handleDeleteFile} data-testid="logo-delete-button" />
          </LogoContainer>
        </Box>
      </DropzoneContainer>
    );
  }

  if (progress !== undefined) {
    return (
      <DropzoneContainer size="medium">
        <DropzoneStatus
          status="uploading"
          text={fileName}
          hintText="i18n.sceneImage.uploading"
          progress={progress || 0}
        />
      </DropzoneContainer>
    );
  }

  if (imageAsset?.status === 'Waiting' || hasUploaded) {
    return (
      <DropzoneContainer size="medium">
        <DropzoneStatus status="processing" text={fileName} hintText="i18n.sceneImage.processing" />
      </DropzoneContainer>
    );
  }

  return (
    <DropzoneContainer size="medium">
      <AssetDropzone
        mime={MimeTypes.image}
        maxSize={2 * 1024 * 1024}
        onFile={onUpload}
        icon="CloudUploadIcon"
        text="i18n.common.logoUpload.text"
        hintText={translate('common.logoUpload.hint', { fileFormat: Object.values(MimeTypes.image).flat().join(', ') })}
        data-testid="logo-upload"
      />
    </DropzoneContainer>
  );
};
