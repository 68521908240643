import { type FC, useCallback, useMemo } from 'react';

import { CurrentTimeOverTime, styled, usePlayPauseKeyboardHandler } from '@cofenster/web-components';

import { useEditorPlayer } from '../../../../contexts/editorPlayer/useEditorPlayer';
import { useWebManagerTracking } from '../../../../hooks/useWebManagerTracking';
import { PlayerActions } from '../../components/PlayerActions';
import { usePreviewDetails } from '../../hooks/usePreviewDetails';

export const ActionsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(2),
  width: 'max-content',
  margin: 'var(--project-editor-player-actions-spacing) auto 0',
}));

const useEditorPlayerWithTracking = () => {
  const { play, pause, gotoNextScene, gotoPreviousScene } = useEditorPlayer();
  const getPreviewDetails = usePreviewDetails();
  const tracking = useWebManagerTracking();

  const playWithTracking = useCallback(() => {
    tracking.trackEvent({
      event: 'ScenePreviewPlayed',
      details: getPreviewDetails({ videoPlayerStatus: 'playing' }),
    });

    play();
  }, [tracking, getPreviewDetails, play]);

  const pauseWithTracking = useCallback(() => {
    tracking.trackEvent({
      event: 'ScenePreviewPaused',
      details: getPreviewDetails({ videoPlayerStatus: 'paused' }),
    });

    pause();
  }, [tracking, getPreviewDetails, pause]);

  const goToPreviousSceneWithTracking = useCallback(() => {
    if (!gotoPreviousScene) return;

    tracking.trackEvent({
      event: 'ScenePreviewPrevious',
      details: getPreviewDetails(),
    });

    gotoPreviousScene();
  }, [tracking, getPreviewDetails, gotoPreviousScene]);

  const goToNextSceneWithTracking = useCallback(() => {
    if (!gotoNextScene) return;

    tracking.trackEvent({
      event: 'ScenePreviewNext',
      details: getPreviewDetails(),
    });

    gotoNextScene();
  }, [tracking, getPreviewDetails, gotoNextScene]);

  return useMemo(
    () => ({
      play: playWithTracking,
      pause: pauseWithTracking,
      goToNextScene: gotoNextScene ? goToNextSceneWithTracking : undefined,
      goToPreviousScene: gotoPreviousScene ? goToPreviousSceneWithTracking : undefined,
    }),
    [
      playWithTracking,
      pauseWithTracking,
      gotoNextScene,
      goToNextSceneWithTracking,
      gotoPreviousScene,
      goToPreviousSceneWithTracking,
    ]
  );
};

export const EditorPlayerActions: FC<{ duration?: number }> = ({ duration: providedDuration }) => {
  const {
    duration: totalVideoDuration,
    currentTime,
    paused,
    isVideoBuffering,
    hasPreviewLoadingError,
  } = useEditorPlayer();
  const { play, pause, goToNextScene, goToPreviousScene } = useEditorPlayerWithTracking();

  // This component is used by the excluded scenes as well, in which case the
  // duration used should be the one of the scene, and not the one of the entire
  // video, so we provide a way to overwrite the duration via the props.
  const duration = providedDuration ?? totalVideoDuration;

  usePlayPauseKeyboardHandler(paused, play, pause);

  return (
    <ActionsContainer>
      <PlayerActions
        gotoPreviousScene={goToPreviousScene}
        gotoNextScene={goToNextScene}
        play={play}
        pause={pause}
        paused={paused}
        disabled={hasPreviewLoadingError || isVideoBuffering}
      />
      <CurrentTimeOverTime
        currentTime={currentTime}
        duration={duration}
        timeFormat={duration > 60 * 60 ? 'hh:mm:ss' : 'mm:ss'}
      />
    </ActionsContainer>
  );
};
