import { useCallback } from 'react';
import { type Params, useNavigate } from 'react-router-dom';

import { generatePath } from '../../utilities/generatePath';

type GotoOptions = {
  state?: unknown;
  search?: { [key: string]: string } | string;
};

export type GotoCallback = (route: string, params?: Params, options?: GotoOptions) => void;

export const createQueryString = (params?: { [key: string]: string } | string) => {
  if (!params) return '';
  if (typeof params === 'string') return params;

  const searchParams = new URLSearchParams();
  for (const [key, value] of Object.entries(params)) {
    searchParams.append(key, value);
  }

  return `?${searchParams.toString()}`;
};

export const useGoto = ({ replace = false } = {}): GotoCallback => {
  const navigate = useNavigate();

  return useCallback(
    (route, params = {}, options?: GotoOptions) => {
      navigate(
        {
          pathname: generatePath(route, params),
          search: createQueryString(options?.search),
        },
        { state: options?.state, replace }
      );
    },
    [navigate, replace]
  );
};
