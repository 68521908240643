import { useSnackbars } from '@cofenster/web-components';
import { useCallback, useMemo } from 'react';
import { useCreateSceneFromContributionRequestAsset } from '../../../../../api/hooks/contributionRequest/useCreateSceneFromContributionRequestAsset';
import { useWebManagerTracking } from '../../../../../hooks/useWebManagerTracking';
import type { ContributionItem } from './useFilterContributionRequests';

export const useAddContributionsToProject = (items: ContributionItem[]) => {
  const { createSceneFromContributionRequestAsset, loading } = useCreateSceneFromContributionRequestAsset();
  const { openSnackbar } = useSnackbars();
  const tracking = useWebManagerTracking();

  const onAdd = useCallback(async () => {
    const { data } = await createSceneFromContributionRequestAsset(
      items.map((item) => ({
        assetId: item.asset.id,
        contributionRequestId: item.contributionRequest.id,
      }))
    );
    tracking.trackEvent({
      event: 'addContributionsToProject',
      details: {
        assetIds: items.map((item) => item.asset.id),
      },
    });
    openSnackbar({
      variant: 'info',
      children: 'i18n.projectContributions.contributions.snackbar.contributionAdded',
      i18nParams: { count: data?.createSceneFromContributionRequestAsset?.length ?? 0 },
    });
  }, [createSceneFromContributionRequestAsset, items, openSnackbar, tracking.trackEvent]);

  return useMemo(
    () => ({
      onAdd,
      loading,
    }),
    [onAdd, loading]
  );
};
