import { Icon, SearchField, VisuallyHidden } from '@cofenster/web-components';
import type { FC } from 'react';
import { useProjectTemplatesSearchFilter } from '../../contexts/projectTemplateSearchFilter/useProjectTemplatesSearchFilter';

export const ProjectTemplatesSearchField: FC = () => {
  const { filter, setSearch } = useProjectTemplatesSearchFilter();

  return (
    <>
      <VisuallyHidden as="label" htmlFor="search-field-search-project-templates">
        i18n.projectTemplates.page.search.label
      </VisuallyHidden>
      <SearchField
        id="search-field-search-project-templates"
        name="search"
        label=""
        placeholder="i18n.projectTemplates.page.search.label"
        search={filter.search || ''}
        onSearch={setSearch}
        minLength={2}
        alwaysExtended
        autoFocus
        fullWidth
        InputProps={{
          startAdornment: <Icon type="SearchIcon" size="m" color="grey600" />,
        }}
      />
    </>
  );
};
