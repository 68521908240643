import { GlobalEN } from '@cofenster/web-components';

import { SceneAssetLifecycleTranslationsEN } from '../../../../web-actor/src/components/capture/translations/EN';

export const EN = {
  ...GlobalEN,
  ...SceneAssetLifecycleTranslationsEN,
  'sceneTypes.color': 'Color',
  'sceneTypes.image': 'Image',
  'sceneTypes.screenRecording': 'Screen recording',
  'sceneTypes.video': 'Video',
  'auth.button.backToLogin': 'Back to login',
  'auth.button.needHelp': 'You need help?',
  'branding.musics.addMusic': 'Add music',
  'branding.musics.create.headline': 'New music',
  'branding.musics.empty.cta': 'Upload a music',
  'branding.musics.empty.description': 'You haven’t uploaded any custom soundtracks yet. Add a new one to get started.',
  'branding.musics.empty.title': 'No custom music',
  'branding.musics.headline': 'Music',
  'branding.themes.createThemeCard.createTheme': 'Create theme',
  'branding.themes.empty.description': 'You haven’t created any branding theme yet. Add a new one to get started.',
  'branding.themes.empty.title': 'No branding themes',
  'branding.themes.form.colorPrimary.label': 'Primary color',
  'branding.themes.form.colorSecondary.label': 'Secondary color',
  'branding.themes.form.name.placeholder': 'Name',
  'branding.themes.formats.horizontal': 'Horizontal 16:9',
  'branding.themes.formats.socialMedia': 'Portrait 4:5',
  'branding.themes.formats.square': 'Square 1:1',
  'branding.themes.formats.vertical': 'Vertical 9:16',
  'branding.themes.headline.colors': 'Colors',
  'branding.themes.headline.headline': 'Branding themes',
  'branding.themes.headline.intro': 'Intro',
  'branding.themes.headline.introAndOutro': 'Intro & Outro',
  'branding.themes.headline.logo': 'Logo',
  'branding.themes.headline.name': 'Name of the theme',
  'branding.themes.headline.outro': 'Outro',
  'branding.themes.themeCard.updatedAt': 'Edited {timeAgo}',
  'bulkUpload.dropzone.hint': 'You can upload multiple files at once.',
  'bulkUpload.dropzone.processing.hint': '{uploaded}/{total} files uploaded',
  'bulkUpload.dropzone.processing.text': 'Uploading your files…',
  'bulkUpload.dropzone.text': 'Drag your files here or browse',
  'CollectSceneAssetPage.instructions.title': 'Instructions',
  'CollectSceneAssetPage.title.image': 'Take a photo',
  'CollectSceneAssetPage.title.video': 'Record a video',
  'common.allFormats': 'All formats',
  'common.archive': 'Archive',
  'common.branding': 'Branding',
  'common.confirm': 'Confirm',
  'common.copySharingLink.success': 'Sharing link copied to clipboard',
  'common.copySharingLink': 'Copy sharing link',
  'common.create': 'Create',
  'common.createProject': 'Create project',
  'common.deactivate': 'Deactivate',
  'common.duplicate': 'Duplicate',
  'common.edit': 'Edit',
  'common.email': 'Email',
  'common.exportVideo.error.general': 'Your video cannot be exported because it does not contain any scenes or some of the uploaded files are still being optimized',
  'common.exportVideo': 'Export',
  'common.firstname': 'First Name',
  'common.gridView': 'Grid view',
  'common.help': 'Help',
  'common.horizontal': 'Horizontal',
  'common.invite': 'Invite',
  'common.language': 'Language',
  'common.lastname': 'Last name',
  'common.listView': 'List view',
  'common.logoUpload.hint': '({fileFormat}, max. 2 MB)',
  'common.logoUpload.text': 'Drop your logo here or browse',
  'common.move': 'Move',
  'common.name': 'Name',
  'common.playItem': 'Play “{name}”',
  'common.project': 'Project',
  'common.projects': 'Projects',
  'common.reactivate': 'Reactivate',
  'common.remove': 'Remove',
  'common.rename': 'Rename',
  'common.resendInvite': 'Resend invite',
  'common.socialMedia': 'Portrait',
  'common.square': 'Square',
  'common.status': 'Status',
  'common.unarchive': 'Unarchive',
  'common.upload.audio.state.error': 'Something went wrong, please try again',
  'common.upload.audio.state.processing': 'Almost done, your audio will be ready soon!',
  'common.upload.audio.state.uploading': 'Your audio file is uploading',
  'common.upload.audio.text': 'Drop your audio here or browse',
  'common.upload.video.text': 'Drop your video here or browse',
  'common.uploading': 'Uploading',
  'common.user': 'Customer',
  'common.userDetails': 'Member details',
  'common.userRole': 'User role',
  'common.vertical': 'Vertical',
  'common.videoFormat': 'Format',
  'common.videoFormatLong': 'Video format',
  'common.viewConsents': 'View consents',
  'consentDatabase.actions.delete': 'Delete',
  'consentDatabase.agreements': 'Consents',
  'consentDatabase.empty.cta': 'Go to consent settings',
  'consentDatabase.empty.description': 'You can collect video usage agreements from all contributors',
  'consentDatabase.empty.title': 'No consent recorded yet',
  'consentDatabase.noAccess.description': 'You need the right permission to access recorded consents',
  'consentDatabase.noAccess.title': 'No access',
  'consentDatabase.noResults.description': 'No consent was found with this search criteria',
  'consentDatabase.noResults.title': 'No consent found',
  'consentDatabase.project.consent': 'Consent',
  'consentDatabase.project.contributor': 'Contributor',
  'consentDatabase.project.signedAt': 'Signed at',
  'consentDatabase.projectName': 'Project',
  'consentDatabase.search': 'Search contributors and projects…',
  'contributionRequest.sharingList.duplicate': '{name} already exists',
  'contributionRequest.type': 'Type',
  'contributionRequest.type.image': 'Image',
  'contributionRequest.type.screenRecording': 'Screen recording',
  'contributionRequest.type.video': 'Video',
  'contributionRequest.typeSelect.label': 'Content type',
  'deactivatedUser.headline': 'Seems like your account is not active.',
  'deactivatedUser.requestAccess': 'Request new access',
  'deactivatedUser.subline': 'If you still would like to use cofenster, please request a new access.',
  'deactivatedUser.title': 'Access deactivated',
  'dialogs.addTeamMembersDialog.description': 'Add members of “{accountName}” to your private team.',
  'dialogs.addTeamMembersDialog.headline': 'Add people to “{teamName}”',
  'dialogs.addTeamMembersDialog.label': 'New members',
  'dialogs.addTeamMembersDialog.placeholder': 'Enter name or email address…',
  'dialogs.changeAnimationStyleDialog.cancel': 'Cancel',
  'dialogs.changeAnimationStyleDialog.confirm': 'Yes, I’m sure',
  'dialogs.changeAnimationStyleDialog.descriptionLine1': 'This may remove your existing {count, plural, one {text element} other {# text elements}}.',
  'dialogs.changeAnimationStyleDialog.descriptionLine2': ' Are you sure you want to change it?',
  'dialogs.changeAnimationStyleDialog.headline': 'Change animation style',
  'dialogs.ConfirmArchiveDialog.button': 'Archive',
  'dialogs.ConfirmArchiveDialog.headline': 'Archive project',
  'dialogs.ConfirmArchiveDialog.text': 'This project is currently publicly shared. Archiving it will unpublish it and it will no longer be available outside of your organization.',
  'dialogs.ConfirmBulkArchiveDialog.button': 'Confirm',
  'dialogs.ConfirmBulkArchiveDialog.headline': 'Archive projects',
  'dialogs.ConfirmBulkArchiveDialog.text': 'This will unpublish the projects that were publicly shared (if any) and they will no longer be available outside of your organization.',
  'dialogs.ContributionRequestListDialog.contributionRequestListSelect.default': 'All recording requests',
  'dialogs.ContributionRequestListDialog.contributionRequestListSelect.label': 'Selected list',
  'dialogs.ContributionRequestListDialog.emailInput.label': 'Add email address, hit enter to add another',
  'dialogs.ContributionRequestListDialog.emailInput.validation.duplicateEmail': 'This email address is already added',
  'dialogs.ContributionRequestListDialog.emailInput.validation.invalidEmail': 'Email address is invalid',
  'dialogs.ContributionRequestListDialog.emailInput.validation.min': 'You must specify at least one recipient',
  'dialogs.ContributionRequestListDialog.emailMessageInput.label': 'Message (optional)',
  'dialogs.ContributionRequestListDialog.headline': 'Request contributions',
  'dialogs.createProjectFolderDialog.button': 'Create folder',
  'dialogs.createProjectFolderDialog.description': 'Please choose a name for the new folder:',
  'dialogs.createProjectFolderDialog.headline': 'Create folder',
  'dialogs.CreateProjectFolderDialog.label': 'Folder name',
  'dialogs.createScenesDialog.addScene': 'Add a scene',
  'dialogs.createScenesDialog.uploading': 'Upload existing files',
  'dialogs.createTeamDialog.description': 'Choose a name that fits the purpose of this team and start collaborating.',
  'dialogs.createTeamDialog.headline': 'Create team',
  'dialogs.copyPasteDeniedDialog.content': 'We need access to your clipboard so you can copy and paste scenes. Please grant us permissions to access your clipboard via your browser settings.',
  'dialogs.copyPasteDeniedDialog.title': 'Grant access to your clipboard',
  'dialogs.deactivateUserDialog.button': 'Deactivate member',
  'dialogs.deactivateUserDialog.headline': 'Are you sure you want to deactivate this user?',
  'dialogs.deactivateUserDialog.text.paragraph1': 'This person won’t have access to cofenster anymore.',
  'dialogs.deactivateUserDialog.text.paragraph2': 'Any teams they owned will be transferred to you. You can review the teams contents and reassign or delete them as needed.',
  'dialogs.DeleteAssetDialog.button': 'Delete',
  'dialogs.DeleteAssetDialog.headline': 'Delete asset',
  'dialogs.DeleteAssetDialog.text': 'Are you sure you want to permanently delete this asset?',
  'dialogs.DeleteAssetDialog.multiple.headline': 'Delete {count, plural, one {asset} other {assets}}',
  'dialogs.DeleteAssetDialog.multiple.text': 'Are you sure you want to permanently delete {count, plural, one {this asset} other {these assets}}?',
  'dialogs.deleteConsent.description': 'Are you sure you want to delete the consent form?',
  'dialogs.deleteConsent.title': 'Delete consent',
  'dialogs.deleteContributionRequestDialog.deleteButton': 'Delete request',
  'dialogs.deleteContributionRequestDialog.paragraph1': 'Are you sure you want to delete this recording request?',
  'dialogs.deleteContributionRequestDialog.paragraph2': 'Any recordings you already received remain in the project.',
  'dialogs.deleteContributionRequestDialog.title': 'Delete recording request?',
  'dialogs.DeleteMusicDialog.headline': 'Delete music',
  'dialogs.DeleteMusicDialog.text': 'Are you sure you want to delete the music named “{name}”? Projects using it will not be automatically re-rendered, and you will need to update their music manually.',
  'dialogs.deleteProjectDialog.headline': 'Delete project',
  'dialogs.deleteProjectDialog.description': 'Are you sure you want to delete “{name}” permanently? This action cannot be undone.',
  'dialogs.deleteProjectDialog.description.published': 'The publicly shared link will no longer be accessible.',
  'dialogs.deleteProjectDialog.button': 'Delete',
  'dialogs.deleteProjectFolderDialog.button': 'Delete folder',
  'dialogs.deleteProjectFolderDialog.description': 'You are about to delete your folder and all the projects it contains permanently.',
  'dialogs.deleteProjectFolderDialog.headline': 'Delete folder',
  'dialogs.deleteProjectsDialog.button': 'Delete {count, plural, =0 {projects} one {project} other {projects}}',
  'dialogs.deleteProjectsDialog.description.all': 'You are about to delete {count, plural, =0 {# published projects} one {# published project} other {# published projects}} permanently. The shared links will no longer be accessible.',
  'dialogs.deleteProjectsDialog.description.none': 'You are about to delete {count, plural, =0 {# projects} one {# project} other {# projects}} permanently.',
  'dialogs.deleteProjectsDialog.description.some': 'You are about to delete {count, plural, =0 {# projects} one {# project} other {# projects}} ({publishedCount} published) permanently. The shared links will not longer be accessible.',
  'dialogs.deleteProjectsDialog.headline.all': 'Delete {count, plural, =0 {published projects} one {published project} other {published projects}}',
  'dialogs.deleteProjectsDialog.headline.none': 'Delete {count, plural, =0 {projects} one {project} other {projects}}',
  'dialogs.deleteProjectsDialog.headline.some': 'Delete {count, plural, =0 {projects} one {project} other {projects}} ({publishedCount} published)',
  'dialogs.DeleteSceneDialog.button': 'Delete',
  'dialogs.DeleteSceneDialog.headline': 'Delete {count, plural, =0 {scenes} one {scene} other {scenes}}',
  'dialogs.DeleteSceneDialog.text': 'Are you sure you want to permanently delete {count, plural, =0 {these scenes} one {this scene} other {these scenes}}?',
  'dialogs.deleteSceneOverlayDialog.headline': 'Delete overlay',
  'dialogs.deleteSceneOverlayDialog.text': 'Are you sure you want to delete this overlay?',
  'dialogs.DeleteSceneTextDialog.button': 'Delete',
  'dialogs.DeleteSceneTextDialog.headline': 'Delete text',
  'dialogs.DeleteSceneTextDialog.text': 'Are you sure you want to delete this text?',
  'dialogs.DeleteSharingListDialog.text': 'Are you sure you want to delete “{name}”? This will affect all contribution requests assigned to this list.',
  'dialogs.DeleteSharingListDialog.title': 'Delete custom list',
  'dialogs.DeleteTeamDialog.headline': 'Delete team',
  'dialogs.DeleteTeamDialog.teamName.error.equal': 'Enter the correct team name to confirm',
  'dialogs.DeleteTeamDialog.teamName.error.required': 'Enter the team name to confirm',
  'dialogs.DeleteTeamDialog.text.paragraph1': 'Permanently delete all videos in “{teamName}”.',
  'dialogs.DeleteTeamDialog.text.paragraph2': 'Type the team name below to confirm.',
  'dialogs.deleteTheme.button': 'Delete',
  'dialogs.deleteTheme.headline': 'Delete theme',
  'dialogs.deleteTheme.text': 'Do you really want to delete this theme?',
  'dialogs.DeleteUserDialog.button': 'Remove',
  'dialogs.DeleteUserDialog.headline': 'Remove this person from your account?',
  'dialogs.DeleteUserDialog.text.paragraph1': '{userName} will no longer have access to teams and projects in cofenster.',
  'dialogs.DeleteUserDialog.text.paragraph2': 'Any teams they owned will be transferred to you. You can review the teams contents and reassign or delete them as needed.',
  'dialogs.DeleteUserDialog.text.paragraph3': 'This action cannot be reversed. Type the person’s name below to confirm.',
  'dialogs.DeleteUserDialog.userName.error.equal': 'Please enter the name of the person correctly',
  'dialogs.DeleteUserDialog.userName.error.required': 'Please type the person’s name',
  'dialogs.DeleteUserDialog.userName.label': 'Person name',
  'dialogs.duplicateProjectDialog.button': 'Duplicate project',
  'dialogs.duplicateProjectDialog.defaultName': 'Copy of {name}',
  'dialogs.duplicateProjectDialog.description': 'How would you like to duplicate your project?',
  'dialogs.duplicateProjectDialog.duplicateAs': 'Duplicate as…',
  'dialogs.duplicateProjectDialog.headline': 'Duplicate project…',
  'dialogs.duplicateProjectDialog.videoFormat': 'Video format',
  'dialogs.moveProjectFolderDialog.description': 'Select the team you want to move this folder to.',
  'dialogs.moveProjectFolderDialog.headline': 'Move folder',
  'dialogs.moveProjectFolderDialog.moveFolder': 'Move Folder',
  'dialogs.moveProjectFolderDialog.moveOutOfPrivateSpace': 'Everyone in “{team}” will get access. You cannot move this folder back.',
  'dialogs.moveProjectFolderDialog.noTeams': 'There is no team where you can move this folder to.',
  'dialogs.moveProjectFolderDialog.teamSelect.label': 'Destination team',
  'dialogs.moveProjectsDialog.button': 'Move {count, plural, =0 {projects} one {project} other {projects}}',
  'dialogs.moveProjectsDialog.description': 'Please choose the folder you want to move your {count, plural, =0 {# projects} one {project} other {# projects}} to:',
  'dialogs.moveProjectsDialog.folderSelect.label': 'Destination folder',
  'dialogs.moveProjectsDialog.headline': 'Move {count, plural, =0 {projects} one {project} other {projects}} to…',
  'dialogs.moveProjectsDialog.moveOutOfPrivateSpace': 'Everyone in {team} will get access. You cannot move this project back.',
  'dialogs.moveProjectsDialog.noFolders': 'There is no folder where you can move this {count, plural, =0 {projects} one {project} other {projects}} to.',
  'dialogs.moveProjectsDialog.teamRootFolder': 'Directly within the team',
  'dialogs.moveProjectsDialog.teamSelect.label': 'Destination team',
  'dialogs.MoveScenesToProjectDialog.decisionStep.newProjectDescription': 'Create a new project from the selected scenes',
  'dialogs.MoveScenesToProjectDialog.decisionStep.newProjectInputLabel': 'Project name',
  'dialogs.MoveScenesToProjectDialog.decisionStep.projectDescription.creator': 'From {creator}',
  'dialogs.MoveScenesToProjectDialog.decisionStep.projectDescription.lastEdited': 'Edited {lastEdited}',
  'dialogs.MoveScenesToProjectDialog.decisionStep.searchProjectsInputPlaceholder': 'Search for project',
  'dialogs.MoveScenesToProjectDialog.decisionStep.targetProjectRadioLegend': 'Choose a destination project',
  'dialogs.MoveScenesToProjectDialog.destination.existingProject': 'Add to existing project',
  'dialogs.MoveScenesToProjectDialog.destination.legend': 'Choose a destination project (new or existing)',
  'dialogs.MoveScenesToProjectDialog.destination.newProject': 'Create a new project',
  'dialogs.MoveScenesToProjectDialog.keepSourceScenes': 'Keep a copy of selected {count, plural, =0 {scenes} one {scene} other {scenes}} in this project',
  'dialogs.MoveScenesToProjectDialog.search.error.description': 'Something went wrong during the search; please try again.',
  'dialogs.MoveScenesToProjectDialog.search.error.title': 'An error occurred',
  'dialogs.MoveScenesToProjectDialog.search.noResults.description': 'We could not find any project matching your search criteria.',
  'dialogs.MoveScenesToProjectDialog.search.noResults.title': 'No results',
  'dialogs.MoveScenesToProjectDialog.search.results': '{count, plural, one {# result} other {# results}}',
  'dialogs.MoveScenesToProjectDialog.title': 'Move to project',
  'dialogs.projectVideoPendingChangesDownloadDialog.download': 'Download video',
  'dialogs.projectVideoPendingChangesDownloadDialog.headline': 'Update video',
  'dialogs.projectVideoPendingChangesDownloadDialog.subline': 'Editing changes were made to this video since the last export. To include these changes update your video or download the current version.',
  'dialogs.projectVideoPendingChangesDownloadDialog.update': 'Update video',
  'dialogs.reactivateUserDialog.button': 'Reactivate member',
  'dialogs.reactivateUserDialog.description': 'This person will be able to access cofenster again. ',
  'dialogs.reactivateUserDialog.headline': 'Are you sure you want to reactivate this member?',
  'dialogs.recordYourself.button': 'Proceed',
  'dialogs.recordYourself.headline': 'Record yourself',
  'dialogs.recordYourself.videoFormat': 'Video Format',
  'dialogs.renameProjectDialog.button': 'Rename project',
  'dialogs.renameProjectDialog.description': 'Please enter the new name to your project:',
  'dialogs.renameProjectDialog.headline': 'Rename project',
  'dialogs.renameProjectDialog.projectTemplate.button': 'Rename template',
  'dialogs.renameProjectDialog.projectTemplate.description': 'Please enter the new name to your project template:',
  'dialogs.renameProjectDialog.projectTemplate.headline': 'Rename project template',
  'dialogs.renameProjectFolderDialog.button': 'Rename folder',
  'dialogs.renameProjectFolderDialog.description': 'Please enter the new name to your folder:',
  'dialogs.renameProjectFolderDialog.headline': 'Rename folder',
  'dialogs.requestPermission.learnMore': 'Learn more',
  'dialogs.requestPermission.message.headline.account': 'You are currently a {roleName}.',
  'dialogs.requestPermission.message.headline.team': 'You are currently a {roleName} in this team.',
  'dialogs.requestPermission.message.subline': 'If you want to do more with cofenster, ask an administrator to upgrade your role.',
  'dialogs.requestPermission.requestPermissionButton': 'Request permissions',
  'dialogs.requestPermission.title': 'Request permissions',
  'dialogs.sceneSubtitles.addLanguage.description': 'Choose a language to add to your subtitles',
  'dialogs.sceneSubtitles.addLanguage.headline': 'Add language to all scenes',
  'dialogs.sceneSubtitles.addLanguage.submitButton': 'Add language',
  'dialogs.SendUserInviteEmailDialog.headline': 'Send new invite?',
  'dialogs.SendUserInviteEmailDialog.text': 'This will send a new invite email, valid for 5 days. Previous invitation links will no longer be valid.',
  'dialogs.SessionExpiredDialog.button': 'Sign in',
  'dialogs.SessionExpiredDialog.description': 'It looks like your session has expired and you need to authenticate again. Press the button below to be redirect to the login page.',
  'dialogs.SessionExpiredDialog.headline': 'Session expired',
  'dialogs.StockLibraryDialog.pexels': 'Results provided by <link>Pexels</link>',
  'dialogs.StockLibraryDialog.save.image': 'Add image',
  'dialogs.StockLibraryDialog.save.video': 'Add video',
  'dialogs.StockLibraryDialog.search.error.description': 'Something went wrong during the search; please try again.',
  'dialogs.StockLibraryDialog.search.error.title': 'An error occurred',
  'dialogs.StockLibraryDialog.search.label': 'Search the stock library',
  'dialogs.StockLibraryDialog.search.noResults.description.image': 'We could not find any images matching your search criteria.',
  'dialogs.StockLibraryDialog.search.noResults.description.video': 'We could not find any videos matching your search criteria.',
  'dialogs.StockLibraryDialog.search.noResults.title': 'No results',
  'dialogs.StockLibraryDialog.title': 'Stock library',
  'dialogs.subtitles.deleteNode.button': 'Delete',
  'dialogs.subtitles.deleteNode.content': 'By deleting this subtitle line, all your text will be deleted. Are you sure you want to proceed?',
  'dialogs.subtitles.deleteNode.header': 'Delete subtitle line',
  'dialogs.team.leave.confirm.headline': 'Leave {team}?',
  'dialogs.team.leave.confirm.subline': 'You will lose access to all folders and projects in this team. To rejoin a private team you have to be invited.',
  'dialogs.team.owner.confirm': 'Transfer ownership',
  'dialogs.team.owner.description': 'Make {newOwner} the owner of the team and become a regular member of the team. This action cannot be undone.',
  'dialogs.team.owner.title': 'Transfer team ownership',
  'forgot.error.expired': 'expired',
  'forgot.error.invalid': 'invalid',
  'forgot.error': 'Your link is {error}. Enter the email address you use for cofenster.',
  'forgot.form.submit': 'Reset password',
  'forgot.headline': 'Reset your password.',
  'forgot.subline': 'No worries, happens to the best of us. Enter the email address you use for cofenster.',
  'forgot.title': 'Password reset',
  'form.error.color.required': 'Please choose your account color',
  'form.error.email.format': 'Please enter a valid email address',
  'form.error.email.required': 'Please enter an email address',
  'form.error.firstname.required': 'Please enter your first name',
  'form.error.generic.lineCount': 'Max {lineCount} lines',
  'form.error.generic.lineLength': 'Max {lineLength} characters per line',
  'form.error.generic.maxLength': 'Max. {max} characters allowed',
  'form.error.generic.required': 'Please fill in this field',
  'form.error.lastname.required': 'Please enter your last name',
  'form.error.newPassword.required': 'Please enter the new password',
  'form.error.newPasswordRepeat.match': 'Passwords don’t match',
  'form.error.newPasswordRepeat.required': 'Please repeat the new password',
  'form.error.oldPassword.required': 'Please enter the current password',
  'form.error.password.required': 'Please enter a password',
  'form.error.password.weak': 'Please choose a longer password and/or include numbers, symbols, uppercase, and lowercase letters',
  'form.error.passwordRepeat.match': 'The repetition does not match the password',
  'form.error.passwordRepeat.required': 'Please repeat the password',
  'form.error.projectCreate.name.required': 'Please enter a project name',
  'form.error.projectCreate.videoFormat.required': 'Please choose a video format',
  'form.error.teamName.exists': 'A team with this name already exists',
  'form.error.token.resetPassword': 'The link to reset your password is not valid',
  'form.error.token.setPassword': 'The invitation link is not valid',
  'form.error.user.emailExists': 'This email address already exists',
  'form.error.user.lastAdmin': 'This would cause the account to be left without an admin',
  'form.error': 'Error',
  'form.success': 'Your changes have been saved.',
  'home.createProjectDialog.button': 'Let’s go',
  'home.createProjectDialog.description': 'Select the team in which you want to create your project. You can always move it afterwards.',
  'home.createProjectDialog.headline': 'Select a team space',
  'home.createProjectDialog.teamSelect.before': 'Create project in',
  'home.createProjectDialog.teamSelect.label': 'Team',
  'home.headline': 'Hey {name}, get started with video',
  'home.latestProjects.empty.headline': 'No activity yet',
  'home.latestProjects.empty.subline': 'Create projects and invite contributors to get started',
  'home.latestProjects.title': 'Recent activity',
  'home.search.empty.description': 'No projects were found matching these search criteria',
  'home.search.empty.title': 'No project found',
  'home.search.label': 'Search all projects',
  'home.title': 'Home',
  'language.select.english': 'English',
  'language.select.german': 'Deutsch',
  'mainNavigation.createTeam': 'Create a team',
  'mainNavigation.home': 'Home',
  'mainNavigation.logout.admin': 'Terminate impersonation',
  'mainNavigation.logout': 'Log out',
  'mainNavigation.personal.projects': 'My projects',
  'mainNavigation.personal': 'Personal',
  'mainNavigation.projectTemplates': 'Project templates',
  'mainNavigation.teams': 'Teams',
  'mainNavigation.userMenu': 'Personal menu',
  'markdownTooltip.example.bold': 'bold',
  'markdownTooltip.example.italics': 'italics',
  'markdownTooltip.example.orderedList': 'Ordered list',
  'markdownTooltip.example.unorderedList': 'Unordered list',
  'markdownTooltip.link': 'Learn more about Markdown.',
  'markdownTooltip.text': 'This field supports a subset of Markdown formatting. It will convert to fully formatted content for your contributors using the sharing link.',
  'markdownTooltip.title': 'Formatting guide',
  'markdownTooltip.toggle': 'About formatting',
  'noAccess.goHome': 'Return home',
  'noAccess.message':
    'Either this {resource, select, Project {project} ProjectFolder {folder} Team {team} User {member} Page {page} other {resource} } does not exist or you do not have the necessary permissions to access it. {resource, select, Team {Ask the team owner to verify the link or add you to the team members.} other {} }',
  'noAccess.title': '{resource, select, Project {Project} ProjectFolder {Folder} Team {Team} User {Member} Page {Page} other {Resource} } not found',
  'noOption.description': 'Admins can invite new people to your team from the account settings',
  'noOption.title': 'Nobody found',
  'notification.settings.administrative.subtitle': 'Even if you unsubscribe from all notifications, we will still send essential communication like password resets, invites or important account updates.',
  'notification.settings.administrative.title': 'We will always send administrative emails',
  'notification.settings.email.receiveNotifications': 'Receive email notifications',
  'notification.settings.email.section.projectActivity.newContribution': 'New contribution in one of your projects',
  'notification.settings.email.section.projectActivity.videoEngagement': 'People are watching your video',
  'notification.settings.email.section.projectActivity': 'Project activity',
  'notification.settings.email.section.teamUpdates.newMembers': 'New members in your account',
  'notification.settings.email.section.teamUpdates.roleChanges': 'Changes to your role or access to a team',
  'notification.settings.email.section.teamUpdates': 'Team updates',
  'notification.settings.email.section.weeklyDigest.weeklyNews': 'Weekly news on activity and performance in your account',
  'notification.settings.email.section.weeklyDigest': 'Weekly digest',
  'notification.settings.email.title': 'Email notifications',
  'projectContributionConfiguration.briefing.description': 'A briefing video message can significantly boost the response rate of contributors',
  'projectContributionConfiguration.briefing.title': 'Briefing video',
  'projectContributionConfiguration.briefing.recordVideoButton': 'Record video',
  'projectContributionConfiguration.initial.title': 'Ask others for videos, images and screenshares',
  'projectContributionConfiguration.initial.description': 'Create a briefing you can share via link to collect content',
  'projectContributionConfiguration.initial.createYourOwn.title': 'Create your own',
  'projectContributionConfiguration.initial.createYourOwn.button': 'Create blank request',
  'projectContributionConfiguration.initial.preset.title': 'Try one of our presets',
  'projectContributionConfiguration.list.requestsSection.title': 'Requests',
  'projectContributionConfiguration.filter.byContributors.label': 'Filter by contributors',
  'projectContributionConfiguration.filter.byContributors.empty': 'All contributors',
  'projectContributionConfiguration.filter.byContributors.notEmpty': '{count, plural, one {# contributor} other {# contributors}}',
  'projectContributionConfiguration.filter.byRequests.label': 'Filter by requests',
  'projectContributionConfiguration.filter.byRequests.empty': 'All requests',
  'projectContributionConfiguration.filter.byRequests.emptyCRTitle': 'Request {index}',
  'projectContributionConfiguration.filter.byRequests.notEmpty': '{count, plural, one {# request} other {# requests}}',
  'projectContributionConfiguration.filter.reset': 'Reset filter',
  'projectContributionConfiguration.backToEditing': 'Back to editing',
  'projectContributionConfiguration.pageTitle': 'Request content',
  'projectContributionConfiguration.preview': 'Preview',
  'projectContributionConfiguration.PreviewBriefingVideoDialog.title': 'Your briefing video',
  'projectContributionConfiguration.RecordVideoBriefing.description': 'Briefly tell people about your video idea and why they should be participating in your video.',
  'projectContributionConfiguration.RecordVideoBriefing.page.title': 'Record video briefing',
  'projectContributionConfiguration.RecordVideoBriefing.title': 'Summarize your video idea',
  'projectContributionConfiguration.requestContentButton': 'Request content',
  'projectContributionConfiguration.recordings.emptyState.title': 'No recordings yet',
  'projectContributionConfiguration.recordings.emptyState.description': 'Nobody has contributed to your request',
  'projectContributionConfiguration.section.dataAndPrivacy.title': 'Data and Privacy',
  'projectContributionConfiguration.section.general.title': 'General',
  'projectContributionConfiguration.settings.createSceneOnContribution.title': 'Add new contributions to editing',
  'projectContributionConfiguration.settings.createSceneOnContribution.description': 'All new contributions are added to editing automatically as they come in',
  'projectContributionConfiguration.settings.acceptResponses.title': 'Accepting responses',
  'projectContributionConfiguration.settings.acceptResponses.description': 'Open link to new contributions',
  'projectContributionConfiguration.settings.collectDetails.title': 'Collect contributor information',
  'projectContributionConfiguration.settings.collectDetails.description': 'Require a name and email from all contributors',
  'projectContributionConfiguration.settings.requireConsent.banner.title': 'Consent is off by default',
  'projectContributionConfiguration.settings.requireConsent.banner.description': 'Consent has been turned off by default for new projects in this account',
  'projectContributionConfiguration.settings.requireConsent.banner.configureButtonLabel': 'Configure',
  'projectContributionConfiguration.settings.requireConsent.description': 'Ask contributors to sign your consent document',
  'projectContributionConfiguration.settings.requireConsent.title': 'Require consent',
  'projectContributionConfiguration.settings.requireConsent.notConfigured.admin': 'Consent has not been setup for your account just yet. <link>Learn more</link>',
  'projectContributionConfiguration.settings.requireConsent.notConfigured.nonAdmin': 'Consent has not been setup for your account just yet. It can be setup by admins of your account. <link>Learn more</link>',
  'projectContributionConfiguration.settings.requireConsent.notConfigured.configureButton': 'Configure consent',
  'projectContributionConfiguration.settings.requireConsent.notConfigured.title': 'Requiring consent',
  'projectContributionConfiguration.settings.showOthersContributions.title': 'Show all contributions',
  'projectContributionConfiguration.settings.showOthersContributions.description': 'Allow contributors to view all responses from other contributors in each request',
  'projectContributionConfiguration.tabs.requests': 'Requests',
  'projectContributionConfiguration.tabs.recordings': 'Recordings',
  'projectContributionConfiguration.tabs.settings': 'Settings',
  'projectContributionConfiguration.videoBriefing.confirmDelete.title': 'Delete video briefing',
  'projectContributionConfiguration.videoBriefing.moreOptionsLabel': 'More options',
  'projectContributionConfiguration.usePresetButton': 'Use preset',
  'projectContribution.AddContributionRequestCard.text': 'Add recording request',
  'projectContribution.CreateContributionRequestListForm.addListTitle': 'Add to custom list',
  'projectContribution.CreateContributionRequestListForm.addListInputLabel': 'Add new list',
  'projectContribution.CreateContributionRequestListForm.emptyStateText': 'Create your own lists to share with different people.',
  'projectContributions.ContributionRequestCard.addToListChipTitle': 'Add to custom list',
  'projectContributions.ContributionRequestCard.contributionsCount': '{count, plural, =0 {No recordings yet} one {# recording} other {# recordings}}',
  'projectContributions.ContributionRequestCard.crl.removeFromGroupDialog.title': 'Remove from the custom list',
  'projectContributions.ContributionRequestCard.crl.removeFromGroupDialog.description': 'Are you sure you want to remove this contribution request from the “{name}” list?',
  'projectContributions.ContributionRequestCard.form.instructions.label': 'Instructions',
  'projectContributions.ContributionRequestCard.form.instructions.placeholder': 'Provide additional directions to the contributor. Where should they film? Which talking points need to be covered? How close should they be to the camera?',
  'projectContributions.ContributionRequestCard.form.title.label': 'Title',
  'projectContributions.ContributionRequestCard.form.title.placeholder': 'Enter a title for the recording request',
  'projectContributions.ContributionRequestListDialog.share.appCode': 'App code',
  'projectContributions.ContributionRequestListDialog.share.codeCopiedSnackbar': 'Code for {for} copied to clipboard',
  'projectContributions.ContributionRequestListDialog.share.copyLink': 'Copy link',
  'projectContributions.ContributionRequestListDialog.share.defaultList': 'all recording requests',
  'projectContributions.ContributionRequestListDialog.share.emailsSentSnackbar.everyone': 'everyone',
  'projectContributions.ContributionRequestListDialog.share.emailsSentSnackbar': 'Recording request sent to {listName}',
  'projectContributions.ContributionRequestListDialog.share.linkCopiedSnackbar': 'Link for {for} copied to clipboard',
  'projectContributions.ContributionRequestListDialog.share.qrCode': 'QR-Code',
  'projectContributions.ContributionRequestListDialog.share.sendEmails': 'Send request',
  'projectContributions.contributions.addToVideoButton': 'Add to video',
  'projectContributions.contributions.bulk.addToProject': 'Add {count, plural, one {# contribution} other {# contributions}} to the project',
  'projectContributions.contributions.bulk.contributionsSelected': '{count, plural, =0 {# contributions} one {# contribution} other {# contributions}} selected',
  'projectContributions.contributions.bulk.label': 'Select "{name}" for bulk actions',
  'projectContributions.contributions.bulk.selectAll': 'Select all',
  'projectContributions.contributions.deleteAssetConfirmation.title': 'Delete contribution',
  'projectContributions.contributions.deleteMultipleAssetConfirmation.title': '{contributionsCount, plural, one {Delete contribution} other {Delete # contributions}}',
  'projectContributions.contributions.deleteMultipleAssetConfirmation.description.noScenes': 'Are you sure you want to delete {contributionsCount, plural, one {this contribution} other {these contributions}}?',
  'projectContributions.contributions.deleteMultipleAssetConfirmation.description':
    '{contributionsCount, plural, one {This contribution is} other {These contributions are}} connected to {scenesCount, plural, one {# scene} other {# scenes}}. Deleting it will also remove all scenes connected to it. Are you sure you want to delete {contributionsCount, plural, one {this contribution} other {these contributions}}?',
  'projectContributions.contributions.emptyState.description': 'Wait for people to contribute',
  'projectContributions.contributions.emptyState.title': 'No recordings yet',
  'projectContributions.contributions.imageContribution.alt': 'Image contribution created at {date}',
  'projectContributions.contributions.includedTooltip': 'Included in the project',
  'projectContributions.contributions.snackbar.contributionAdded': '{count , plural, one {Scene} other {# scenes}} added to project. You can find it in editing.',
  'projectContributions.RequestRecordingsButton.label': 'Request recordings',
  'projectCreate.form.button.submit': 'Create project',
  'projectCreate.form.name.defaultValue': 'Untitled video',
  'projectCreate.form.name.label': 'Select a name',
  'projectCreate.form.name.placeholder': 'What is your project name?',
  'projectCreate.header': 'Create your new video',
  'projectCreate.projectTemplates.blankProjectHeader': 'New blank project',
  'projectCreate.projectTemplates.projectTemplatesHeader': 'Project templates',
  'projectCreate.videoFormat.header': 'Choose a format',
  'projectCreate.videoFormat.Horizontal.title': 'Horizontal 16:9',
  'projectCreate.videoFormat.SocialMedia.title': 'Portrait 4:5',
  'projectCreate.videoFormat.Square.title': 'Square 1:1',
  'projectCreate.videoFormat.Vertical.title': 'Vertical 9:16',
  'ProjectCut.TrimmingSections.buttons.split': 'Split',
  'ProjectCut.TrimmingSections.buttons.delete': 'Delete',
  'ProjectCut.TrimmingSections.handle': 'Trimming handle',
  'ProjectCut.TrimmingSections.scrubber': 'Seek slider',
  'ProjectCut.TrimmingSections.tooltip.extendSequence': 'Extend sequence',
  'ProjectCut.TrimmingSections.tooltip.joinSequences': 'Join sequences',
  'ProjectCut.TrimmingSections.tooltips.zoomIn': 'Zoom in',
  'ProjectCut.TrimmingSections.tooltips.zoomLevel': 'Zoom level',
  'ProjectCut.TrimmingSections.tooltips.zoomOut': 'Zoom out',
  'projectDesign.OutroTextInput.label': 'Text',
  'projectDesign.OutroTextInput.placeholder': 'Max. {lines} lines with {lineLength} characters each',
  'projectDesign.panel.headline': 'Template',
  'projectDesign.templateForm.background.black': 'Black',
  'projectDesign.templateForm.background.blurred': 'Blurred',
  'projectDesign.templateForm.background.primary': 'Primary color',
  'projectDesign.templateForm.background': 'Background',
  'projectDesign.templateForm.backgroundTooltip.black': 'The blank space will be filled with black color',
  'projectDesign.templateForm.backgroundTooltip.blurred': 'The blank space will be filled with a blur effect',
  'projectDesign.templateForm.backgroundTooltip.primary': 'The blank space will be filled with your chosen primary color',
  'projectDesign.templateForm.changeButton': 'Change <hidden>template</hidden>',
  'projectDesign.templateForm.headline': 'Template',
  'projectDesign.themeForm.changeTheme': 'Change <hidden>theme</hidden>',
  'projectDesign.themeForm.colorPrimary.label': 'Primary color',
  'projectDesign.themeForm.colorSecondary.label': 'Secondary color',
  'projectDesign.themeForm.error': 'An error occurred updating the theme.',
  'projectDesign.themeForm.headline.colors': 'Colors',
  'projectDesign.themeForm.headline.logo': 'Logo',
  'projectDesign.themeForm.headline.theme': 'Theme',
  'projectDesign.themeForm.intro': 'Intro',
  'projectDesign.themeForm.outro': 'Outro',
  'projectDesign.themeForm.panel.headline': 'Themes',
  'projectDesign.themeForm.panel.noThemes': 'You currently do not have any custom theme. Head into your design settings to create a new reusable theme.',
  'projectDesign.themeForm.panel.unnamedTheme': 'Unnamed theme',
  'projectDesign.themeForm.videoOutro': 'Video outro',
  'projectEdit.colorPicker.pickButton': 'Pick color {color}',
  'projectEdit.colorPicker.setButton': 'Set color',
  'projectEdit.copySceneToProject.error': 'Scene can not be pasted. Either it was deleted or you do not have the required permissions.',
  'projectEdit.durationInput.setButton': 'Set duration',
  'projectEdit.editorTools.EditSceneSubtitles.button.text': 'Subtitles',
  'projectEdit.empty.addScene': 'Add a scene',
  'projectEdit.empty.archived.description': 'This archived project contains nothing. Unarchive it to add scenes.',
  'projectEdit.empty.archived.title': 'Empty project',
  'projectEdit.empty': 'You can create instructions for scenes and share them with {br} other contributors using the storyboard',
  'projectEdit.EnhancedAudio.error': 'Something went wrong. Please try again.',
  'projectEdit.EnhancedAudio.mainVideoAssetNotReady': 'Removing the background noise is available once we optimized your video',
  'projectEdit.EnhancedAudio.remove': 'Remove background noise',
  'projectEdit.EnhancedAudio.removing': 'Removing background noise…',
  'projectEdit.EnhancedAudio.title': 'Audio tools',
  'projectEdit.manipulateAsset.crop': 'Crop',
  'projectEdit.noUpdateProjectPermission.headline': 'No scenes so far',
  'projectEdit.noUpdateProjectPermission.subline': 'Ask your administrator for editing rights to start creating scenes',
  'projectEdit.overlays.headline': 'Add elements',
  'projectEdit.scene.edit.button': 'Edit scene “{title}”',
  'projectEdit.SceneAudioButton.noAudio': 'No audio',
  'projectEdit.SceneAudioButton.sceneAudio': 'Audio',
  'projectEdit.SceneAudioPanelContent.sceneAudioMute.title': 'Mute scene',
  'projectEdit.SceneAudioPanelContent.sceneAudioType.originalSceneAudio.button': 'Play original track',
  'projectEdit.SceneAudioPanelContent.sceneAudioType.originalSceneAudio': 'Original',
  'projectEdit.SceneAudioPanelContent.sceneAudioType.uploadedAudio': 'Uploaded',
  'projectEdit.SceneAudioPanelContent.SceneAudioUploader.hint': 'Your audio will be trimmed to the duration of the scene.',
  'projectEdit.SceneAudioPanelContent.title': 'Audio track',
  'projectEdit.SceneColorNoAsset.text': 'Add a color for your background below',
  'projectEdit.SceneEditorNotes.button.text': 'Notes',
  'projectEdit.SceneEditorNotes.overlay.header': 'Notes',
  'projectEdit.SceneEditorNotes.overlay.placeholder': 'You can put your notes here',
  'projectEdit.sceneForm.color': 'Color',
  'projectEdit.sceneForm.image': 'Image',
  'projectEdit.sceneForm.screenRecording': 'Screen recording',
  'projectEdit.sceneForm.tabs.new': 'New',
  'projectEdit.sceneForm.tabs.requests': 'Requests',
  'projectEdit.sceneForm.video': 'Video',
  'projectEdit.textElement.addText.tooltip': 'You need at least one second of space to add text at this point',
  'projectEdit.textElement.addText': 'Add text',
  'projectEdit.textElement.tooltip': '{count, plural, one {1 text element} other {# text elements}}',
  'projectEdit.trimming.cut': 'Cut',
  'projectEditor.hint.music.short': 'Music is added after export',
  'projectEditor.hint.hidden': 'This scene is hidden from the video. <link>Show</link>',
  'projectEditor.hint.hidden.short': 'Hidden: <link>Show</link>',
  'projectEditor.playerActions.gotoNextScene': 'Go to next scene',
  'projectEditor.playerActions.gotoPreviousScene': 'Go to previous scene',
  'projectEditor.sceneAssetThumbs.popover.actions.addTransition': 'Add transition',
  'projectEditor.sceneAssetThumbs.popover.actions.addTransitions': 'Add to all scenes',
  'projectEditor.sceneAssetThumbs.popover.actions.delete': 'Delete',
  'projectEditor.sceneAssetThumbs.popover.actions.deleteAsset': 'Remove asset',
  'projectEditor.sceneAssetThumbs.popover.actions.deleteColor': 'Remove color',
  'projectEditor.sceneAssetThumbs.popover.actions.deleteTransition': 'Remove transition',
  'projectEditor.sceneAssetThumbs.popover.actions.download': 'Download asset',
  'projectEditor.sceneAssetThumbs.popover.actions.hideScene': 'Hide from video',
  'projectEditor.sceneAssetThumbs.popover.actions.insertScene': 'New scene',
  'projectEditor.sceneAssetThumbs.popover.actions.moveToProject': 'Move to project',
  'projectEditor.sceneAssetThumbs.popover.actions.showScene': 'Show in video',
  'projectEditor.sceneAssetThumbs.transition.isLastIncludedScene': 'The transition will not be shown for the last visible scene',
  'projectEditor.sceneAssetThumbs.transition': 'Transition',
  'projectEditor.tools.introOutro': 'Edit {type}',
  'projectFolder.personal': 'My projects',
  'projectManipulateAsset.controls.mirror': 'Mirror',
  'projectManipulateAsset.handle.crop.bottom': 'Bottom crop handle',
  'projectManipulateAsset.handle.crop.left': 'Left crop handle',
  'projectManipulateAsset.handle.crop.right': 'Right crop handle',
  'projectManipulateAsset.handle.crop.top': 'Top crop handle',
  'projectManipulateAsset.handle.drag': 'Drag handle',
  'projectManipulateAsset.handle.rotate': 'Rotate handle',
  'projectManipulateAsset.handle.scale.bottomLeft': 'Bottom left scale handle',
  'projectManipulateAsset.handle.scale.bottomRight': 'Bottom right scale handle',
  'projectManipulateAsset.handle.scale.topLeft': 'Top left scale handle',
  'projectManipulateAsset.handle.scale.topRight': 'Top right scale handle',
  'projectMusic.music.headline.current': 'Music',
  'projectMusic.music.headline.public': 'Library',
  'projectMusic.music.navigation.all': 'All music',
  'projectMusic.music.navigation.private': 'My music',
  'projectMusic.music.navigation.public': 'By cofenster',
  'projectMusic.music.hint': 'Choose appropriate music for your video from the library',
  'projectMusic.music.subline': 'Music is added during export and automatically adjusted when people speak.',
  'projectMusic.music.remove': 'Remove {name}',
  'projectMusic.music.select': 'Select {name}',
  'projectMusic.music.private': 'My music',
  'projectMusic.music.progress': 'Current soundtrack progress',
  'projectMusic.music.public': 'By cofenster',
  'projectMusic.MusicLoudnessForm.High.description': 'Recommended when the spoken audio is loud, or where the music should be a focus',
  'projectMusic.MusicLoudnessForm.High.title': 'Loud',
  'projectMusic.MusicLoudnessForm.Medium.description': 'Recommended for most cases to go with audio',
  'projectMusic.MusicLoudnessForm.Medium.title': 'Normal',
  'projectMusic.MusicLoudnessForm.Soft.description': 'Recommended when people are speaking softly',
  'projectMusic.MusicLoudnessForm.Soft.title': 'Quiet',
  'projectMusic.volume.title': 'Volume',
  'projectTemplate.pageNotification': 'You are editing a {icon} Project template',
  'projectTemplates.createPopoverText': 'Save as template',
  'projectTemplates.createProjectFromProjectTemplateDialog.createButton': 'Let’s go!',
  'projectTemplates.createProjectFromProjectTemplateDialog.description': 'Select the team in which you want to create your project. You can always move it afterwards.',
  'projectTemplates.createProjectFromProjectTemplateDialog.headline': 'Select a team space',
  'projectTemplates.createProjectTemplateFromProject.dialog.form.description': 'Description (optional)',
  'projectTemplates.createProjectTemplateFromProject.dialog.form.descriptionPlaceholder': 'Describe when and how the template is useful…',
  'projectTemplates.createProjectTemplateFromProject.dialog.form.name': 'Template name',
  'projectTemplates.createProjectTemplateFromProject.dialog.headline': 'Save as template',
  'projectTemplates.createProjectTemplateFromProject.dialog.subheadline': 'Turn frequent use cases into reusable templates. You and your team can leverage them to create videos faster in your preferred style. The original project stays as it is.',
  'projectTemplates.createProjectTemplateFromProject.dialog.submitButton': 'Save as template',
  'projectTemplates.createProjectTemplate.form.nameTitle': 'Select a name',
  'projectTemplates.createProjectTemplate.form.formatTitle': 'Choose a format',
  'projectTemplates.createProjectTemplate.form.submitButton': 'Create template',
  'projectTemplates.createProjectTemplate.page.title': 'Create a template',
  'projectTemplates.createProjectTemplate.snackbar': 'Project saved as template. <link>Edit</link>',
  'projectTemplates.createProjectTemplateForm.description': 'Description (optional)',
  'projectTemplates.createProjectTemplateForm.descriptionPlaceholder': 'Describe when and how the template is useful…',
  'projectTemplates.createProjectTemplateForm.name': 'Template name',
  'projectTemplates.deleteDialog.content': 'Are you sure you want to delete this project template?',
  'projectTemplates.deleteDialog.title': 'Delete project template',
  'projectTemplates.deleteProjectTemplate.snackbar.success': 'Your project template was deleted',
  'projectTemplates.duplicateProjectTemplate.snackbar.success': 'Your project template was duplicated',
  'projectTemplates.editTemplateConfirmDialog.confirm': 'Edit template',
  'projectTemplates.editTemplateConfirmDialog.content': 'New projects created from this template will be based on your changes.',
  'projectTemplates.editTemplateConfirmDialog.title': 'Edit template',
  'projectTemplates.page.createEmptyTemplateButton': 'Create template',
  'projectTemplates.page.search.label': 'Search templates',
  'projectTemplates.page.search.noResults.description': 'Try a different search term',
  'projectTemplates.page.search.noResults.noSearch.title': 'No project templates found',
  'projectTemplates.page.search.noResults.title': 'No project templates found for “{search}”',
  'projectTemplates.page.search.resultsCount': '{count, plural, =0 {No templates} one {# template} other {# templates}}',
  'projectTemplates.page.title': 'Project templates',
  'projectTemplates.previewTemplate.noScenes.description': 'This project template has no scenes',
  'projectTemplates.previewTemplate.noScenes.title': 'No scenes to preview',
  'projectTemplates.projectTile.actions.useTemplate': 'Use template',
  'projectTemplates.projectTile.openPreview': 'Preview',
  'projectTemplates.renameProjectTemplateDialog.headline': 'Rename template',
  'projectTemplates.search.error.description': 'Something went wrong during the search; please try again.',
  'projectTemplates.search.error.title': 'An error occurred',
  'projects.bulkSelector.projects': '{count, plural, =0 {# projects} one {# project} other {# projects}} selected',
  'projects.bulkSelector.selectAll': 'Select all',
  'projects.buttons.folder': 'Add folder',
  'projects.buttons.project': 'Create project',
  'projects.filters.createdBy.ANYONE_BUT_SELF': 'Not created by me',
  'projects.filters.createdBy.ANYONE': 'Created by anyone',
  'projects.filters.createdBy.label': 'Filter projects',
  'projects.filters.createdBy.SELF': 'Created by me',
  'projects.filters.status.ACTIVE': 'Active',
  'projects.filters.status.ALL': 'All',
  'projects.filters.status.ARCHIVED': 'Archived',
  'projects.filters.status.label': 'Filter projects',
  'projects.folderCard.videos': '{count, plural, =0 {No videos} one {# video} other {# videos}}',
  'projects.headline.folders': 'Folders',
  'projects.headline.projects': 'Projects',
  'projects.noArchived.headline': 'No archived projects',
  'projects.noArchived.subline': 'Move a project to your archive and it will show up here',
  'projects.projectCard.bulk.label': 'Select “{name}” for bulk actions',
  'projects.projectCard.creator': 'From {name}',
  'projects.projectCard.edit': 'Edit {name}',
  'projects.projectCard.updatedAt': 'Edited {timeAgo}',
  'projects.projectCard.uploadedScenes': 'Uploaded scenes',
  'projects.projectCard.watch': 'Watch {name}',
  'ProjectSceneOverlays.buttons.addMediaOverlay': 'Add footage',
  'ProjectSceneOverlays.buttons.addOverlays': 'Add elements',
  'ProjectSceneOverlays.buttons.uploadFile': 'Upload a file',
  'ProjectSceneOverlays.feedback.processing': 'Processing file…',
  'ProjectSceneOverlays.feedback.uploading': 'Uploading file…',
  'projectSceneOverlays.lastTimeSaved': 'Last saved at {timeAgo}',
  'ProjectSceneOverlays.MainTimeline.muted': 'The scene is muted',
  'projectSceneOverlays.savingChanges': 'Saving changes…',
  'projectSceneOverlays.tooltips.maxOverlays': 'Maximum of {max} overlays reached',
  'projectsFolder.noProjectsAndPermission.headline': 'No projects here so far',
  'projectsFolder.noProjectsAndPermission.subline': 'Ask your administrator for editing rights to start creating your own',
  'projectsFolderList.showLess': 'Show less',
  'projectsFolderList.showMore': 'Show all',
  'projectStoryboard.AddSceneCard.text': 'Add scene',
  'projectSubtitles.CreateTranscriptionCard.buttonText': 'Add subtitles',
  'projectSubtitles.CreateTranscriptionCard.headline': 'Add subtitles to your video',
  'projectSubtitles.CreateTranscriptionCard.subline': 'You can add translations afterwards as well',
  'projectSubtitles.deleteTranscriptionDialog.confirm': 'Delete subtitles',
  'projectSubtitles.deleteTranscriptionDialog.description': 'You will need to create new subtitles before translating. Are you sure you want to delete your subtitles?',
  'projectSubtitles.deleteTranscriptionDialog.text1': '{translationsCount, plural, =0 {} one {Your translation} other {Your {translationsCount} translations}} will be deleted as well.',
  'projectSubtitles.deleteTranscriptionDialog.text2': 'Are you sure you want to delete your subtitles?',
  'projectSubtitles.deleteTranscriptionDialog.title': 'Delete subtitles',
  'projectSubtitles.deleteTranslation.confirm': 'Delete translation',
  'projectSubtitles.deleteTranslation.description': 'All your progress will be lost. Are you sure you want to delete your translation?',
  'projectSubtitles.deleteTranslation.title': 'Delete translation',
  'projectSubtitles.editDialog.editSubtitle': 'Edit subtitles',
  'projectSubtitles.editDialog.editTranslation': 'Edit translation',
  'projectSubtitles.error.buttonText': 'Request support',
  'projectSubtitles.error.description': 'A technical problem stopped the creation',
  'projectSubtitles.error.headline': 'Something went wrong on our end',
  'projectSubtitles.missingReviewDialog.continueToAdd': 'Add translation',
  'projectSubtitles.missingReviewDialog.description': 'The translations are based on your subtitles. To ensure the best result for your translations review  your subtitles first.',
  'projectSubtitles.missingReviewDialog.review': 'Review subtitles',
  'projectSubtitles.missingReviewDialog.title': 'Review subtitles',
  'projectSubtitles.projectChanged.description': 'Editing changes were made to the project. To include these changes update your video or download the current version.',
  'projectSubtitles.projectChanged.download': 'Download video',
  'projectSubtitles.projectChanged.headline': 'Update video',
  'projectSubtitles.projectChanged.updateVideo': 'Update video',
  'projectSubtitles.recreateSubtitles.buttonText': 'Recreate subtitles',
  'projectSubtitles.recreateSubtitles.header': 'Recreate subtitles',
  'projectSubtitles.recreateSubtitles.text1': 'Your subtitle {translationsCount, plural, =0 {} one {and translation} other {and {translationsCount} translations}} will be deleted.',
  'projectSubtitles.recreateSubtitles.text2': ' Are you sure you want to recreate your subtitles?',
  'projectSubtitles.recreateTranslation.buttonText': 'Recreate translation',
  'projectSubtitles.recreateTranslation.header': 'Recreate translation',
  'projectSubtitles.recreateTranslation.text': 'All your progress will be lost. Are you sure you want to recreate your translation?',
  'projectSubtitles.RenderVideoCard.buttonText': 'Export video first',
  'projectSubtitles.RenderVideoCard.headline': 'Add subtitles to your video',
  'projectSubtitles.RenderVideoCard.subline': 'Please export your video first before adding subtitles',
  'projectSubtitles.status.exportError': 'Error',
  'projectSubtitles.status.exportInProgress': 'Exporting…',
  'projectSubtitles.status.transcriptionInProgress.description': 'Automatically creating subtitles for this scene',
  'projectSubtitles.status.transcriptionInProgress': 'Creating subtitles',
  'projectSubtitles.status.translationInProgress': 'Translating…',
  'projectSubtitles.status.undatingInProgress': 'Updating…',
  'projectSubtitles.subtitleInputLabel': 'Subtitle between {start} and {end}',
  'projectSubtitles.subtitles': 'Subtitles',
  'projectSubtitles.subtitlesCard.actionButtons.download': 'Download',
  'projectSubtitles.subtitlesCard.actionButtons.edit': 'Edit',
  'projectSubtitles.subtitlesCard.actionButtons.export': 'Export video',
  'projectSubtitles.subtitlesCard.actionButtons.update': 'Update video',
  'projectSubtitles.subtitlesCard.popover.deleteSubtitle': 'Delete subtitles',
  'projectSubtitles.subtitlesCard.popover.deleteTranslation': 'Delete translation',
  'projectSubtitles.subtitlesCard.popover.regenerateSubtitle': 'Recreate subtitles',
  'projectSubtitles.subtitlesCard.popover.regenerateTranslation': 'Recreate translation',
  'projectSubtitles.subtitlesCard.popover.srtDownload': 'Download .srt ',
  'projectSubtitles.subtitlesCard.popover.vttDownload': 'Download .vtt ',
  'projectSubtitles.subtitlesChangedDialog.description': 'Editing changes were made to the subtitles. To include these changes update your video or download the current version.',
  'projectSubtitles.subtitlesChangedDialog.download': 'Download video',
  'projectSubtitles.subtitlesChangedDialog.headline': 'Update video',
  'projectSubtitles.subtitlesChangedDialog.update': 'Update video',
  'projectSubtitles.subtitlesNodeAddLabel': 'Add line',
  'projectSubtitles.subtitlesNodeMergeLines': 'Merge lines',
  'projectSubtitles.subtitlesNodeSplitLine': 'Split line',
  'projectSubtitles.subtitlesNodeDeleteLabel': 'Delete line',
  'projectSubtitles.subtitlesTimestampLabel': 'Subtitle “{type}” time at {time}',
  'projectSubtitles.translation.new': 'Add translated subtitles',
  'projectSubtitles.translations': 'Translations',
  'projectTranscription.button': 'Create subtitles',
  'projectTranscription.header': 'Add subtitles',
  'projectTranscription.languageSelect.label': 'Spoken language',
  'projectTranscription.languageSelect.placeholder': 'Choose spoken language',
  'projectTranscription.progress.description': 'Converting speech into text',
  'projectTranscription.progress.header': 'Creating subtitles for your video',
  'projectTranscription.title': 'What language do people speak in your video?',
  'projectTranslation.banner.description': 'The more accurate and well reviewed your subtitles are, the better the translation!',
  'projectTranslation.banner.title': 'How to get the best result for your translation',
  'projectTranslation.button': 'Create translation',
  'projectTranslation.header': 'Add translation',
  'projectTranslation.languageSelect.label': 'Translation language',
  'projectTranslation.languageSelect.placeholder': 'Choose translation language',
  'projectTranslation.loading.description': 'Translating based on your subtitles',
  'projectTranslation.loading.title': 'Creating translations',
  'projectTranslation.title': 'What language do you want to translate your subtitles to?',
  'projectVideo.exportListCard.moreMenu.editSubtitles': 'Edit subtitles',
  'projectVideo.exportListCard.moreMenu.editVideo': 'Edit video',
  'projectVideo.exportListCard.title.originalVideo': 'Original video',
  'projectVideo.exportListCard.title.subtitledVideo': '{language} subtitles',
  'projectVideo.notYetRendered.subtitle': 'Let us work some magic to finalize your video.',
  'projectVideo.notYetRendered.title': 'Export your video',
  'projectVideo.notYetRendered.withSubtitles.subtitle': 'Export the video with {language} subtitles',
  'projectVideo.notYetRendered.withSubtitles.title': 'Export your subtitled video',
  'projectVideo.renderButton.emergency': 'Our services are undergoing an unscheduled maintenance; exporting will be back in a moment.',
  'projectVideo.renderButton.tooLong.absolute': 'Unfortunately, your video is too long for us to render ({duration}, while the maximum is {max}). Please, reach out to us so we can support you with your export.',
  'projectVideo.renderButton.tooLong.relative': 'This video is too long for you to render ({duration}, while the maximum is {max}). Shorten it, or reach out to us to discuss adjusting your plan.',
  'projectVideo.renderedVideo.downloadSubtitles': '{format} subtitles',
  'projectVideo.renderedVideo.lastExported': 'Exported {timeAgo}',
  'projectVideo.renderedVideo.pendingChanges': 'Pending changes',
  'projectVideo.renderedVideo.update': 'Update video',
  'projectVideo.renderJobFailed.subtitle': 'A technical problem stopped the export.',
  'projectVideo.renderJobFailed.title': 'Something went wrong on our end',
  'quickActions.recordScreen.button': 'Record screen',
  'quickActions.recordYourself.button': 'Record yourself',
  'reset.again': 'You didn’t receive an email? <link>Request</link> a new one.',
  'reset.form.password.placeholder': 'New password',
  'reset.form.passwordConfirmation.placeholder': 'Confirm password',
  'reset.headline': 'Welcome back to cofenster!',
  'reset.subline': 'Please enter a new password to login to your cofenster account.',
  'reset.title': 'Password reset',
  'resetSent.headline': 'Please check your inbox.',
  'resetSent.subline': 'We have sent a password recover link to your email address.',
  'resetSent.title': 'Email sent',
  'roleRequest.button.request': 'Request permissions',
  'roleRequest.button.requestSharingLinkPermissions': 'Request to publish',
  'roleRequest.pending.text': 'Pending request',
  'roleRequests.item.approve': 'Approve',
  'roleRequests.item.content': '{userName} requests upgrade to {roleName} role',
  'roleRequests.item.deny': 'Deny',
  'roleRequests.item.requester': 'User',
  'roleRequests.title': 'The following members expect a role upgrade',
  'sceneAsset.recordYourselfButton.image': 'Take photo',
  'sceneAsset.recordYourselfButton.screen': 'Record screen',
  'sceneAsset.recordYourselfButton.video': 'Record video',
  'sceneAsset.stock.image': 'Add stock photo',
  'sceneAsset.stock.video': 'Add stock video',
  'SceneAudioOverlay.headline': 'Audio',
  sceneExcluded: 'Scene excluded from video',
  'sceneImage.dropzone.text': 'Drop your image here or browse',
  'sceneImage.error': 'Something went wrong, please try again.',
  'sceneImage.processing': 'Almost done, your image will be ready soon!',
  'sceneImage.uploading': 'Your image is uploading',
  'sceneSubtitle.bottomNavigation.nthSceneLabel': 'Scene #{nth}',
  'sceneSubtitle.bottomNavigation.playPause.durationTooltip': 'Total duration of scene #{nth}',
  'sceneSubtitle.bottomNavigation.sceneWithoutAssetTooltip': 'Scene has no asset',
  'sceneSubtitle.bottomNavigation.selectNthSceneLabel': 'Select scene #{nth}',
  'sceneSubtitle.createForm.createSubtitlesButton.text': 'Create subtitles',
  'sceneSubtitle.createForm.description': 'This scene has no subtitles yet',
  'sceneSubtitle.createForm.formType': 'Method',
  'sceneSubtitle.createForm.title': 'Create subtitles',
  'sceneSubtitle.createForm.whatLanguageToCaption.title': 'What language do you want to caption?',
  'sceneSubtitle.CreateSceneSubtitleTypeSelect.automaticTranslation.description': 'We caption the scene for you',
  'sceneSubtitle.CreateSceneSubtitleTypeSelect.automaticTranslation.title': 'Automatic subtitles',
  'sceneSubtitle.CreateSceneSubtitleTypeSelect.fromSRT.description': 'Provider a file (.srt)',
  'sceneSubtitle.CreateSceneSubtitleTypeSelect.fromSRT.title': 'Upload subtitles file',
  'sceneSubtitle.CreateSceneSubtitleTypeSelect.manualTranslation.description': 'You write your own subtitles',
  'sceneSubtitle.CreateSceneSubtitleTypeSelect.manualTranslation.title': 'Manual subtitles',
  'sceneSubtitle.currentSubtitleHeader.originalLanguage': 'Original language',
  'sceneSubtitle.currentSubtitleHeader.spokenLanguage': 'Spoken language',
  'sceneSubtitle.currentSubtitleHeader.title': 'Scene subtitles',
  'sceneSubtitle.deleteSubtitleDialog.confirm': 'Delete subtitles',
  'sceneSubtitle.deleteSubtitleDialog.content': 'You will permanently delete the {language} subtitles created for this scene.',
  'sceneSubtitle.deleteSubtitleDialog.title': 'Delete subtitles',
  'sceneSubtitle.deleteSubtitleLanguageDialog.confirm': 'Delete language',
  'sceneSubtitle.deleteSubtitleLanguageDialog.content': 'You will permanently delete the all {language} subtitles created for this project (all scenes).',
  'sceneSubtitle.deleteSubtitleLanguageDialog.title': 'Delete language',
  'sceneSubtitle.dropzone.hintText': '.SRT file format',
  'sceneSubtitle.dropzone.selected.noNamePlaceholder': 'SRT is ready to be uploaded',
  'sceneSubtitle.dropzone.text': 'Drop your subtitles file here or browse',
  'sceneSubtitle.editor.node.contentInput.label': 'Subtitles content of line #{index}',
  'sceneSubtitle.editor.node.delete.label': 'Delete subtitles line #{index}',
  'sceneSubtitle.editor.node.endTimeInput.label': 'End time of subtitles line #{index}',
  'sceneSubtitle.editor.node.excluded': 'This part was cut',
  'sceneSubtitle.editor.node.excludedPartially': 'This part was partially cut',
  'sceneSubtitle.editor.node.startTimeInput.label': 'Start time of subtitles line #{index}',
  'sceneSubtitle.emptySubtitles.createFirstNodeButton': 'Create first line',
  'sceneSubtitle.emptySubtitles.description': 'Add a subtitles line to the scene and start captioning.',
  'sceneSubtitle.emptySubtitles.title': 'No subtitles',
  'sceneSubtitle.error.description': 'There was an error on our end when creating the subtitles for this scene. If the error persists ({error}), you can manually create the subtitles instead.',
  'sceneSubtitle.error.primaryActionButton': 'Retry subtitles creation',
  'sceneSubtitle.error.secondaryActionButton': 'Create manually',
  'sceneSubtitle.error.title': 'Subtitles creation failed',
  'sceneSubtitle.forms.languageRequired': 'Language is required',
  'sceneSubtitle.forms.otherSceneSubtitleIdRequired': 'Language from other scene subtitles is required',
  'sceneSubtitle.forms.srtContentRequired': 'Please provide a SRT file',
  'sceneSubtitle.languageSelectorMenu.addLanguageButton': 'Add language',
  'sceneSubtitle.languageSelectorMenu.addLanguageButtonForAllScenes': 'Add language',
  'sceneSubtitle.languageSelectorMenu.deleteSubtitle': 'Delete subtitles',
  'sceneSubtitle.languageSelectorMenu.downloadSRT': 'Download SRT subtitles',
  'sceneSubtitle.languageSelectorMenu.downloadVTT': 'Download VTT subtitles',
  'sceneSubtitle.languageSelectorMenu.moreOptions': 'More subtitles options',
  'sceneSubtitle.languageSelectorMenu.scenesSubtitled': '{count, plural, one {# scene subtitled} other {# scenes subtitled}}',
  'sceneSubtitle.manageLanguage.deleteLanguage': 'Delete language',
  'sceneSubtitle.manageLanguage.downloadSRT': 'Download SRT subtitles',
  'sceneSubtitle.manageLanguage.downloadVTT': 'Download VTT subtitles',
  'sceneSubtitle.manageLanguage.triggerButtonText': 'More',
  'sceneSubtitle.nothingToTranscribe.buttonText': 'Add subtitles',
  'sceneSubtitle.nothingToTranscribe.description': 'You can still add subtitles',
  'sceneSubtitle.nothingToTranscribe.title': 'No voice detected',
  'sceneSubtitle.pages.title': 'Subtitles',
  'sceneSubtitle.select.languages': 'Languages',
  'sceneSubtitle.select.usedInThisProjectTitle': 'Used in this project',
  'sceneSubtitle.slider.cutOutSection.tooltip': 'This section is cut out',
  'sceneSubtitle.slider.cutOutSection.tooltipCutOutButtonTooltip': 'Go to cutting',
  'sceneSubtitle.styles.applyToAllScenes': 'Apply to all scenes',
  'sceneSubtitle.styles.applyToAllScenes.successSnackbar': 'Successfully applied on all scenes',
  'sceneSubtitle.styles.positionBottom': 'Bottom',
  'sceneSubtitle.styles.positionMiddle': 'Middle',
  'sceneSubtitle.styles.positionTop': 'Top',
  'sceneSubtitle.styles.menuButtonText': 'Styles',
  'sceneSubtitle.styles.suggested': 'Recommended',
  'sceneSubtitle.transcription.autoDetected': '(auto-detected)',
  'sceneSubtitle.transcription.failed.description': 'There was an error on our end when transcribing your scene. If the error persists, you can manually create the subtitles instead.',
  'sceneSubtitle.transcription.failed.primaryAction.button.text': 'Try again',
  'sceneSubtitle.transcription.failed.title': 'Transcription failed',
  'sceneSubtitle.transcription.ready': '(transcript ready)',
  'sceneSubtitle.transcription.spokenLanguageLabel': 'Language spoken in the video',
  'sceneSubtitle.transcription.waitingForTranscription': 'Transcribing your scene…',
  'sceneSubtitle.translateForm.applyToAllScenes': 'Apply {language} to all scenes',
  'sceneSubtitle.translateForm.description': 'Translate this scene to {language}',
  'sceneSubtitle.translateForm.fromOtherScene.description': 'We translate for you',
  'sceneSubtitle.translateForm.fromOtherScene.title': 'Translate automatically',
  'sceneSubtitle.translateForm.fromSRT.description': 'You provide the translation',
  'sceneSubtitle.translateForm.fromSRT.title': 'Upload SRT file',
  'sceneSubtitle.translateForm.manual.description': 'You do your own translation',
  'sceneSubtitle.translateForm.manual.title': 'Create manually',
  'sceneSubtitle.translateForm.submitButtonText': 'Translate',
  'sceneSubtitle.translateForm.title': 'Translate subtitles',
  'sceneSubtitle.translating.description': 'We are translating the subtitles for you',
  'sceneSubtitle.translating.title': 'Translating subtitles',
  'sceneSubtitle.translationQualityHint.description': 'Review original subtitles before translating to ensure an optimal result',
  'sceneSubtitle.translationQualityHint.title': 'Tip for perfect translations',
  'sceneSubtitle.waitingForTheAsset.message': 'Waiting for the scene to be ready',
  'SceneTextsPanel.delete.error': 'We failed to delete this text. Please try again later.',
  'SceneTextsPanel.form.error.required': 'Please fill in this content',
  'SceneTextsPanel.headline.content': 'Content',
  'SceneTextsPanel.headline.style': 'Style',
  'SceneTextsPanel.headline': 'Text',
  'SceneTextsPanel.save.error': 'We failed to save your text. Please try again later.',
  'sceneVideo.dropzone.text': 'Drop your video here or browse',
  'sceneVideo.error': 'Something went wrong, please try again.',
  'sceneVideo.processing': 'Almost done, your video will be ready soon!',
  'sceneVideo.uploading': 'Your video is uploading',
  'set.form.submit': 'Create account',
  'set.subline': 'Please create a password to login to your new cofenster account',
  'set.title': 'Password creation',
  'set.tnc': 'By continuing and creating your account, you agree to cofenster’s <tnc>terms and conditions</tnc> and <data>data privacy policy</data>.',
  'settingsAccount.account.headline': 'Account',
  'settingsAccount.accountForm.name.label': 'Name',
  'settingsAccount.accountForm.name.placeholder': 'Name',
  'settingsAccount.AccountUserCreate.headline': 'Invite new member',
  'SettingsAccount.ConsentDropzone.error.encrypted': 'It seems your PDF is encrypted, so we cannot process it.',
  'SettingsAccount.ConsentDropzone.error': 'Something went wrong while uploading your document.',
  'SettingsAccount.ConsentDropzone.hint': 'Non-encrypted .pdf, max 10MB',
  'SettingsAccount.ConsentDropzone.hintText': 'Your consent form is uploading',
  'SettingsAccount.ConsentDropzone.text': 'Drop your PDF file here or browse',
  'SettingsAccount.ConsentForm.collectingConsent': 'Upload your account consent form as a PDF. You can make this a requirement to participate in video projects. Read more about <link>collecting consent</link>.',
  'SettingsAccount.ConsentForm.dialog.content': 'Are you sure you want to delete the consent form?',
  'SettingsAccount.ConsentForm.dialog.header': 'Delete consent form',
  'SettingsAccount.ConsentForm.requireConsent': 'Require consent from participants',
  'SettingsAccount.ConsentForm.uploadedAt': 'uploaded {uploadedAt}',
  'settingsAccount.consents.headline': 'Consent',
  'settingsAccount.consents.subheadline': 'Collected consents',
  'settingsAccount.headline': 'Account',
  'settingsAccount.members.headline': 'Members',
  'settingsAccount.passwordForm.error.invalidCredentials': 'The current password is not correct.',
  'settingsAccount.passwordForm.newPassword.label': 'New password',
  'settingsAccount.passwordForm.newPassword.placeholder': 'New password',
  'settingsAccount.passwordForm.newPasswordRepeat.label': 'Repeat new password',
  'settingsAccount.passwordForm.newPasswordRepeat.placeholder': 'Repeat new password',
  'settingsAccount.passwordForm.oldPassword.label': 'Current password',
  'settingsAccount.passwordForm.oldPassword.placeholder': 'Current password',
  'settingsAccount.personal.headline': 'Personal settings',
  'settingsAccount.teams.headline': 'Teams',
  'settingsAccount.userForm.email.label': 'Email',
  'settingsAccount.userForm.email.placeholder': 'Email address',
  'settingsAccount.userForm.error.emailExists': 'This email address is already in use.',
  'settingsAccount.userForm.firstname.label': 'First name',
  'settingsAccount.userForm.firstname.placeholder': 'First name',
  'settingsAccount.userForm.lastname.label': 'Last name',
  'settingsAccount.userForm.lastname.placeholder': 'Last name',
  'settingsConsent.headline': 'Consent form',
  'settingsCookie.button': 'Edit cookie consent',
  'settingsCookie.headline': 'Cookie consent',
  'settingsCookie.text': 'To learn more about the data we collect to improve our products, please refer to our data privacy policy. You can always change your consent at any time.',
  'settingsMembers.addUser': 'Invite member',
  'settingsPersonal.headline.changePassword': 'Change password',
  'settingsPersonal.headline.details': 'Details',
  'SharingLinkSettingsDialog.availableSubtitlesSection': 'Available subtitles',
  'SharingLinkSettingsDialog.notExportedYet': 'This language is available after exporting the subtitled video.',
  'SharingLinkSettingsDialog.subtitleLanguagesLegend': 'Subtitle languages visible in exported video',
  'SharingLinkSettingsDialog.successSnackbar': 'Successfully saved the sharing settings.',
  'SharingLinkSettingsDialog.title': 'Sharing settings',
  'sharingOptions.copyEmbed': 'Copy embed code',
  'sharingOptions.email': 'Share via Email',
  'sharingOptions.facebook': 'Share on Facebook',
  'sharingOptions.linkedin': 'Share on Linkedin',
  'signin.form.email.placeholder': 'Email address',
  'signin.form.error.invalidCredentials': 'Invalid email address or password',
  'signin.form.forgot': 'Forgot password?',
  'signin.form.password.placeholder': 'Password',
  'signin.form.submit': 'Log in',
  'signin.headline': 'Welcome to cofenster!',
  'signin.title': 'Sign in',
  'SignInError.headline': 'There was a problem signing you in.',
  'SignInError.requestAccess': 'Ask for help',
  'SignInError.subline': 'Send us a message from the chat bubble if you need support.',
  'SignInError.title': 'Signin error',
  'snackbars.folderArchivedSnackbar.copy': 'Your folder was moved into the archive.',
  'snackbars.folderDeletedSnackbar.copy': 'Your folder was deleted.',
  'snackbars.folderUnarchivedSnackbar.copy': 'Your folder was restored from the archive.',
  'snackbars.projectCreatedSnackbar.copy': '🎉 Project created! Add scenes to create your story.',
  'snackbars.projectDuplicatedSnackbar.copy': 'Your project was duplicated.',
  'snackbars.projectFolderMoved.copy': 'Folder moved to “{teamName}”',
  'snackbars.projectsArchivedSnackbar.copy': '{count, plural, =0 {Nothing} one {Your project was} other {Your projects were}} archived.',
  'snackbars.projectsDeletedSnackbar.copy': '{count, plural, =0 {Nothing} one {Your project was} other {Your projects were}} deleted.',
  'snackbars.projectsMovedSnackbar.copy': '{count, plural, =0 {Nothing} one {Your project was} other {Your projects were}} moved.',
  'snackbars.projectsUnarchivedSnackbar.copy': '{count, plural, =0 {Nothing} one {Your project was} other {Your projects were}} unarchived.',
  'snackbars.ShowSceneSnackbar.title.interactive': 'This scene is hidden: <button>Show in video</button> to preview it.',
  'snackbars.ShowSceneSnackbar.title': 'This scene is hidden.',
  'snackbars.userDeactivatedSnackbar.copy': '😴 The member was succesfully deactivated!',
  'snackbars.UserDeletedSnackbar.copy': 'The member “{name}” was deleted',
  'snackbars.userReactivatedSnackbar.copy': '🎉 The member was succesfully reactivated!',
  'snackbars.UserReinvitedSnackbar.copy': '✉  An invitation was sent to {email}',
  'snackbars.videoLink.copy': 'Sharing link copied to clipboard',
  'snackbars.videoLink.embed': 'Embed code copied to clipboard',
  'subNavigation.backToEditing': 'Back to editing',
  'subNavigation.branding.musics': 'Music',
  'subNavigation.branding.themes': 'Themes',
  'subNavigation.design': 'Design',
  'subNavigation.edit': 'Editing',
  'subNavigation.members': 'Members',
  'subNavigation.more': 'More',
  'subNavigation.moreLanguages': '{count} more {count, plural, one {language} other {languages}}',
  'subNavigation.music': 'Music',
  'subNavigation.settings.account': 'Account',
  'subNavigation.settings.consent': 'Consent',
  'subNavigation.settings.members': 'Members',
  'subNavigation.settings.notifications': 'My notifications',
  'subNavigation.settings.personal': 'Personal settings',
  'subNavigation.settings.teams': 'Teams',
  'subNavigation.settings': 'Settings',
  'subNavigation.subtitles': 'Subtitles',
  'subNavigation.video': 'Video',
  'team.access.info': 'Access cannot be changed later on.',
  'team.access': 'Access',
  'team.addMembers': 'Add members',
  'team.addTeam.create': 'Create team',
  'team.addTeam.decription': 'Start a space for your team to work on video',
  'team.delete.info': 'Permanently delete this team and content inside it. Be sure to move videos you want to keep to another team before deleting this one.',
  'team.delete': 'Delete team',
  'team.details': 'Details',
  'team.error.nameLength': 'The team name cannot be longer than 30 characters.',
  'team.error.nameRequired': 'Name is required',
  'team.leave': 'Leave team',
  'team.member': 'Member',
  'team.members.all': 'All',
  'team.members': 'Members',
  'team.name': 'Team Name',
  'team.owner': 'Owner',
  'team.private.info': 'Only members you add can join',
  'team.private': 'Private team',
  'team.public.info': 'Everyone will be able to contribute',
  'team.public': 'Open Team',
  'team.role.Admin.description': 'Manage the account, teams and roles of people in the account.',
  'team.role.Admin.warning': 'You cannot update the role of the only account Admin.',
  'team.role.Admin': 'Admin',
  'team.role.Contributor.description': 'View projects. Contribute scenes and add translations.',
  'team.role.Contributor': 'Contributor',
  'team.role.Editor.description': 'Manage projects. Edit projects and share contribution links.',
  'team.role.Editor': 'Editor',
  'team.role.help': 'Learn more about roles',
  'team.role.Owner.description': 'Own the team. Manage roles of people in the team.',
  'team.role.Owner': 'Owner',
  'team.role.Publisher.description': 'Manage projects. Edit, export, download and share projects.',
  'team.role.Publisher': 'Publisher',
  'team.role.Translator.description': 'View subtitles. Add translations to projects.',
  'team.role.Translator': 'Translator',
  'team.role.Viewer': 'Viewer',
  'team.save': 'Save',
  'team.settings': 'Settings',
  'upsellBanner.actions.contact': 'Contact us',
  'upsellBanner.actions.info': 'Learn more',
  'upsellBanner.CONSENT.content': 'Make sure video participants contribute in a legally safe way',
  'upsellBanner.CONSENT.title': 'Automatically collect consent',
  'upsellBanner.CUSTOM_MUSICS.content': 'Upgrade your plan for advanced music features',
  'upsellBanner.CUSTOM_MUSICS.title': 'Use your own soundtracks',
  'upsellBanner.MULTIPLE_TEAMS.content': 'Upgrade your plan to better organize your account',
  'upsellBanner.MULTIPLE_TEAMS.title': 'Get access to multiple teams',
  'upsellBanner.SCENE_OVERLAYS.content': 'Create more engaging videos with video overlays by upgrading your plan.',
  'upsellBanner.SCENE_OVERLAYS.title': 'Add background footage to your videos',
  'upsellBanner.SPECIAL_FORMATS.content': 'Expand your available design options',
  'upsellBanner.SPECIAL_FORMATS.title': 'Create videos tailored for social media',
  'upsellBanner.STOCK_LIBRARY.content': 'Upgrade your plan to create videos with stock footage',
  'upsellBanner.STOCK_LIBRARY.title': 'Get access to our stock library',
  'upsellBanner.VIDEO_ANALYTICS.title': 'Expand your plan for insights into viewing behaviour',
  'userRole.role': 'Role',
  'videoAnalytics.enableLink': 'Anyone with the link can watch it',
  'videoAnalytics.enableSharing.actionButton': 'Enable sharing',
  'videoAnalytics.enableSharing.description': 'Gather insights about viewer engagement by enabling public sharing',
  'videoAnalytics.enableSharing.title': 'Share a link to your video',
  'videoAnalytics.share': 'Share',
  'videoAnalytics.sharingLinkDisabledTooltip': 'Public sharing is available to Publishers and Admins',
  'videoAnalytics.sharingLinkSettingsButtonLabel': 'Sharing link settings',
  'videoAnalytics.totalViews.description': 'Times this video is watched',
  'videoAnalytics.totalViews.title': 'Total views',
  'videoAnalytics.uniqueViews.description': 'Individuals who saw the video',
  'videoAnalytics.uniqueViews.title': 'Unique views',
  'videoAnalytics.watchTime.description': 'Average time spent watching the video',
  'videoAnalytics.watchTime.title': 'Average view duration',
};
