import type { FC } from 'react';

import {
  BaseLink,
  PopoverMenuDivider,
  PopoverMenuItem,
  styled,
  useGoto,
  useLocalizedWebsiteUrl,
} from '@cofenster/web-components';

import { AccountPermissionRestriction } from '../../../../../../../contexts/user/AccountPermissionRestriction';
import { type User, useUser } from '../../../../../../../contexts/user/useUser';
import { routes } from '../../../../../../../routes';
import { RouterLink } from '../../../../../../button/RouterLink';

import { PopoverProfileLink } from './PopoverProfileLink';

const Container = styled('div')(() => ({
  minWidth: 300,
}));

export type UserProfilePopoverProps = {
  user: User;
};

const SettingsAccountLink: FC<{ 'data-testid'?: string }> = (props) => <RouterLink to="settingsAccount" {...props} />;
const SettingsPersonalLink: FC<{ 'data-testid'?: string }> = (props) => <RouterLink to="settingsPersonal" {...props} />;
const SettingsBrandingThemesLink: FC<{ 'data-testid'?: string }> = (props) => (
  <RouterLink to="brandingThemes" {...props} />
);
const HelpLink: FC<{ href: string; 'data-testid'?: string }> = (props) => <BaseLink target="_blank" {...props} />;

export const UserProfilePopover: FC<UserProfilePopoverProps> = ({ user }) => {
  const goto = useGoto();
  const { signout } = useUser();
  const learnMoreUrl = useLocalizedWebsiteUrl('KNOWLEDGE_BASE');

  return (
    <Container>
      <PopoverProfileLink
        name={user.name}
        accountName={user.account.name}
        onClick={() => goto(routes.settingsPersonal)}
      />

      <PopoverMenuDivider />

      <PopoverMenuItem icon="AccountIcon" component={SettingsAccountLink} data-testid="account-settings-link">
        i18n.subNavigation.settings.account
      </PopoverMenuItem>

      <AccountPermissionRestriction has="ThemeRead">
        <PopoverMenuItem icon="BrandingIcon" component={SettingsBrandingThemesLink} data-testid="branding-themes-link">
          i18n.common.branding
        </PopoverMenuItem>
      </AccountPermissionRestriction>

      <PopoverMenuItem icon="GlobeIcon" component={SettingsPersonalLink} data-testid="language-settings-link">
        i18n.common.language
      </PopoverMenuItem>
      <PopoverMenuItem
        icon="SupportIcon"
        component={HelpLink}
        data-testid="support-link"
        href={learnMoreUrl}
        closePopupOnClick
      >
        i18n.common.help
      </PopoverMenuItem>

      <PopoverMenuDivider />

      <PopoverMenuItem
        icon={user.isImpersonated ? 'UserSwitchIcon' : 'SignOutIcon'}
        onClick={async () => {
          await signout();
          if (user.isImpersonated) window.close();
          else goto(routes.home);
        }}
        data-testid="logout-button"
      >
        {user.isImpersonated ? 'i18n.mainNavigation.logout.admin' : 'i18n.mainNavigation.logout'}
      </PopoverMenuItem>
    </Container>
  );
};
