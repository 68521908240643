import { useMemo } from 'react';

import {
  type CreateSceneFromContributionRequestAssetInput,
  useCreateSceneFromContributionRequestAssetMutation,
} from '../../generated';

export const useCreateSceneFromContributionRequestAsset = () => {
  const [createSceneFromContributionRequestAsset, { loading }] = useCreateSceneFromContributionRequestAssetMutation({
    refetchQueries: ['ContributionRequestById', 'Scenes', 'ContributionRequestsByProject'],
  });

  return useMemo(
    () => ({
      createSceneFromContributionRequestAsset: (inputs: CreateSceneFromContributionRequestAssetInput[]) =>
        createSceneFromContributionRequestAsset({
          variables: {
            inputs,
          },
        }),
      loading,
    }),
    [createSceneFromContributionRequestAsset, loading]
  );
};
