import type { FC } from 'react';
import { Outlet, Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';

import { CookieBanner } from '@cofenster/web-components';

import { ErrorBoundary } from './components/ErrorBoundary';
import { IntercomWidget } from './components/common/IntercomWidget';
import { ImpersonationBanner } from './components/page/Layout/ImpersonationBanner';
import { EnsureUser } from './contexts/user/EnsureUser';
import { DeactivatedUser } from './pages/DeactivatedUser';
import { ForgotPassword } from './pages/ForgotPassword';
import { Home } from './pages/Home';
import { NoAccessContent, NoAccessLayout } from './pages/NoAccess';
import { ProjectContributionConfiguration } from './pages/ProjectContributionConfiguration';
import { ProjectCreate } from './pages/ProjectCreate';
import { ProjectDesign } from './pages/ProjectDesign';
import { ProjectEditor } from './pages/ProjectEditor';
import { ProjectFolder } from './pages/ProjectFolder';
import { ProjectMusic } from './pages/ProjectMusic';
import { ProjectRedirect } from './pages/ProjectRedirect';
import { ProjectVideo } from './pages/ProjectVideo';
import { ResetPassword } from './pages/ResetPassword';
import { ResetSent } from './pages/ResetSent';
import { SetPassword } from './pages/SetPassword';
import { SignIn } from './pages/SignIn';
import { SignInError } from './pages/SignInError';
import { TeamMembers } from './pages/TeamMembers';
import { TeamSettings } from './pages/TeamSettings';
import { routes } from './routes';
import { Sentry } from './sentry';

const Root: FC = () => {
  return (
    <ErrorBoundary>
      <Outlet />
      <CookieBanner />
      <ImpersonationBanner />
      <IntercomWidget />
    </ErrorBoundary>
  );
};

export const router = Sentry.wrapCreateBrowserRouter(createBrowserRouter)(
  createRoutesFromElements(
    <Route path="/" element={<Root />}>
      <Route path={routes.signin} element={<SignIn />} />
      <Route path={routes.forgotPassword} element={<ForgotPassword />} />
      <Route path={routes.deactivatedUser} element={<DeactivatedUser />} />
      <Route path={routes.resetSent} element={<ResetSent />} />
      <Route path={routes.resetPassword} element={<ResetPassword />} />
      <Route path={routes.setPassword} element={<SetPassword />} />
      <Route path={routes.signInError} element={<SignInError />} />

      <Route element={<EnsureUser />}>
        <Route path={routes.home} element={<Home />} />
        <Route path={routes.teamSettings} element={<TeamSettings />} />
        <Route path={routes.teamMembers} element={<TeamMembers />} />

        <Route path={routes.projectFolder} element={<ProjectFolder />} />

        <Route path={routes.projectCreate} element={<ProjectCreate />} />
        <Route path={routes.project} element={<ProjectRedirect />} />

        <Route path={routes.projectContributionsConfiguration} element={<ProjectContributionConfiguration />} />
        <Route path={routes.projectEdit} element={<ProjectEditor />} />
        <Route path={routes.projectEditScene} element={<ProjectEditor />} />

        <Route path={routes.projectCutScene} lazy={() => import('./pages/ProjectCutScene')} />
        <Route path={routes.projectSceneOverlays} lazy={() => import('./pages/ProjectSceneOverlays')} />
        <Route path={routes.projectManipulateAsset} lazy={() => import('./pages/ProjectManipulateAsset')} />

        <Route
          path={routes.projectCaptureSceneAsset}
          lazy={() => import(/* webpackChunkName: "capture" */ './pages/CollectSceneAssetPage')}
        />
        <Route
          path={routes.recordVideoBriefing}
          lazy={() => import(/* webpackChunkName: "capture" */ './pages/RecordVideoBriefing')}
        />

        <Route path={routes.projectDesign} element={<ProjectDesign />} />
        <Route path={routes.projectMusic} element={<ProjectMusic />} />
        <Route path={routes.projectVideoSubtitles} element={<ProjectVideo />} />
        <Route path={routes.projectVideo} element={<ProjectVideo />} />

        <>
          <Route
            path={routes.projectTemplates}
            lazy={() => import(/* webpackChunkName: "projectTemplates" */ './pages/ProjectTemplates')}
          />

          <Route
            path={routes.projectTemplateCreate}
            lazy={() => import(/* webpackChunkName: "projectTemplates" */ './pages/ProjectTemplateCreate')}
          />
        </>

        <Route
          path={routes.projectSubtitles}
          lazy={() => import(/* webpackChunkName: "subtitles" */ './pages/ProjectSubtitles')}
        />
        <Route
          path={routes.projectCreateTranscription}
          lazy={() => import(/* webpackChunkName: "subtitles" */ './pages/ProjectCreateTranscription')}
        />
        <Route
          path={routes.projectCreateTranslation}
          lazy={() => import(/* webpackChunkName: "subtitles" */ './pages/ProjectCreateTranslation')}
        />
        <Route
          path={routes.projectEditSubtitle}
          lazy={() => import(/* webpackChunkName: "subtitles" */ './pages/ProjectEditSubtitle')}
        />
        <Route
          path={routes.projectRenderDescription}
          lazy={() => import(/* webpackChunkName: "subtitles" */ './pages/ProjectRenderDescription')}
        />
        <Route
          path={routes.projectSceneSubtitle}
          lazy={() => import(/* webpackChunkName: "subtitles" */ './pages/ProjectSceneSubtitle')}
        />
        <Route
          path={routes.projectSceneSubtitleEdit}
          lazy={() => import(/* webpackChunkName: "subtitles" */ './pages/ProjectSceneSubtitleEdit')}
        />
        <Route
          path={routes.projectSceneSubtitleCreate}
          lazy={() => import(/* webpackChunkName: "subtitles" */ './pages/ProjectSceneSubtitleCreate')}
        />

        <Route
          path={routes.settingsPersonal}
          lazy={() => import(/* webpackChunkName: "personal-settings" */ './pages/SettingsPersonal')}
        />
        <Route
          path={routes.settingsNotifications}
          lazy={() => import(/* webpackChunkName: "personal-settings" */ './pages/NotificationSettings')}
        />

        <Route
          path={routes.settingsAccount}
          lazy={() => import(/* webpackChunkName: "account-settings" */ './pages/SettingsAccount')}
        />
        <Route
          path={routes.settingsAccountMembers}
          lazy={() => import(/* webpackChunkName: "account-settings" */ './pages/SettingsAccountMembers')}
        />
        <Route
          path={routes.settingsAccountMemberCreate}
          lazy={() => import(/* webpackChunkName: "account-settings" */ './pages/SettingsAccountMembers/MemberCreate')}
        />
        <Route
          path={routes.settingsAccountMemberUpdate}
          lazy={() => import(/* webpackChunkName: "account-settings" */ './pages/SettingsAccountMembers/MemberUpdate')}
        />
        <Route
          path={routes.settingsAccountTeams}
          lazy={() => import(/* webpackChunkName: "account-settings" */ './pages/SettingsAccountTeams')}
        />
        <Route
          path={routes.settingsAccountTeamMembers}
          lazy={() => import(/* webpackChunkName: "account-settings" */ './pages/SettingsAccountTeamMembers')}
        />

        <Route
          path={routes.settingsAccountConsents}
          lazy={() => import(/* webpackChunkName: "account-settings" */ './pages/AccountConsents')}
        />
        <Route
          path={routes.settingsAccountProjectConsents}
          lazy={() => import(/* webpackChunkName: "account-settings" */ './pages/ProjectConsents')}
        />

        <Route
          path={routes.brandingThemes}
          lazy={() => import(/* webpackChunkName: "branding" */ './pages/BrandingThemes')}
        />
        <Route
          path={routes.brandingTheme}
          lazy={() => import(/* webpackChunkName: "branding" */ './pages/BrandingThemes/ThemeUpdate')}
        />
        <Route
          path={routes.brandingMusics}
          lazy={() => import(/* webpackChunkName: "branding" */ './pages/BrandingMusics')}
        />
        <Route
          path={routes.brandingMusicCreate}
          lazy={() => import(/* webpackChunkName: "branding" */ './pages/BrandingMusics/MusicCreate')}
        />
        <Route
          path={routes.brandingMusicUpdate}
          lazy={() => import(/* webpackChunkName: "branding" */ './pages/BrandingMusics/MusicUpdate')}
        />

        <Route path="*" element={<NoAccessLayout />} />
      </Route>

      <Route path="*" element={<NoAccessContent />} />
    </Route>
  )
);
