import { useUrlParameter } from '@cofenster/web-components';
import { useEffect, useMemo } from 'react';
import type { ProjectTemplatesFilter } from '../../api/generated';
export type { ProjectTemplatesFilter as ProjectTemplatesSearchFilters } from '../../api/generated';

export const useProjectTemplatesSearchFilters = (itemsPerPage: number) => {
  const [page, setPage] = useUrlParameter(1, 'page', String, Number);
  const [videoFormat, setVideoFormat] = useUrlParameter<ProjectTemplatesFilter['videoFormat']>(
    undefined,
    'videoFormat'
  );
  const [search, setSearch] = useUrlParameter<ProjectTemplatesFilter['search']>('', 'search');
  const [createdBy, setCreatedBy] = useUrlParameter<ProjectTemplatesFilter['createdBy']>('ANYONE', 'createdBy');
  const [tags, setTags] = useUrlParameter<ProjectTemplatesFilter['tags']>(
    [],
    'tags',
    (urlTags) => encodeURIComponent((urlTags || []).join(',')),
    (urlTags) => decodeURIComponent(urlTags).split(',')
  );

  const filter = useMemo(
    () => ({
      createdBy,
      videoFormat,
      tags,
      search,
      limit: itemsPerPage,
      offset: Math.max(page - 1, 0) * itemsPerPage,
    }),
    [createdBy, videoFormat, tags, search, page, itemsPerPage]
  );

  // Whenever the search parameters have been changed, reinitialize the page to
  // the first one.
  // biome-ignore lint/correctness/useExhaustiveDependencies: safe
  useEffect(() => {
    if (search || videoFormat || tags || createdBy) setPage(1);
  }, [search, createdBy, videoFormat, tags]);

  return useMemo(
    () => ({ filter, setSearch, page, setPage, createdBy, setCreatedBy, setVideoFormat, setTags }),
    [filter, setSearch, page, setPage, createdBy, setCreatedBy, setVideoFormat, setTags]
  );
};
