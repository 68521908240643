import { type CSSProperties, type FC, Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { EmptyState, LoadingSpinner, styled, useKeypress } from '@cofenster/web-components';

import { AssetPreloadingProvider } from '../../../../../render-template-engine/src/context/assetPreloading/AssetPreloadingProvider';
import { ProjectLayout } from '../../../components/page/Layout/ProjectLayout';
import { RequestPermissionButton } from '../../../components/requestPermissions';
import { EditorPlayerProvider } from '../../../contexts/editorPlayer/EditorPlayerProvider';
import { RemotionPlayerRefProvider } from '../../../contexts/editorPlayer/RemotionPlayerRefProvider';
import { useIntroOutro } from '../../../contexts/editorPlayer/useIntroOutro';
import { useSceneTimings } from '../../../contexts/editorPlayer/usePlayerScenes';
import { useProject } from '../../../contexts/project/useProject';
import { useProjectRenderDescription } from '../../../contexts/projectRenderDescription/useProjectRenderDescription';
import { useScenes } from '../../../contexts/scenes/useScenes';
import { TeamPermissionRestriction, useTeamPermissionStatus } from '../../../contexts/user/TeamPermissionRestriction';
import { useUser } from '../../../contexts/user/useUser';
import { useProjectEditingHeader } from '../../../hooks/useProjectHeader';
import { NoAccessContent } from '../../../pages/NoAccess';
import type { ProjectEditorRouteParams } from '../../../routes';

import { usePasteSceneFromClipboard } from '../hooks/usePasteSceneFromClipboard';
import { EditorPlayer } from './EditorPlayer';
import { EditorSceneAssetThumbs } from './EditorSceneAssetThumbs';
import { useSceneInsertionIndex } from './EditorSceneAssetThumbs/hooks/useSceneInsertionIndex';
import { EditorTools } from './EditorTools';
import { EditorUpload } from './EditorUpload';
import { SceneEditorNotesOverlay } from './SceneEditorNotes/SceneEditorNotesOverlay';
import { ScenesWizard } from './ScenesWizard';

// 1. Make the sidebar spread the full height of the window, no more, no less,
//    at all time.
// 2. Add enough spacing at the top of the sidebar to let the header overlap it
//    with covering any content.
// 3. Make the sidebar scrollable and enable momentum-based scrolling on
//    supporting touch devices.
//    See: https://developer.mozilla.org/en-US/docs/Web/CSS/-webkit-overflow-scrolling
const Sidebar = styled('div')(({ theme }) => ({
  position: 'fixed', // 1
  top: 0, // 1
  bottom: 0, // 1
  left: 0,
  paddingTop: 'calc(var(--project-header-height) + var(--page-notification-height, 0px))', // 2
  overflowY: 'auto', // 3
  WebkitOverflowScrolling: 'touch', // 3
  backgroundColor: theme.palette.brand.white,
  borderRight: `1px solid ${theme.palette.brand.grey100}`,

  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

// 1. Leave enough room for the sidebar to side on the left of the screen, so
//    that the `Main` section appear centered in the remaining available width.
//    Ref: 208px for a thumbnail + 2 times 15px padding + 1px border.
const Main = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.brand.linen,
  flex: 1,
  paddingLeft: 'calc(208px + 15px * 2 + 1px)', // 1

  [theme.breakpoints.down('sm')]: {
    paddingLeft: 0,
  },
}));

const Content = styled('div')(({ theme }) => ({
  flex: '1 1 100%',
  padding: theme.spacing('var(--project-editor-preview-spacing)', 2),
  position: 'relative',

  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2, 0, 4),
    flex: '0 0 auto',
  },
}));

const ScenesWizardContainer = styled('div')(({ theme }) => ({
  maxWidth: 960,
  width: '100%',
  paddingTop: theme.spacing(6),
  marginLeft: 'auto',
  marginRight: 'auto',
}));

const Tools = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: 'var(--project-editor-footer-height)',

  position: 'sticky',
  bottom: 0,
  zIndex: theme.zIndex.above,
  padding: theme.spacing(1.5, 2),
  backgroundColor: theme.palette.brand.white,

  [theme.breakpoints.down('sm')]: {
    borderRadius: theme.shape['borderRadius-l'],
    flexWrap: 'wrap',
    position: 'static',
  },
}));

const LoadingSpinnerContainer = styled('div')(() => ({
  margin: 'auto',
}));

export const ProjectEditorContent: FC = () => {
  const { sceneId, projectId } = useParams() as ProjectEditorRouteParams;

  const { project, loading } = useProject();
  const { scenes, ...scenesContext } = useScenes();
  const { isUploaded } = useIntroOutro();
  const canUpdateProject = useTeamPermissionStatus({ has: 'ProjectUpdate' }) === 'ALLOWED';
  const withSceneAddition = !project?.archivedAt && canUpdateProject;

  // We only use the scene timings to know whether the current scene has an
  // asset, otherwise we render the upload interface. Because there is no need
  // for timings here, we can include the excluded scenes so we can handle the
  // standalone preview for excluded scenes.
  const sceneTimings = useSceneTimings({ withExcludedScenes: true });

  // Find the current scene here instead of reading it from the `useScenes`
  // context as it:
  // a) computes it in a ‘useEffect’, which can make for brief unsynchronized
  //    state where the ‘sceneId’ has updated, but the ‘currentScene’ hasn’t
  // b) falls back to the first scene, which causes the ‘currentScene’ to be
  //    incorrect during intro/outro, and for a brief flash after/before them
  const resolvedCurrentScene = useMemo(() => scenes.find((scene) => scene.id === sceneId), [scenes, sceneId]);
  const currentScene = __NO_CURRENT_SCENE_DEFAULT__ ? scenesContext.currentScene : resolvedCurrentScene;
  const isInitialMount = useRef(true);

  const { refetch } = useProjectRenderDescription();

  // Refetch the project render description every time the scene timings change,
  // but not the first time around as both the scenes and the render description
  // were freshly fetched from the backend at the same time — making a refetch
  // unnecessary and needlessly costly
  useEffect(() => {
    if (isInitialMount.current) isInitialMount.current = false;
    else if (sceneTimings) refetch();
  }, [sceneTimings, refetch]);

  const [bulkUploadInProgress, setBulkUploadInProgress] = useState(false);
  const { user } = useUser();
  const isIntroOrOutro = sceneId === 'intro' || sceneId === 'outro';
  const isIntroOrOutroAndIsUploaded = isIntroOrOutro && isUploaded(sceneId);

  const currentSceneTiming = useMemo(
    () => sceneTimings.find(({ id }) => id === currentScene?.id),
    [sceneTimings, currentScene?.id]
  );

  const hasAsset = useMemo(() => {
    if (isIntroOrOutro) {
      return __INTEGRATED_INTRO_OUTRO_EDITOR__ ? isIntroOrOutroAndIsUploaded : true;
    }
    return !!currentSceneTiming?.hasAsset;
  }, [currentSceneTiming, isIntroOrOutro, isIntroOrOutroAndIsUploaded]);

  const isAssetReady = useMemo(() => {
    if (isIntroOrOutro) {
      return __INTEGRATED_INTRO_OUTRO_EDITOR__ ? isIntroOrOutroAndIsUploaded : true;
    }

    return !!currentSceneTiming?.isAssetReady;
  }, [currentSceneTiming, isIntroOrOutro, isIntroOrOutroAndIsUploaded]);

  const canDisplayAsset = useMemo(() => {
    if (isIntroOrOutro) {
      return __INTEGRATED_INTRO_OUTRO_EDITOR__ ? isIntroOrOutroAndIsUploaded : true;
    }

    return !!currentSceneTiming?.canDisplayAsset;
  }, [currentSceneTiming, isIntroOrOutro, isIntroOrOutroAndIsUploaded]);

  const endUpload = useCallback(() => {
    setBulkUploadInProgress(false);
  }, []);

  const startUpload = useCallback(() => {
    setBulkUploadInProgress(true);
  }, []);

  const header = useProjectEditingHeader(projectId);

  const index = useSceneInsertionIndex(currentScene?.id);
  const { pasteScene } = usePasteSceneFromClipboard(project?.id);

  useKeypress(
    useCallback(() => pasteScene(index), [pasteScene, index]),
    '$mod+v',
    { disabled: !withSceneAddition }
  );

  if (project?.archivedAt && !scenes.length) {
    return (
      <ProjectLayout documentTitle={project?.name ?? ''} header={header} isProjectTemplate={project.isProjectTemplate}>
        <EmptyState
          iconType="ListBulletIcon"
          title="i18n.projectEdit.empty.archived.title"
          description="i18n.projectEdit.empty.archived.description"
          data-testid="empty-archive"
        />
      </ProjectLayout>
    );
  }

  return (
    <AssetPreloadingProvider>
      <ProjectLayout
        documentTitle={project?.name ?? ''}
        header={header}
        ContentContainerComponent={Fragment}
        isProjectTemplate={project?.isProjectTemplate}
      >
        {project?.template ? (
          <>
            {scenes.length ? (
              <RemotionPlayerRefProvider>
                <Sidebar>
                  <EditorSceneAssetThumbs />
                </Sidebar>

                <Main
                  style={
                    {
                      '--project-editor-player-actions-height': '64px',
                      '--project-editor-player-actions-spacing': '32px',
                      '--project-editor-preview-spacing': '32px',
                      '--project-editor-footer-height': '72px',
                    } as CSSProperties
                  }
                >
                  {scenes.length > 0 ? (
                    <EditorPlayerProvider>
                      <Content>
                        {hasAsset && canDisplayAsset ? (
                          <EditorPlayer
                            project={project}
                            showMusicHint={!!project?.music?.id}
                            isAssetProcessing={!isAssetReady}
                          />
                        ) : (
                          <EditorUpload project={project} themeVideoType={isIntroOrOutro ? sceneId : undefined} />
                        )}
                        <SceneEditorNotesOverlay />
                      </Content>

                      <Tools>
                        <EditorTools project={project} sceneId={sceneId} />
                      </Tools>
                    </EditorPlayerProvider>
                  ) : (
                    <Content>
                      <ScenesWizardContainer>
                        <TeamPermissionRestriction
                          has="ProjectUpdate"
                          fallback={
                            <EmptyState
                              iconType="MovieIcon"
                              title="i18n.projectEdit.noUpdateProjectPermission.headline"
                              description="i18n.projectEdit.noUpdateProjectPermission.subline"
                              cta={user && <RequestPermissionButton user={user} permission="ProjectUpdate" />}
                            />
                          }
                        >
                          <ScenesWizard
                            projectId={project.id}
                            isProjectTemplate={project.isProjectTemplate}
                            videoFormat={project.videoFormat}
                            onUploadStart={startUpload}
                            onUploadEnd={endUpload}
                            bulkUploadInProgress={bulkUploadInProgress}
                            hasContributionRequestListAvailable={project.hasContributionRequestListAvailable}
                          />
                        </TeamPermissionRestriction>
                      </ScenesWizardContainer>
                    </Content>
                  )}
                </Main>
              </RemotionPlayerRefProvider>
            ) : (
              <ScenesWizardContainer>
                <TeamPermissionRestriction
                  has="ProjectUpdate"
                  fallback={
                    <EmptyState
                      iconType="MovieIcon"
                      title="i18n.projectEdit.noUpdateProjectPermission.headline"
                      description="i18n.projectEdit.noUpdateProjectPermission.subline"
                      cta={user && <RequestPermissionButton user={user} permission="ProjectUpdate" />}
                    />
                  }
                >
                  <ScenesWizard
                    projectId={project.id}
                    videoFormat={project.videoFormat}
                    isProjectTemplate={project.isProjectTemplate}
                    onUploadStart={startUpload}
                    onUploadEnd={endUpload}
                    bulkUploadInProgress={bulkUploadInProgress}
                    hasContributionRequestListAvailable={project.hasContributionRequestListAvailable}
                  />
                </TeamPermissionRestriction>
              </ScenesWizardContainer>
            )}
          </>
        ) : loading ? (
          <LoadingSpinnerContainer>
            <LoadingSpinner />
          </LoadingSpinnerContainer>
        ) : (
          <NoAccessContent resource="Project" />
        )}
      </ProjectLayout>
    </AssetPreloadingProvider>
  );
};
