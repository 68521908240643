import { Button, Icon, Typography, styled, useGoto } from '@cofenster/web-components';
import { type CSSProperties, type FC, useCallback } from 'react';
import type { ProjectTemplate } from '../../api/hooks/projectTemplate/useProjectTemplates';
import { Masthead } from '../../components/team/Projects/Masthead';
import { ProjectFormatInformation } from '../../components/team/Projects/ProjectFormatInformation';
import { useDialogs } from '../../contexts/dialogs';
import { useUser } from '../../contexts/user/useUser';
import { CofensterProjectTemplateIndicator } from './CofensterProjectTemplateIndicator';
import { ProjectTemplateMenu } from './ProjectTemplateMenu';

// 1. Clear blur effect from the `Masthead` element
const StyledMasthead = styled(Masthead)(() => ({
  '::before': { filter: 'none' }, // 1
}));

const TitleAndMoreActionsContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: theme.spacing(1),
}));

const ActionWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(1),
  padding: theme.spacing(2),

  position: 'absolute',
  inset: 0,
  zIndex: theme.zIndex.above,
}));

export const ProjectTemplateTile: FC<{
  projectTemplate: ProjectTemplate;
  folderId?: string;
}> = ({ projectTemplate, folderId }) => {
  const thumbnailUrl = projectTemplate.thumbnailUrl;
  const goto = useGoto();
  const { openDialog } = useDialogs();
  const { user } = useUser();

  const onPreviewProjectTemplate = useCallback(() => {
    openDialog('ProjectTemplatePreviewDialog', {
      projectTemplate,
      goto,
      personalFolderId: user?.rootProjectFolder?.id ?? '',
      enforcedProjectId: folderId,
    });
  }, [openDialog, projectTemplate, goto, user, folderId]);

  return (
    <>
      <StyledMasthead style={{ '--image': thumbnailUrl ? `url(${thumbnailUrl})` : undefined } as CSSProperties}>
        <ActionWrapper data-interactive>
          <Button variant="blurred" onClick={onPreviewProjectTemplate} startIcon={<Icon size="s" type="EyeIcon" />}>
            i18n.projectTemplates.projectTile.openPreview
          </Button>
        </ActionWrapper>
        <ProjectFormatInformation
          completedSceneCount={projectTemplate.project.completedSceneCount}
          videoFormat={projectTemplate.project.videoFormat}
          sizing="m"
        />
        {projectTemplate.byCofenster && <CofensterProjectTemplateIndicator />}
      </StyledMasthead>
      <TitleAndMoreActionsContainer>
        <Typography variant="h6" component="span">
          {projectTemplate.project.name}
        </Typography>
        {!projectTemplate.byCofenster && (
          <ProjectTemplateMenu
            projectTemplate={projectTemplate}
            goto={goto}
            onPreviewProjectTemplate={onPreviewProjectTemplate}
          />
        )}
      </TitleAndMoreActionsContainer>
    </>
  );
};
