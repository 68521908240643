import type { VideoFormat } from '@cofenster/constants';
import { Icon, Translatable, Typography, preventForwardProps, styled } from '@cofenster/web-components';
import type { FC } from 'react';
import { useVideoFormatTextAndIcon } from './useVideoFormatTextAndIcon';

const MetadataLabel = styled(
  Typography,
  preventForwardProps(['sizing'])
)<{ sizing: 's' | 'm' }>(({ theme, sizing }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  gap: theme.spacing(1),

  position: 'absolute',
  bottom: theme.spacing(sizing === 'm' ? 2 : 1),
  left: theme.spacing(sizing === 'm' ? 2 : 1),
  zIndex: theme.zIndex.above,

  backgroundColor: theme.palette.brand.white,
  padding: theme.spacing(0.5, 1),
  borderRadius: theme.shape.borderRadius,
}));

export const ProjectFormatInformation: FC<{
  completedSceneCount: number;
  videoFormat: VideoFormat;
  sizing?: 's' | 'm';
}> = ({ completedSceneCount, videoFormat, sizing = 'm' }) => {
  const { text: videoFormatText } = useVideoFormatTextAndIcon(videoFormat);

  return (
    <MetadataLabel sizing={sizing} variant="s" component="p" data-testid="project-video-format">
      <Translatable>{videoFormatText}</Translatable>
      <Icon size="s" type="MovieIcon" />
      {completedSceneCount}
    </MetadataLabel>
  );
};
