import type { FC } from 'react';

import { GridContainer, GridItem } from '@cofenster/web-components';

import { IntroOutroUpload } from '../../../../components/branding/IntroOutroUpload';
import type { Project } from '../../../../contexts/project/useProject';

export const OutroSection: FC<{
  project: Project;
}> = ({ project }) => {
  const { theme, videoFormat } = project;

  if (!theme) {
    return null;
  }

  return (
    <GridContainer alignItems="center" spacing={2}>
      <GridItem xs={12}>
        <IntroOutroUpload
          videoType="Outro"
          videoFormat={videoFormat}
          theme={theme}
          size="small"
          data-testid="outro-upload-dropzone"
        />
      </GridItem>
    </GridContainer>
  );
};
