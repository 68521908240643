import * as Sentry from '@sentry/react';

import { type SentryOptions, getClientConfig } from './config/getClientConfig';

export const initSentry = (options: SentryOptions) => {
  if (!Sentry.isInitialized()) {
    Sentry.init(getClientConfig(options));
  }

  return Sentry;
};
