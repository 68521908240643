import { useMemo } from 'react';

import { type ProjectTemplateRenderDetailsQueryVariables, useProjectTemplateRenderDetailsQuery } from '../../generated';

export const useProjectTemplateRenderDetails = (id: ProjectTemplateRenderDetailsQueryVariables['id']) => {
  const { loading, error, data } = useProjectTemplateRenderDetailsQuery({
    variables: { id },
  });
  const projectTemplateRenderDetails = data?.projectTemplate ?? undefined;
  return useMemo(
    () => ({ projectTemplateRenderDetails, loading, error }),
    [projectTemplateRenderDetails, loading, error]
  );
};
