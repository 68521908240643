import { useSnackbars } from '@cofenster/web-components';
import { useCallback } from 'react';
import { useDeleteProjectTemplate } from '../../../api/hooks/projectTemplate/useDeleteProjectTemplate';
import { useConfirmDialog } from '../../../hooks/useConfirmDialog';

export const useOnDeleteProjectTemplateWithConfirmation = (projectTemplateId: string) => {
  const [deleteProjectTemplate] = useDeleteProjectTemplate(projectTemplateId);
  const { openSnackbar } = useSnackbars();
  const confirmDelete = useConfirmDialog({
    title: 'i18n.projectTemplates.deleteDialog.title',
    content: 'i18n.projectTemplates.deleteDialog.content',
    confirm: 'i18n.global.delete',
  });

  return useCallback(async () => {
    if (await confirmDelete()) {
      await deleteProjectTemplate();
      openSnackbar({
        children: 'i18n.projectTemplates.deleteProjectTemplate.snackbar.success',
        variant: 'success',
      });
    }
  }, [confirmDelete, deleteProjectTemplate, openSnackbar]);
};
