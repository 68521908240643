import type { ComponentProps, FC, PropsWithChildren } from 'react';

import { ErrorPage } from '@cofenster/web-components';

import { Sentry } from '../sentry';

export type ErrorBoundaryProps = ComponentProps<(typeof Sentry)['ErrorBoundary']>;

export const ErrorBoundary: FC<PropsWithChildren<ErrorBoundaryProps>> = ({
  children,
  fallback = <ErrorPage />,
  ...rest
}) => {
  return (
    <Sentry.ErrorBoundary {...rest} fallback={fallback}>
      {children}
    </Sentry.ErrorBoundary>
  );
};
