import { useMemo } from 'react';

import {
  type CreateProjectTemplateFromProjectMutationVariables,
  useCreateProjectTemplateFromProjectMutation,
} from '../../generated';

export const useCreateTemplateFromProject = () => {
  const [createProjectTemplateFromProject, { error, loading }] = useCreateProjectTemplateFromProjectMutation({
    update: (cache) => {
      cache.evict({ fieldName: 'ProjectTemplates' });
    },
  });

  return useMemo(
    () => ({
      createProjectTemplateFromProject: async (
        projectId: CreateProjectTemplateFromProjectMutationVariables['projectId'],
        input: CreateProjectTemplateFromProjectMutationVariables['input']
      ) => {
        const result = await createProjectTemplateFromProject({ variables: { projectId, input } });
        return result.data?.createProjectTemplateFromProject;
      },
      error,
      loading,
    }),
    [createProjectTemplateFromProject, error, loading]
  );
};
