import { type FC, Fragment, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { Container, GridItem, LoadingSpinner, styled } from '@cofenster/web-components';

import { useProjectFolder } from '../../api/hooks/projectFolder/useProjectFolder';
import { RouterIconButton } from '../../components/button/RouterIconButton';
import { FullPageWithTitleAndActions } from '../../components/page/FullPageWithTitleAndActions';
import { CurrentTeamProvider } from '../../contexts/currentTeam/CurrentTeamProvider';
import type { ProjectCreateRouteParams, RouteName } from '../../routes';

import { ProjectTemplatesSearchFilterProvider } from '../../contexts/projectTemplateSearchFilter/ProjectTemplatesSearchFilterProvider';
import { NewProjectForm } from './NewProjectForm';

const StyledContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  margin: 'auto',
  paddingBottom: theme.spacing(3),
}));

const useQueryParams = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};

const useSource = (folderId: string) => {
  const queryParams = useQueryParams();
  const from = queryParams.get('from');

  if (from === 'home') {
    return { to: 'home' as RouteName };
  }

  return { to: 'projectFolder' as RouteName, params: { folderId } };
};

export const ProjectCreate: FC = () => {
  const { folderId } = useParams() as ProjectCreateRouteParams;
  const { projectFolder } = useProjectFolder(folderId);
  const source = useSource(folderId);

  return (
    <FullPageWithTitleAndActions
      title="i18n.projectCreate.header"
      rightActions={[
        <Fragment key="cancel">
          <RouterIconButton
            {...source}
            icon="CloseIcon"
            data-testid="create-project-cancel"
            label="i18n.global.cancel"
          />
        </Fragment>,
      ]}
    >
      <GridItem alignSelf="stretch" flex={1} display="flex" flexDirection="column">
        <StyledContainer>
          <CurrentTeamProvider team={projectFolder?.team ?? undefined}>
            <ProjectTemplatesSearchFilterProvider resultsPerPage={9}>
              {projectFolder ? <NewProjectForm projectFolder={projectFolder} /> : <LoadingSpinner />}
            </ProjectTemplatesSearchFilterProvider>
          </CurrentTeamProvider>
        </StyledContainer>
      </GridItem>
    </FullPageWithTitleAndActions>
  );
};
