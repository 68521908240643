import { useKeypress } from '@cofenster/web-components';
import type { Project } from '../../../../../api/hooks/project/useProject';
import { useAddNewSceneCard } from '../../../../../components/common/AddScene/AddSceneCard';
import { useScenes } from '../../../../../contexts/scenes/useScenes';
import { useTeamPermissionStatus } from '../../../../../contexts/user/TeamPermissionRestriction';
import { useDelete, useDuplicate } from '../components/ScenePopoverActions';
import { useSceneInsertionIndex } from './useSceneInsertionIndex';

export const useScenePopoverActionShortcuts = (project: Project, sceneId: string) => {
  const { scenes } = useScenes();
  const sceneExists = scenes.find((scene) => scene.id === sceneId);

  const isProjectArchived = Boolean(project?.archivedAt);
  const isAllowedToUpdateProject = useTeamPermissionStatus({ has: 'ProjectUpdate' }) === 'ALLOWED';
  const canUpdateProject = !isProjectArchived && isAllowedToUpdateProject;

  const index = useSceneInsertionIndex(sceneId);
  const openDialog = useAddNewSceneCard({
    index,
    projectId: project.id,
    isProjectTemplate: project.isProjectTemplate,
    videoFormat: project.videoFormat,
    hasContributionRequestListAvailable: project.hasContributionRequestListAvailable,
  });

  useKeypress(() => openDialog(), 'N', { disabled: !canUpdateProject });

  const onDuplicateScene = useDuplicate(sceneId);
  useKeypress(onDuplicateScene, '$mod+d', { disabled: !sceneExists || !canUpdateProject });

  const onDeleteScene = useDelete(sceneId);
  useKeypress(onDeleteScene, 'Backspace', { disabled: !sceneExists || !canUpdateProject });
};
