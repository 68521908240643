import type { FC, PropsWithChildren } from 'react';

import type { VideoFormat } from '@cofenster/constants';
import {
  BlankButton,
  Icon,
  type IconType,
  LoadingSpinner,
  Text,
  Typography,
  UpsellIcon,
  styled,
  useFeatureFlags,
} from '@cofenster/web-components';

import type { VideoFormatOptionConfiguration } from './options';

const UseCases = styled('ul')(({ theme }) => ({
  margin: 0,
  marginTop: theme.spacing(1),
  paddingLeft: 0,
  listStyleType: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexWrap: 'wrap',
  gap: theme.spacing(1),
}));

const UseCaseItem = styled('li')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(0.5),
}));

const UseCase: FC<PropsWithChildren<{ iconType: IconType }>> = ({ iconType, children }) => {
  return (
    <UseCaseItem>
      <Icon type={iconType} size="s" color="grey600" />
      <Text color="grey700" variant="s">
        {children}
      </Text>
    </UseCaseItem>
  );
};

const StyledUpsellIcon = styled(UpsellIcon)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(2),
  right: theme.spacing(2),
}));

const translations = {
  SocialMedia: 'i18n.projectCreate.videoFormat.SocialMedia.title',
  Horizontal: 'i18n.projectCreate.videoFormat.Horizontal.title',
  Square: 'i18n.projectCreate.videoFormat.Square.title',
  Vertical: 'i18n.projectCreate.videoFormat.Vertical.title',
};

const StyledBlankButton = styled(BlankButton)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: theme.palette.brand.white,
  borderRadius: theme.shape['borderRadius-l'],
  padding: theme.spacing(2),
  border: `1px solid ${theme.palette.brand.grey500}`,
  height: '100%',
  width: '100%',
  '&:focus': theme.mixins.focusRing,

  '&:disabled': {
    cursor: 'not-allowed' as const,
  },
}));

export const VideoFormatButton: FC<{
  format: VideoFormat;
  configuration: VideoFormatOptionConfiguration;
  onClick: (format: VideoFormat) => void;
  loading: boolean;
  disabled: boolean;
}> = ({ format, configuration, onClick, loading, disabled }) => {
  const { hasFeature } = useFeatureFlags();

  return (
    <StyledBlankButton
      onClick={() => onClick(format)}
      disabled={disabled}
      data-testid={`project-preset-${format}-button`}
    >
      {configuration.flag && !hasFeature(configuration.flag) && <StyledUpsellIcon />}
      {loading ? <LoadingSpinner /> : <img src={`/assets/images/projectCreate/${format}.svg`} alt="" />}
      <Typography variant="l">{translations[format]}</Typography>
      <UseCases>
        {configuration.useCases.map((useCase) => (
          <UseCase key={useCase.text} iconType={useCase.iconType as IconType}>
            {useCase.text}
          </UseCase>
        ))}
      </UseCases>
    </StyledBlankButton>
  );
};
