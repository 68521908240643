import type { FC } from 'react';

import type { RenderDescription } from '@cofenster/render-worker';
import { RemotionPlayer } from '@cofenster/web-remotion-player';
import { useRemotionPlayerRef } from '../../../../contexts/editorPlayer/useRemotionPlayerRef';
import type { Project } from '../../../../contexts/project/useProject';
import { ResponsiveContainer } from '../../components/ResponsiveContainer';
import { EditorPlayerActions } from '../EditorPlayerActions';
import { EditorExcludedSceneHint } from './Hint';

export const EditorExcludedScenePlayer: FC<{
  project: Project;
  sceneId?: string;
  renderDescription: RenderDescription;
  enable: VoidFunction;
}> = ({ project, sceneId, renderDescription, enable }) => {
  const { setPlayerRef } = useRemotionPlayerRef();

  return (
    <>
      <ResponsiveContainer project={project} data-testid={`${sceneId}-preview`}>
        <RemotionPlayer
          ref={setPlayerRef}
          bundleUrl={project.template.previewBundleUrl}
          templateIdentifier={project.template.templateIdentifier}
          renderDescription={renderDescription}
        />
        <EditorExcludedSceneHint enable={enable} />
      </ResponsiveContainer>

      <EditorPlayerActions duration={renderDescription.totalDurationInSeconds} />
    </>
  );
};
