import { type FC, useCallback } from 'react';

import { Button, type ButtonProps, Icon, styled } from '@cofenster/web-components';

import type { Permission } from '../../api/generated';
import { useRequestRoleWithPermissions } from '../../api/hooks/roleRequest/useRequestRoleWithPermissions';
import { useUserRoleRequest } from '../../api/hooks/roleRequest/useUserRoleRequest';
import { useCurrentTeamSafe } from '../../contexts/currentTeam/useCurrentTeam';
import { useDialogs } from '../../contexts/dialogs/useDialogs';
import type { User } from '../../contexts/user/useUser';

type RequestPermissionButtonProps = {
  user: User;
  permission: Permission;
} & Omit<ButtonProps, 'onClick' | 'loading' | 'startIcon' | 'variant'>;

export const RequestPermissionButton: FC<RequestPermissionButtonProps> = ({ user, permission, children, ...props }) => {
  const { openDialog } = useDialogs();
  const { team } = useCurrentTeamSafe();
  const { userRoleRequest, loading } = useUserRoleRequest(user.id, team && !team.public ? team.id : null);
  const requestRoleWithPermissions = useRequestRoleWithPermissions();

  const onClick = useCallback(() => {
    openDialog('RequestPermissionDialog', {
      user,
      team,
      requestRole: () => requestRoleWithPermissions([permission], team && !team.public ? team.id : null),
    });
  }, [openDialog, user, team, requestRoleWithPermissions, permission]);

  if (userRoleRequest) {
    return (
      <HighlightButton {...props} disabled startIcon={<Icon type="HourglassIcon" size="s" />}>
        i18n.roleRequest.pending.text
      </HighlightButton>
    );
  }

  return (
    <StyledButton
      {...props}
      loading={loading}
      onClick={onClick}
      startIcon={<Icon type="LockIcon" />}
      variant="secondary"
    >
      {children ?? 'i18n.roleRequest.button.request'}
    </StyledButton>
  );
};

// 1. Prevent the button from wrapping across several lines in case of a very
//    long project name
const StyledButton = styled(Button)(() => ({
  width: 'max-content', // 1
}));

const HighlightButton = styled(StyledButton)(({ theme }) => ({
  '@keyframes highlight': {
    'from, to': { borderColor: theme.palette.brand.transparent },
    '25%': { borderColor: theme.palette.brand.positive },
  },
  animation: 'highlight 1s ease-in-out',
}));
