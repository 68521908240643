import { type FC, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { ProcessingIndicator, styled } from '@cofenster/web-components';

import { useIntroOutroRenderDescription } from '../../../../api/hooks/renderDescription/useIntroOutroRenderDescription';
import { useSceneRenderDescription } from '../../../../api/hooks/renderDescription/useSceneRenderDescription';
import type { ThemeVideoType } from '../../../../api/hooks/user/useMe';
import { useIntroOutro } from '../../../../contexts/editorPlayer/useIntroOutro';
import type { Project } from '../../../../contexts/project/useProject';
import { useScenes } from '../../../../contexts/scenes/useScenes';
import type { ProjectEditorRouteParams } from '../../../../routes';
import { ResponsiveContainer } from '../../components/ResponsiveContainer';
import { useMoveSceneKeyboardHandler } from '../../hooks/useMoveSceneKeyboardHandler';
import { EditorExcludedScenePlayer } from '../EditorExcludedScene/Player';
import { EditorMusicHint } from '../EditorMusicHint';
import { EditorPlayerActions } from '../EditorPlayerActions';
import { EditorPlayerControls } from '../EditorPlayerControls';
import { EditorPreview } from '../EditorPreview';
import { useIncludeScene } from '../EditorSceneAssetThumbs/hooks/useIncludeScene';

const StyledProcessingIndicator = styled(ProcessingIndicator)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(3),
  right: theme.spacing(3),
}));

type EditorPlayerProps = {
  project: Project;
  showMusicHint?: boolean;
  isAssetProcessing?: boolean;
};

const capitalize = (value: string) => value.slice(0, 1).toUpperCase() + value.slice(1);

export const EditorPlayer: FC<EditorPlayerProps> = ({ project, showMusicHint, isAssetProcessing }) => {
  const { sceneId } = useParams() as ProjectEditorRouteParams;
  const { scenes, ...scenesContext } = useScenes();
  const { enable: enableIntroOutro, isEnabled } = useIntroOutro();
  const includeScene = useIncludeScene();

  // We retrieve the current scene manually instead of picking `currentScene`
  // from the scene context as it would fall back to the first scene when the ID
  // from the URL is “intro” or “outro”, which is not what we want here.
  const resolvedCurrentScene = useMemo(() => scenes.find((scene) => scene.id === sceneId), [scenes, sceneId]);
  const currentScene = __NO_CURRENT_SCENE_DEFAULT__ ? scenesContext.currentScene : resolvedCurrentScene;

  // Only query the scene render description if targeting an excluded scene so
  // we can render a standalone Remotion preview
  const { sceneRenderDescription } = useSceneRenderDescription(
    project.id,
    currentScene?.excluded ? currentScene.id : undefined
  );

  // Only query the intro outro render description if targeting an uploaded but
  // disabled intro/outro scene so we can render a standalone Remotion preview
  const skipIntroOutroRD =
    !sceneId || !['intro', 'outro'].includes(sceneId) || isEnabled(sceneId as Lowercase<ThemeVideoType>);
  const { introOutroRenderDescription } = useIntroOutroRenderDescription(
    project.id,
    capitalize(sceneId ?? '') as ThemeVideoType,
    skipIntroOutroRD
  );

  useMoveSceneKeyboardHandler();

  if (introOutroRenderDescription) {
    return (
      <EditorExcludedScenePlayer
        project={project}
        sceneId={sceneId}
        renderDescription={introOutroRenderDescription}
        enable={() => enableIntroOutro(sceneId as ThemeVideoType)}
      />
    );
  }

  if (sceneRenderDescription) {
    return (
      <EditorExcludedScenePlayer
        project={project}
        sceneId={sceneId}
        renderDescription={sceneRenderDescription}
        enable={() => (currentScene ? includeScene(currentScene.id) : undefined)}
      />
    );
  }

  return (
    <>
      <ResponsiveContainer project={project} data-testid="editor-preview">
        <EditorPreview
          bundleUrl={project.template.previewBundleUrl}
          templateIdentifier={project.template.templateIdentifier}
        />

        {isAssetProcessing && <StyledProcessingIndicator />}

        {showMusicHint && <EditorMusicHint />}

        <EditorPlayerControls projectId={project.id} />
      </ResponsiveContainer>

      <EditorPlayerActions />
    </>
  );
};
