import {
  BulkSelector,
  Button,
  IconButton,
  type MultiSelect,
  Typography,
  styled,
  useAnimount,
} from '@cofenster/web-components';
import { type FC, useCallback, useEffect, useState } from 'react';
import { useAddContributionsToProject } from './hooks/useAddContributionsToProject';
import { useDeleteContributionsWithConfirmation } from './hooks/useDeleteContributionsWithConfirmation';
import { useDownloadContributions } from './hooks/useDownloadContributions';
import type { ContributionItem } from './hooks/useFilterContributionRequests';

const Buttons = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
}));

export const ContributionsBulkActions: FC<{
  multiSelection: MultiSelect<ContributionItem>;
}> = ({ multiSelection }) => {
  const [isSelectorOpen, setIsSelectorOpen] = useState(false);
  const animountStatus = useAnimount(isSelectorOpen, 300);

  useEffect(() => {
    setIsSelectorOpen(multiSelection.selectedItems.length > 0);
  }, [multiSelection.selectedItems]);

  const onCancel = useCallback(() => multiSelection.clearSelection(), [multiSelection]);
  const onSelectAll = useCallback(() => multiSelection.selectAll(), [multiSelection]);
  const { onAdd, loading } = useAddContributionsToProject(multiSelection.selectedItems);
  const onDelete = useDeleteContributionsWithConfirmation(multiSelection.selectedItems);
  const onDownload = useDownloadContributions(multiSelection.selectedItems);

  if (animountStatus === 'EXITED') return null;

  return (
    <BulkSelector
      status={animountStatus}
      left={
        <Typography
          i18nParams={{
            count: multiSelection.selectedItems.length,
          }}
        >
          i18n.projectContributions.contributions.bulk.contributionsSelected
        </Typography>
      }
      center={
        <Buttons>
          <IconButton icon="TrashIcon" label="i18n.global.delete" onClick={() => onDelete()} />
          <IconButton icon="DownloadIcon" label="i18n.global.download" onClick={onDownload} />
          <IconButton
            icon="PlusIcon"
            label="i18n.projectContributions.contributions.bulk.addToProject"
            i18nParams={{ count: multiSelection.selectedItems.length }}
            onClick={onAdd}
            loading={loading}
          />
        </Buttons>
      }
      right={
        <Buttons>
          <Button variant="tertiary" onClick={onCancel}>
            i18n.global.cancel
          </Button>
          <Button variant="secondary" onClick={onSelectAll}>
            i18n.projectContributions.contributions.bulk.selectAll
          </Button>
        </Buttons>
      }
    />
  );
};
