const DOMAIN = 'o401315.ingest.sentry.io';

export const ADMIN_API_DSN = `https://9e74023bf3a5735b324b43e18771ffa6@${DOMAIN}/4506859002658816`;
export const ADMIN_CLIENT_DSN = `https://6cd9e2d395de4db9bbe2b196633ca995@${DOMAIN}/4504893603577856`;
export const ASSET_CLEANUP_STACK_DSN = `https://ac6b1deb754753ed6fcb9023a17de771@${DOMAIN}/4507249136369664`;
export const BACKEND_TRACKING_STACK_DSN = `https://1882cc85676bbbfc79e327d493a42b59@${DOMAIN}/4507843202580480`;
export const CAPTURE_API_DSN = `https://157bea1c9ffeef8d12cb0e6e007a7157@${DOMAIN}/4507860172931072`;
export const CAPTURE_CLIENT_DSN = `https://b2e55cb4f8e345f2bec5462c929cda5b@${DOMAIN}/6158757`;
export const CSP_DSN = `https://eb7cbd34a4432b2d6ce624a0b12ecb51@${DOMAIN}/4507843178528768`;
export const DATABASE_CLEANUP_STACK_DSN = `https://4a8a28488406f19041a05647d3dbf9f6@${DOMAIN}/4507843686498304`;
export const IMAGES_STACK_DSN = `https://4474709b124ce1ae8361aabcbac81a1e@${DOMAIN}/4507842891022336`;
export const MAINTENANCE_CLIENT_DSN = `https://b7933e094bc24a3b9d2458157391b9ac@${DOMAIN}/4505392309862400`;
export const MANAGER_API_DSN = `https://33712d3e2a0c4bcaae1c878595f46957@${DOMAIN}/5260771`;
export const MANAGER_CLIENT_DSN = `https://32a3a61672ce4f85bf40abcf3ac5952e@${DOMAIN}/5260770`;
export const NOTIFICATIONS_STACK_DSN = `https://c69200c13d038fcd9339651d31339a73@${DOMAIN}/4507842855174144`;
export const REMOTION_DSN = `https://8b13638ae0ff4801aaf2de94e6ecd837@${DOMAIN}/6395437`;
export const SERVICES_API_DSN = `https://81985ea6c0ae003b19df3d1b459c6546@${DOMAIN}/4507842819129344`;
export const TRANSCODINGS_STACK_DSN = `https://92c3f36595e9faa80f10ddfb60afb7d5@${DOMAIN}/4507826772312064`;
export const TRANSCRIPTIONS_STACK_DSN = `https://309f0a56af66b1fec25c629a9a9cd6d4@${DOMAIN}/4507843145629696`;
export const TRANSLATIONS_STACK_DSN = `https://ce7d2fcafe248714108bb1dcb36d0b42@${DOMAIN}/4507843168960512`;
export const VIDEO_API_DSN = `https://546cf1f2ba9d4dbf8786c41748921b8c@${DOMAIN}/4504870772408320`;
export const VIDEO_CLIENT_DSN = `https://32a3a61672ce4f85bf40abcf3ac5952e@${DOMAIN}/5260770`;
export const WORKER_OBSERVER_DSN = `https://9fdd4214e3dcb147a1ae4ccb098ef00d@${DOMAIN}/4507847795343360`;
export const WORKER_TRIGGER_DSN = `https://62f5d4303b48dfdd400f2d469ff7a425@${DOMAIN}/4507847783219200`;
