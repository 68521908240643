import { type I18nParams, Translatable, Typography } from '@cofenster/web-components';
import { GlobalStyles, styled } from '@mui/material';
import type { FC } from 'react';

const PageNotificationWrapper = styled('div')(({ theme }) => ({
  backgroundImage: `url("data:image/svg+xml,%3Csvg width='1440' height='40' viewBox='0 0 1440 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M265.5 0C283 27.5 308.5 40 308.5 40H534C516.5 23 505.5 0 505.5 0H265.5Z' fill='url(%23paint0_linear_30369_19307)'/%3E%3Cpath d='M0 0V40H308.5C308.5 40 283 27.5 265.5 0H0Z' fill='%234FF8A8'/%3E%3Cpath d='M505.5 0C505.5 0 516.5 23 534 40H960C954.709 28.3603 950.818 23.9986 946.549 19.2136C942.75 14.9546 938.651 10.3603 933 0H505.5Z' fill='url(%23paint1_linear_30369_19307)'/%3E%3Cpath d='M999 0H933C938.651 10.3603 942.75 14.9546 946.549 19.2136C950.818 23.9986 954.709 28.3603 960 40H1023C1018.6 34.8505 1015.44 31.5309 1013.03 29.003C1005.84 21.4672 1005.36 20.9666 999 0Z' fill='%2373F999'/%3E%3Cpath d='M1398.5 0H999C1005.36 20.9666 1005.84 21.4672 1013.03 29.003C1015.44 31.5309 1018.6 34.8505 1023 40H1414L1398.5 0Z' fill='url(%23paint2_linear_30369_19307)'/%3E%3Cpath d='M1440 40V0H1398.5L1414 40H1440Z' fill='%2373F999'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_30369_19307' x1='0' y1='20' x2='1440' y2='20' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%2373F999'/%3E%3Cstop offset='1' stop-color='%2373F999'/%3E%3C/linearGradient%3E%3ClinearGradient id='paint1_linear_30369_19307' x1='0' y1='20' x2='1440' y2='20' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23B9FB7B'/%3E%3Cstop offset='1' stop-color='%23B5FB7C'/%3E%3C/linearGradient%3E%3ClinearGradient id='paint2_linear_30369_19307' x1='0' y1='20' x2='1440' y2='20' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%2388FA90'/%3E%3Cstop offset='1' stop-color='%238DFA8E'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");`,
  color: theme.palette.brand.carbon,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: theme.zIndex.above,
  height: 'var(--page-notification-height)',
}));

const PageNotificationText = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(1),
  '& > svg': { verticalAlign: 'middle' },
}));

export type PageNotificationProps = {
  i18nKey?: string;
  i18nParams?: I18nParams;
};

export const PageNotification: FC<PageNotificationProps> = ({ i18nKey, i18nParams }) => {
  return i18nKey ? (
    <>
      <PageNotificationWrapper role="status" data-testid="page-notification">
        <GlobalStyles styles={{ body: { '--page-notification-height': '40px' } }} />
        <PageNotificationText variant="l" component="p">
          <Translatable i18nParams={i18nParams}>{i18nKey}</Translatable>
        </PageNotificationText>
      </PageNotificationWrapper>
    </>
  ) : null;
};
