import { useMemo } from 'react';

import { type DuplicateProjectTemplateMutationVariables, useDuplicateProjectTemplateMutation } from '../../generated';

export const useDuplicateProjectTemplate = (id: DuplicateProjectTemplateMutationVariables['id']) => {
  const [duplicateProjectTemplate, metadata] = useDuplicateProjectTemplateMutation({
    refetchQueries: ['ProjectTemplates'],
    awaitRefetchQueries: true,
  });

  return useMemo(
    () => [() => duplicateProjectTemplate({ variables: { id } }), metadata] as const,
    [duplicateProjectTemplate, metadata, id]
  );
};
