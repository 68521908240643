import type { VideoFormat } from '@cofenster/constants';
import { useCallback } from 'react';
import type { ProjectFolder } from '../../api/hooks/projectFolder/useProjectFolder';
import { useWebManagerTracking } from '../../hooks/useWebManagerTracking';

export const useProjectCreatedTracking = (projectFolder?: ProjectFolder) => {
  const tracking = useWebManagerTracking();

  return useCallback(
    (projectId: string, name: string, videoFormat: VideoFormat) => {
      if (!projectFolder) return;
      tracking.trackEvent({
        event: 'projectCreated',
        details: {
          projectId,
          projectName: name,
          videoFormat,
          source: 'new project',
          teamId: projectFolder.team?.id,
          teamName: projectFolder.team?.name,
          teamType: projectFolder.team?.public ? 'public' : 'private',
          folderId: projectFolder.id,
          folderName: projectFolder.name,
          folderType: projectFolder.team ? 'team' : 'private',
        },
      });
    },
    [projectFolder, tracking]
  );
};
