import { type FormikHelpers, useField, useFormikContext } from 'formik';
import { type FC, useCallback, useMemo } from 'react';
import * as Yup from 'yup';

import { Button, Form, FormTextField, GridContainer, GridItem, useI18n } from '@cofenster/web-components';

import { useContributionRequestListByProject } from '../../../../api/hooks/contributionRequestList/useContributionRequestListsByProject';
import {
  type CreateContributionRequestListInput,
  useCreateContributionRequestList,
} from '../../../../api/hooks/contributionRequestList/useCreateContributionRequestList';

type Values = CreateContributionRequestListInput;

const validations = {
  projectId: Yup.string().required(),
  contributionRequestIds: Yup.array().of(Yup.string().required()).required(),
  name: Yup.string(),
};
const validationSchema = Yup.object<Values>().shape(validations);

const useInitialValues = (projectId: string, contributionRequestId: string) => {
  return useMemo(
    () => ({
      projectId,
      contributionRequestIds: [contributionRequestId],
      name: '',
    }),
    [projectId, contributionRequestId]
  );
};

const useSubmit = () => {
  const { createContributionRequestList } = useCreateContributionRequestList();
  return useCallback(
    async (
      values: CreateContributionRequestListInput,
      { setFieldValue }: FormikHelpers<CreateContributionRequestListInput>
    ) => {
      if (!values.name) return;
      await createContributionRequestList(values);
      await setFieldValue('name', '');
    },
    [createContributionRequestList]
  );
};

type CreateContributionRequestListFormProps = {
  projectId: string;
  contributionRequestId: string;
};

export const CreateContributionRequestListForm: FC<CreateContributionRequestListFormProps> = ({
  projectId,
  contributionRequestId,
}) => {
  const initialValues = useInitialValues(projectId, contributionRequestId);
  const onSubmit = useSubmit();
  const existingContributionRequestList = useContributionRequestListByProject(projectId);
  const { translate } = useI18n();

  const test = async (values: Values, helpers: FormikHelpers<Values>) => {
    const { setErrors } = helpers;
    const nameExists = existingContributionRequestList.contributionRequestLists.some(
      (contributionRequestList) => contributionRequestList.name === values.name
    );
    if (nameExists) {
      setErrors({ name: translate('contributionRequest.sharingList.duplicate', { name: values.name }) });
      return;
    }

    await onSubmit(values, helpers);
  };

  return (
    <Form initialValues={initialValues} validationSchema={validationSchema} onSubmit={test}>
      <FormContent />
    </Form>
  );
};

const FormContent: FC = () => {
  const [{ value }] = useField('name');
  const { isSubmitting } = useFormikContext();
  const disabled = !value;

  return (
    <GridContainer spacing={1}>
      <GridItem xs={8}>
        <FormTextField
          id="name"
          name="name"
          label="i18n.projectContribution.CreateContributionRequestListForm.addListInputLabel"
          placeholder="i18n.common.name"
          autoFocus
          autoComplete="off"
          maxLength={50}
          fullWidth
          data-testid="create-contribution-request-list-form-name-input"
        />
      </GridItem>
      <GridItem xs={4}>
        <Button
          type="submit"
          disabled={disabled}
          loading={isSubmitting}
          fullWidth
          data-testid="create-contribution-request-list-form-submit-button"
        >
          i18n.common.create
        </Button>
      </GridItem>
    </GridContainer>
  );
};
