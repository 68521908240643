import { type FC, useCallback, useRef, useState } from 'react';
import { RouterProvider } from 'react-router-dom';

import {
  type ApolloClient,
  ApolloProvider,
  type ErrorResponse,
  createApiClient,
  isUnauthenticated,
} from '@cofenster/api-client-apollo';
import { MANAGER_API_URL } from '@cofenster/constants';
import {
  SnackbarsProvider,
  ThemeProvider,
  TrackingProvider,
  UploadProvider,
  useAxe,
  useVersionLog,
} from '@cofenster/web-components';

import { DialogsProvider as ActorDialogsProvider } from '../../web-actor/src/context/dialogs';
import pkg from '../package.json';

import { router } from './Router';
import { ErrorBoundary } from './components/ErrorBoundary';
import { DialogsProvider } from './contexts/dialogs';
import { FeatureFlagsProvider } from './contexts/featureFlags/FeatureFlagsProvider';
import { UserProvider } from './contexts/user/UserProvider';
import { useAssetUpload } from './hooks/useAssetUpload';
import { I18nProvider } from './i18n';

const SEGMENT_WRITE_KEY_WEB_MANAGER = process.env.SEGMENT_WRITE_KEY_WEB_MANAGER as string;
const STAGE = process.env.STAGE as string;

export const App: FC = () => {
  const client = useRef<ApolloClient<unknown>>();
  // This state is intended to store whether we have caught a 401 Unauthorized
  // due to an expired authentication token, so we can show a dialog for the
  // user to sign in again
  const [hasSessionExpired, setHasSessionExpired] = useState(false);

  const onError = useCallback((error: ErrorResponse) => {
    if (isUnauthenticated(error)) setHasSessionExpired(true);
  }, []);

  if (!client.current) {
    client.current = createApiClient({
      // Locally, the backend API is proxied through the /graphql route via the
      // webpack dev server configuration
      url: process.env.STAGE === 'local' ? '/graphql' : MANAGER_API_URL,
      onError,
    });
  }

  useVersionLog(pkg);
  useAxe(STAGE);

  return (
    <ThemeProvider>
      <TrackingProvider segmentWriteKey={SEGMENT_WRITE_KEY_WEB_MANAGER}>
        <ApolloProvider client={client.current}>
          <UserProvider hasSessionExpired={hasSessionExpired} setHasSessionExpired={setHasSessionExpired}>
            <I18nProvider>
              <ErrorBoundary>
                <UploadProvider useUpload={useAssetUpload}>
                  <FeatureFlagsProvider>
                    <SnackbarsProvider>
                      <ActorDialogsProvider>
                        <DialogsProvider>
                          <RouterProvider router={router} />
                        </DialogsProvider>
                      </ActorDialogsProvider>
                    </SnackbarsProvider>
                  </FeatureFlagsProvider>
                </UploadProvider>
              </ErrorBoundary>
            </I18nProvider>
          </UserProvider>
        </ApolloProvider>
      </TrackingProvider>
    </ThemeProvider>
  );
};
