import { createContext } from 'react';

import type { ProjectTemplatesSearchFilters } from './useProjectTemplatesSearchFilters';

export type ProjectTemplatesSearchFilterContext = {
  filter: ProjectTemplatesSearchFilters;
  page: number;
  setPage: (page: number) => void;
  setSearch: (search: ProjectTemplatesSearchFilters['search']) => void;
  setVideoFormat: (videoFormat: ProjectTemplatesSearchFilters['videoFormat']) => void;
  setTags: (tags: ProjectTemplatesSearchFilters['tags']) => void;
  setCreatedBy: (createdBy: ProjectTemplatesSearchFilters['createdBy']) => void;
};

export const ProjectTemplatesSearchFilterContext = createContext<ProjectTemplatesSearchFilterContext | undefined>(
  undefined
);
