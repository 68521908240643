export type OS = 'Unknown' | 'MacOS' | 'Windows' | 'Linux';

export const detectOS = () => {
  const platform = window.navigator.platform;
  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
  let os: OS = 'Unknown';

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'MacOS';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows';
  } else if (!os && /Linux/.test(platform)) {
    os = 'Linux';
  }

  return os;
};
