import type { FC } from 'react';

import type { VideoFormat } from '@cofenster/constants';
import { GridContainer, GridItem, LoadingSpinner, Typography, useMultiSelect } from '@cofenster/web-components';

import { useContributionRequestsByProject } from '../../../../api/hooks/contributionRequest/useContributionRequestsByProject';
import { useI18n } from '../../../../i18n';

import { ContributionAssetListItem } from './ContributionAssetListItem';
import { ContributionRecordingsEmptyState } from './ContributionRecordingsEmptyState';
import { ContributionRequestsSelect } from './ContributionRequestsSelect';
import { ContributionsBulkActions } from './ContributionsBulkActions';
import { ContributorSelect } from './ContributorSelect';
import { useFilterContributionRequests } from './hooks/useFilterContributionRequests';

export const ContributionRecordings: FC<{
  projectId: string;
  videoFormat: VideoFormat;
  onSceneCreated?: (sceneId: string) => unknown;
  newSceneIndex?: number;
  goToProjectContributionsPage?: () => unknown;
  canUpdateProject: boolean;
}> = ({ projectId, videoFormat, onSceneCreated, newSceneIndex, goToProjectContributionsPage, canUpdateProject }) => {
  const { translate } = useI18n();
  const { data, loading: loadingContributionRequests } = useContributionRequestsByProject(projectId);
  const requests = data?.contributionRequestsByProject ?? [];
  const {
    requestIds,
    onRequestChange,
    contributorIds,
    onContributorChange,
    contributors,
    filteredContributionItems,
    contributionItems,
  } = useFilterContributionRequests(requests);

  const multiSelection = useMultiSelect(filteredContributionItems, []);

  if (loadingContributionRequests) return <LoadingSpinner />;

  if (!contributionItems.length) {
    return <ContributionRecordingsEmptyState goToProjectContributionsPage={goToProjectContributionsPage} />;
  }

  return (
    <GridContainer>
      <GridItem xs={12} md={4} alignItems="center" display="flex">
        <Typography variant="h5" i18nParams={{ count: filteredContributionItems.length }} component="p">
          i18n.projectContributions.ContributionRequestCard.contributionsCount
        </Typography>
      </GridItem>
      <GridItem xs={12} md={4}>
        <ContributorSelect contributors={contributors} value={contributorIds} onChange={onContributorChange} />
      </GridItem>
      <GridItem xs={12} md={4}>
        <ContributionRequestsSelect contributionRequests={requests} value={requestIds} onChange={onRequestChange} />
      </GridItem>
      {filteredContributionItems?.map((item) => (
        <GridItem key={item.asset.id} xs={12} sm={6} md={4}>
          <ContributionAssetListItem
            videoFormat={videoFormat}
            item={item}
            title={
              item.contributionRequest.title
                ? item.contributionRequest.title
                : translate('projectContributionConfiguration.filter.byRequests.emptyCRTitle', {
                    index: item.contributionRequest.index + 1,
                  })
            }
            contributors={contributors}
            onSceneCreated={onSceneCreated}
            newSceneIndex={newSceneIndex}
            canUpdateProject={canUpdateProject}
            multiSelection={multiSelection}
          />
        </GridItem>
      ))}
      <GridItem xs={12}>
        <ContributionsBulkActions multiSelection={multiSelection} />
      </GridItem>
    </GridContainer>
  );
};
