import {
  type GotoCallback,
  PopoverMenuDivider,
  PopoverMenuItem,
  PopoverMenuTriggerIcon,
  withPopoverMenu,
} from '@cofenster/web-components';
import type { FC } from 'react';
import type { ProjectTemplate } from '../../api/hooks/projectTemplate/useProjectTemplates';
import { useAccountPermissionStatus } from '../../contexts/user/AccountPermissionRestriction';
import { useOnDeleteProjectTemplateWithConfirmation } from './hooks/useOnDeleteProjectTemplateWithConfirmation';
import { useOnDuplicateProjectTemplate } from './hooks/useOnDuplicateProjectTemplate';
import { useOnEditProjectTemplate } from './hooks/useOnEditProjectTemplate';
import { useOnRenameProjectTemplate } from './hooks/useOnRenameProjectTemplate';

export const ProjectTemplateMenu: FC<{
  projectTemplate: ProjectTemplate;
  goto: GotoCallback;
  onPreviewProjectTemplate: VoidFunction;
}> = ({ projectTemplate, goto, onPreviewProjectTemplate }) => {
  const onRenameProjectTemplate = useOnRenameProjectTemplate(projectTemplate);
  const onEditProjectTemplate = useOnEditProjectTemplate(projectTemplate, goto);
  const onDuplicateProjectTemplate = useOnDuplicateProjectTemplate(projectTemplate.id);
  const onDeleteProjectTemplate = useOnDeleteProjectTemplateWithConfirmation(projectTemplate.id);
  const canUpdateProject = useAccountPermissionStatus({ has: 'ProjectCreate' }) === 'ALLOWED';

  if (!canUpdateProject) return null;

  const PopoverMenuIcon = withPopoverMenu(PopoverMenuTriggerIcon, {
    children: (
      <>
        <PopoverMenuItem icon="PlusIcon" onClick={onPreviewProjectTemplate}>
          i18n.projectTemplates.projectTile.actions.useTemplate
        </PopoverMenuItem>
        <PopoverMenuDivider key="divider1" />
        <PopoverMenuItem icon="TextBoxIcon" onClick={onRenameProjectTemplate}>
          i18n.common.rename
        </PopoverMenuItem>
        <PopoverMenuItem icon="PencilSimpleIcon" onClick={onEditProjectTemplate}>
          i18n.common.edit
        </PopoverMenuItem>
        <PopoverMenuItem icon="DuplicateIcon" onClick={onDuplicateProjectTemplate}>
          i18n.common.duplicate
        </PopoverMenuItem>
        <PopoverMenuDivider key="divider2" />
        <PopoverMenuItem icon="TrashIcon" color="negative" onClick={onDeleteProjectTemplate}>
          i18n.global.delete
        </PopoverMenuItem>
      </>
    ),
    transformOriginX: 'left',
    anchorOriginX: 'left',
    offsetY: 4,
  });

  return <PopoverMenuIcon data-testid="project-templates-more-options" />;
};
