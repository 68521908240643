import { type PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { RawIntlProvider } from 'react-intl';

import { I18nContext } from './I18nContext';
import { isSupportedLocale, useReactIntl } from './useReactIntl';

import type { I18nParams, SupportedLocale, Translations } from './types';

const I18N_PREFIX = 'i18n.';

const useSetLocale = <T extends Translations>(
  translations: T,
  userLocale: I18nProviderProps<T>['userLocale'],
  defaultLocale: SupportedLocale<T>
) => {
  const initialLocale = isSupportedLocale(translations, userLocale) ? userLocale : defaultLocale;
  const [currentLocale, setCurrentLocale] = useState<SupportedLocale<T>>(initialLocale);
  const setLocale = useCallback(
    (locale: string) => {
      if (!isSupportedLocale(translations, locale)) return;
      if (locale === currentLocale) return;
      setCurrentLocale(locale);
    },
    [currentLocale, translations]
  );
  return useMemo(() => [currentLocale, setLocale] as const, [currentLocale, setLocale]);
};

const useLanguageNames = (locale: string) => {
  const languageNames = useMemo(() => new Intl.DisplayNames([locale], { type: 'language' }), [locale]);
  const safeOf = useCallback(
    (code: string) => {
      try {
        // We tend to use locales expressed with underscores (e.g. `de_DE`), but
        // the `Intl.DisplayNames` API expects a language code using hyphens.
        return languageNames.of(code.replace('_', '-'));
      } catch {
        return code;
      }
    },
    [languageNames]
  );

  return useMemo(() => ({ ...languageNames, of: safeOf }), [languageNames, safeOf]);
};

const useI18nContext = <T extends Translations>(
  translations: I18nProviderProps<T>['translations'],
  defaultLocale: I18nProviderProps<T>['defaultLocale'],
  userLocale: I18nProviderProps<T>['userLocale']
) => {
  const [currentLocale, setLocale] = useSetLocale(translations, userLocale, defaultLocale);
  const { intl, locale } = useReactIntl(translations, defaultLocale, currentLocale);
  const languageNames = useLanguageNames(locale);

  const safeFormatMessage = useCallback(
    (key: string, values?: I18nParams) => {
      try {
        return intl.formatMessage({ id: key }, values);
      } catch (error) {
        console.error(error);
        return key;
      }
    },
    [intl]
  );

  return useMemo(() => {
    return {
      intl,
      context: {
        locale,
        setLocale,
        translate: (key, values?) => safeFormatMessage(key, values),
        translatable: (key, values?) => {
          if (typeof key === 'string' && key.startsWith(I18N_PREFIX)) {
            return safeFormatMessage(key.substring(I18N_PREFIX.length), values);
          }

          return key;
        },
        languageNames,
      } as I18nContext<T>,
    };
  }, [intl, setLocale, locale, safeFormatMessage, languageNames]);
};

type I18nProviderProps<T extends Translations> = PropsWithChildren<{
  translations: T;
  defaultLocale: SupportedLocale<T>;
  userLocale?: string;
}>;

export const I18nProvider = <T extends Translations>({
  children,
  translations,
  defaultLocale,
  userLocale,
}: I18nProviderProps<T>) => {
  const { intl, context } = useI18nContext(translations, defaultLocale, userLocale);
  return (
    <RawIntlProvider value={intl}>
      <I18nContext.Provider key={context.locale} value={context as I18nContext<Translations>}>
        {children}
      </I18nContext.Provider>
    </RawIntlProvider>
  );
};
