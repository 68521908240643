import { type FormikValues, useField } from 'formik';
import { type FC, useCallback, useMemo } from 'react';
import * as Yup from 'yup';

import { VIDEO_FORMATS, type VideoFormat } from '@cofenster/constants';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Form,
  FormTextField,
  Spacing,
  Text,
  useSnackbars,
} from '@cofenster/web-components';

import { useCloneProject } from '../../../api/hooks/project/useCloneProject';
import type { Project } from '../../../api/hooks/project/useProjects';
import { useWebManagerTracking } from '../../../hooks/useWebManagerTracking';
import { useI18n } from '../../../i18n';
import { VideoFormatSelect } from '../../form/VideoFormatSelect';

export type DuplicateProjectDialogProps = {
  project: Project;
  isOpen: boolean;
  closeDialog: () => unknown;
  teamId?: string;
  refetchQueries?: string[];
};

const useDuplicate = (project: Project, closeDialog: () => unknown, teamId?: string, refetchQueries?: string[]) => {
  const { openSnackbar } = useSnackbars();
  const tracking = useWebManagerTracking(teamId);
  const duplicateProjectFolder = useCloneProject(refetchQueries);

  const trackProjectDuplicated = useCallback(
    (values: FormikValues, duplicatedProjectId: string | undefined) =>
      tracking.trackEvent({
        event: 'projectDuplicated',
        details: {
          projectId: project.id,
          projectName: project.name,
          videoFormat: values.videoFormat,
          name: values.name,
          duplicatedProjectId,
        },
      }),
    [tracking, project.id, project.name]
  );

  return useCallback(
    async (values: FormikValues) => {
      if (!project) return;

      const { videoFormat, name } = values;

      // Perform the mutation and track it
      const result = await duplicateProjectFolder(project.id, { videoFormat, name });
      trackProjectDuplicated(values, result.data?.cloneProject?.id);

      // Display the success snackbar and close the dialog
      openSnackbar({ children: 'i18n.snackbars.projectDuplicatedSnackbar.copy' });
      closeDialog();
    },
    [project, duplicateProjectFolder, closeDialog, openSnackbar, trackProjectDuplicated]
  );
};

const useValidationSchema = () => {
  const { translate } = useI18n();

  return useMemo(() => {
    const validations = {
      name: Yup.string()
        .trim()
        .max(250, translate('form.error.generic.maxLength', { max: 250 }))
        .required('i18n.form.error.generic.required'),
      includeSceneAssets: Yup.boolean().required('i18n.form.error.generic.required'),
      videoFormat: Yup.mixed()
        .required('i18n.form.error.generic.required')
        .oneOf(Object.keys(VIDEO_FORMATS), 'i18n.form.error.generic.required') as Yup.StringSchema<VideoFormat>,
    };

    return Yup.object().shape(validations);
  }, [translate]);
};

const VideoFormatField = () => {
  const [field, , { setValue }] = useField({ name: 'videoFormat' });

  return (
    <VideoFormatSelect
      videoFormat={field.value}
      setVideoFormat={setValue}
      name="videoFormat"
      label="i18n.dialogs.duplicateProjectDialog.videoFormat"
      data-testid="project-format-field"
    />
  );
};

const useInitialValues = (project: Project) => {
  const { translate } = useI18n();
  const name = translate('dialogs.duplicateProjectDialog.defaultName', { name: project.name });

  return useMemo(
    () => ({
      videoFormat: project.videoFormat,
      includeSceneAssets: true,
      name,
    }),
    [project.videoFormat, name]
  );
};

export const DuplicateProjectDialog: FC<DuplicateProjectDialogProps> = ({
  isOpen,
  closeDialog,
  project,
  teamId,
  refetchQueries,
}) => {
  const handleSubmit = useDuplicate(project, closeDialog, teamId, refetchQueries);
  const initialValues = useInitialValues(project);
  const validationSchema = useValidationSchema();

  return (
    <Dialog
      open={isOpen}
      onClose={closeDialog}
      data-testid="project-duplicate-dialog"
      title="i18n.dialogs.duplicateProjectDialog.headline"
    >
      <Form initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        <DialogContent>
          <Spacing bottom={3}>
            <Text variant="l" color="grey600" component="p">
              i18n.dialogs.duplicateProjectDialog.description
            </Text>
          </Spacing>

          <FormTextField
            name="name"
            id="name"
            label="i18n.projectCreate.form.name.label"
            data-testid="project-name-field"
          />
          <VideoFormatField />
        </DialogContent>
        <DialogActions>
          <Button variant="tertiary" type="button" fullWidth onClick={closeDialog}>
            i18n.global.cancel
          </Button>
          <Button variant="primary" type="submit" fullWidth data-testid="project-duplicate-dialog-button">
            i18n.dialogs.duplicateProjectDialog.button
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  );
};
