import { useCallback } from 'react';
import type { ProjectTemplate } from '../../../api/hooks/projectTemplate/useProjectTemplates';
import { useDialogs } from '../../../contexts/dialogs';

export const useOnRenameProjectTemplate = (projectTemplate: ProjectTemplate) => {
  const { openDialog } = useDialogs();
  return useCallback(() => {
    openDialog('RenameProjectTemplateDialog', {
      projectTemplateId: projectTemplate.id,
      name: projectTemplate.project.name,
      description: projectTemplate.description ?? undefined,
    });
  }, [openDialog, projectTemplate]);
};
