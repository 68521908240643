import { useCallback } from 'react';
import { useDownloadUrl } from '../../../../../hooks/download/useDownloadUrl';
import { useWebManagerTracking } from '../../../../../hooks/useWebManagerTracking';
import type { ContributionItem } from './useFilterContributionRequests';

export const useDownloadContributions = (items: ContributionItem[]) => {
  const download = useDownloadUrl();

  const tracking = useWebManagerTracking();

  return useCallback(
    () =>
      items
        .filter((item) => item.asset.downloadUrl != null)
        .forEach((item, index) => {
          const { asset, contributionRequest } = item;
          const { downloadUrl } = asset;
          if (downloadUrl == null) return;

          // chrome will not allow to download more than 10 files without setTimeout
          // safari does not work at all without setTimeout for 2 or more downloads
          setTimeout(() => {
            download(downloadUrl);
            tracking.trackEvent({
              event: 'requestAssetDownloaded',
              details: {
                requestId: contributionRequest.id,
                requestAssetId: asset.id,
              },
            });
          }, index * 200);
        }),
    [items, download, tracking]
  );
};
