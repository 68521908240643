import { useMultiSelect } from '@cofenster/web-components';
import type { FC, PropsWithChildren } from 'react';
import { useParams } from 'react-router-dom';
import { useScenes } from '../scenes/useScenes';
import { SelectedScenesContext } from './SelectedScenesContext';

export const SelectedScenesProvider: FC<PropsWithChildren> = ({ children }) => {
  const { sceneId } = useParams();
  const { scenes, currentScene } = useScenes();
  const onIntroOrOutro = sceneId && ['intro', 'outro'].includes(sceneId);

  const context = useMultiSelect(scenes, currentScene && !onIntroOrOutro ? [currentScene] : []);

  return <SelectedScenesContext.Provider value={context}>{children}</SelectedScenesContext.Provider>;
};
