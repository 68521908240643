import type { FC } from 'react';

import {
  Button,
  CSSGridTable,
  type CSSGridTableConfig,
  GridContainer,
  GridItem,
  Icon,
  LoadingSpinner,
  Typography,
  VisuallyHidden,
  styled,
} from '@cofenster/web-components';

import { useResolveRoleRequest } from '../../api/hooks/roleRequest/useResolveRoleRequest';
import { type RoleRequest, useRoleRequests } from '../../api/hooks/roleRequest/useRoleRequest';

export const RoleRequests: FC<{ teamId?: string }> = ({ teamId = null }) => {
  const { roleRequests, loading, error } = useRoleRequests(teamId);

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <Typography color="negative">i18n.global.error.generic.unknown</Typography>;
  }

  if (!roleRequests?.length) {
    return null;
  }

  return (
    <GridContainer marginTop={2}>
      <GridItem xs={12}>
        <Typography variant="xl" component="h2" align="center">
          i18n.roleRequests.title
        </Typography>
      </GridItem>
      <GridItem xs={12}>
        <CSSGridTable columns={columns} data={roleRequests} data-testid="role-requests" />
      </GridItem>
    </GridContainer>
  );
};

const Flexbox = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  alignItems: 'center',
}));

const RoleRequestInfo: FC<{ item: RoleRequest }> = ({ item }) => {
  return (
    <Flexbox>
      <Icon type="TimerIcon" size="s" color="white" background={{ size: 'l', color: 'blue' }} />
      <Typography
        color="grey600"
        component="p"
        i18nParams={{
          userName: (
            <>
              <Typography variant="h6" color="carbon" component="strong">
                {item.requester.name}
              </Typography>
              <br />
            </>
          ),
          roleName: <Typography component="strong">{item.role.name}</Typography>,
        }}
      >
        i18n.roleRequests.item.content
      </Typography>
    </Flexbox>
  );
};

const DenyRoleRequest: FC<{ item: RoleRequest }> = ({ item }) => {
  const deny = useResolveRoleRequest(item.id, false);
  return (
    <Button startIcon={<Icon type="NoOptionIcon" />} variant="tertiary" onClick={deny}>
      i18n.roleRequests.item.deny
    </Button>
  );
};

const ApproveRoleRequest: FC<{ item: RoleRequest }> = ({ item }) => {
  const approve = useResolveRoleRequest(item.id, true);
  return (
    <Button startIcon={<Icon type="CheckIcon" />} variant="primary" onClick={approve}>
      i18n.roleRequests.item.approve
    </Button>
  );
};

const columns: CSSGridTableConfig<RoleRequest>['columns'] = [
  {
    id: 'requester',
    name: 'i18n.roleRequests.item.requester',
    header: ({ column }) => <VisuallyHidden>{column.name}</VisuallyHidden>,
    cell: ({ item }) => <RoleRequestInfo item={item} />,
  },
  {
    id: 'deny',
    name: 'i18n.roleRequests.item.deny',
    header: ({ column }) => <VisuallyHidden>{column.name}</VisuallyHidden>,
    cell: ({ item }) => <DenyRoleRequest item={item} />,
    extra: { size: 'min-content' },
  },
  {
    id: 'approve',
    name: 'i18n.roleRequests.item.approve',
    header: ({ column }) => <VisuallyHidden>{column.name}</VisuallyHidden>,
    cell: ({ item }) => <ApproveRoleRequest item={item} />,
    extra: { size: 'min-content' },
  },
];
