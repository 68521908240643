import { useMemo } from 'react';
import {
  type ProjectTemplatesQueryResult,
  type ProjectTemplatesQueryVariables,
  useProjectTemplatesQuery,
} from '../../generated';

export type ProjectTemplatesFilter = NonNullable<ProjectTemplatesQueryVariables['filter']>;
export type PaginatedProjectTemplates = NonNullable<ProjectTemplatesQueryResult['data']>['projectTemplates'];
export type ProjectTemplate = NonNullable<
  NonNullable<ProjectTemplatesQueryResult['data']>['projectTemplates']
>['items'][number];

export const useProjectTemplates = (filter?: ProjectTemplatesFilter) => {
  const { loading, error, data } = useProjectTemplatesQuery({
    variables: { filter },
    // Project templates might be added by other users. Make sure that
    // the cache is always up-to-date.
    fetchPolicy: 'cache-and-network',
  });
  const paginatedProjectTemplates = data?.projectTemplates ?? undefined;
  return useMemo(() => ({ paginatedProjectTemplates, loading, error }), [paginatedProjectTemplates, loading, error]);
};
