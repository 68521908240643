import type { FC, PropsWithChildren } from 'react';

import { ProjectTemplatesSearchFilterContext } from './ProjectTemplatesSearchFilterContext';
import { useProjectTemplatesSearchFilters } from './useProjectTemplatesSearchFilters';

export const ProjectTemplatesSearchFilterProvider: FC<
  PropsWithChildren<{
    resultsPerPage?: number;
  }>
> = ({ children, resultsPerPage = 10 }) => {
  const state = useProjectTemplatesSearchFilters(resultsPerPage);

  return (
    <ProjectTemplatesSearchFilterContext.Provider value={state}>
      {children}
    </ProjectTemplatesSearchFilterContext.Provider>
  );
};
