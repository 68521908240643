import type { FC } from 'react';

import type { VideoFormat } from '@cofenster/constants';
import { Select, SelectOption, type SelectProps, Translatable, styled } from '@cofenster/web-components';

// 1. Assign an arbitrary minimum width so the width does not vary based on the
//    picked option
const StyledSelect = styled(Select)(() => ({
  minWidth: 215, // 1
}));

const translations: Record<VideoFormatSelectValue, string> = {
  All: 'i18n.common.allFormats',
  Horizontal: 'i18n.common.horizontal',
  Vertical: 'i18n.common.vertical',
  Square: 'i18n.common.square',
  SocialMedia: 'i18n.common.socialMedia',
};

const options: VideoFormatSelectValue[] = ['All', 'Horizontal', 'Vertical', 'Square', 'SocialMedia'];

export type VideoFormatSelectValue = VideoFormat | 'All';

export const VideoFormatSelect: FC<
  Omit<SelectProps, 'label' | 'name' | 'value'> & { name?: string; value: VideoFormatSelectValue }
> = ({ id = 'videoFormat', name = 'videoFormat', ...rest }) => {
  return (
    <StyledSelect
      id={id}
      name={name}
      label="i18n.common.videoFormatLong"
      renderValue={(value) => {
        const typedValue = value as VideoFormatSelectValue;
        return <Translatable>{translations[typedValue]}</Translatable>;
      }}
      pb={0}
      {...rest}
    >
      {options.map((format) => (
        <SelectOption key={format} value={format}>
          {translations[format]}
        </SelectOption>
      ))}
    </StyledSelect>
  );
};
