import { useMemo } from 'react';

import {
  type DeleteAssetFromContributionRequestMutationVariables,
  useDeleteAssetFromContributionRequestMutation,
} from '../../generated';

export const useDeleteAssetFromContributionRequest = () => {
  const [deleteAssetFromContributionRequest, { loading }] = useDeleteAssetFromContributionRequestMutation({
    refetchQueries: ['Scenes'],
    awaitRefetchQueries: true,
  });

  return useMemo(() => {
    return {
      deleteAssetFromContributionRequest: (inputs: DeleteAssetFromContributionRequestMutationVariables['inputs']) =>
        deleteAssetFromContributionRequest({
          variables: { inputs },
        }),
      loading,
    };
  }, [deleteAssetFromContributionRequest, loading]);
};
