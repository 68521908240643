import { type FormikValues, useFormikContext } from 'formik';
import { useEffect } from 'react';

export type FormObserverProps<Values extends FormikValues> = {
  onChange: (values: Values) => unknown;
};

export const FormObserver = <Values extends FormikValues>({ onChange }: FormObserverProps<Values>) => {
  const { values } = useFormikContext<Values>();
  useEffect(() => {
    onChange(values);
  }, [onChange, values]);
  return null;
};
