import type { FormikHelpers } from 'formik';
import { type FC, useCallback, useMemo, useState } from 'react';
import * as Yup from 'yup';

import { Form, GridContainer, GridItem, useSnackbars } from '@cofenster/web-components';

import type { ContributionRequestList } from '../../../../api/hooks/contributionRequestList/useContributionRequestListsByProject';
import { useSendContributionRequestListAccessEmail } from '../../../../api/hooks/contributionRequestList/useSendContributionRequestListAccessEmail';
import { useI18n } from '../../../../i18n';
import { useTrackContributionRequestList } from '../../../../pages/ProjectContributionConfiguration/hooks/useTrackContributionRequestList';
import { CardSeparator } from '../../../card';
import { ContributionRequestListEmailSelect } from '../ContributionRequestListEmailSelect';
import { ContributionRequestListSelect } from '../ContributionRequestListSelect';
import { ContributionRequestShareButtons } from '../ContributionRequestShareButtons';
import { ContributionRequestShareSnackbarText } from '../ContributionRequestShareSnackbarText';

type Values = {
  emails: string[];
  message: string | undefined;
};

const validationSchema: Yup.ObjectSchema<Values> = Yup.object().shape({
  emails: Yup.array()
    .of(Yup.string().email().required())
    .min(1, 'i18n.dialogs.ContributionRequestListDialog.emailInput.validation.min')
    .required(),
  message: Yup.string(),
});

const useInitialValues = () => {
  return useMemo<Values>(
    () => ({
      emails: [],
      message: '',
    }),
    []
  );
};

export const ContributionRequestListDialogContent: FC<{
  lists: ContributionRequestList[];
  closeDialog: VoidFunction;
}> = ({ lists, closeDialog }) => {
  const { sendContrinutionRequestListAccessEmail, loading } = useSendContributionRequestListAccessEmail();
  const [selectedList, setSelectedList] = useState<ContributionRequestList | undefined>(lists[0]);
  const initialValues = useInitialValues();
  const { openSnackbar } = useSnackbars();
  const { translate } = useI18n();
  const track = useTrackContributionRequestList();

  const onSubmit = useCallback(
    async (values: Values, formikHelpers: FormikHelpers<Values>) => {
      if (!selectedList) return;
      const data = values as Values;
      await sendContrinutionRequestListAccessEmail(selectedList.id, data);
      track('requestListEmailSent', selectedList, {
        emailMessageText: data.message,
        emailRecipientCount: data.emails.length,
      });
      openSnackbar({
        variant: 'info',
        open: true,
        children: (
          <ContributionRequestShareSnackbarText
            icon="PaperPlaneTiltIcon"
            i18nParams={{
              listName: selectedList.default
                ? translate('projectContributions.ContributionRequestListDialog.share.emailsSentSnackbar.everyone')
                : selectedList.name,
            }}
          >
            i18n.projectContributions.ContributionRequestListDialog.share.emailsSentSnackbar
          </ContributionRequestShareSnackbarText>
        ),
      });

      await formikHelpers.setFieldValue('emails', []);
      await formikHelpers.setFieldValue('message', '');
      closeDialog();
    },
    [sendContrinutionRequestListAccessEmail, selectedList, openSnackbar, translate, track, closeDialog]
  );

  if (!selectedList) return null;

  return (
    <Form initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
      <GridContainer spacing={0}>
        {lists.length > 1 && (
          <GridItem xs={12}>
            <ContributionRequestListSelect
              lists={lists}
              setSelectedList={setSelectedList}
              selectedList={selectedList}
            />
          </GridItem>
        )}
        <GridItem xs={12}>
          <ContributionRequestListEmailSelect lists={lists} selectedList={selectedList} />
        </GridItem>
        <GridItem xs={12} pb={1}>
          <CardSeparator fullWidth={false} />
        </GridItem>
        <GridItem xs={12} display="flex" gap={1}>
          <ContributionRequestShareButtons contributionRequestList={selectedList} loading={loading} />
        </GridItem>
      </GridContainer>
    </Form>
  );
};
