import { useMemo } from 'react';

import { type UpdateProjectTemplateMutationVariables, useUpdateProjectTemplateMutation } from '../../generated';

export const useUpdateProjectTemplate = () => {
  const [updateProjectTemplate, metadata] = useUpdateProjectTemplateMutation({
    refetchQueries: ['ProjectTemplates', 'Project'],
    awaitRefetchQueries: true,
  });

  return useMemo(
    () =>
      [
        async (
          id: UpdateProjectTemplateMutationVariables['id'],
          input: UpdateProjectTemplateMutationVariables['input']
        ) => {
          return updateProjectTemplate({ variables: { id, input } });
        },
        metadata,
      ] as const,
    [updateProjectTemplate, metadata]
  );
};
