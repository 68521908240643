import { useMemo } from 'react';

import {
  type CreateProjectInput,
  type CreateProjectMutationVariables,
  useCreateProjectMutation,
} from '../../generated';

export const useCreateProject = () => {
  const [createProject, metadata] = useCreateProjectMutation();
  return useMemo(
    () =>
      [
        (projectFolderId: CreateProjectMutationVariables['projectFolderId'], input: CreateProjectInput) =>
          createProject({ variables: { input, projectFolderId } }),
        metadata,
      ] as const,
    [createProject, metadata]
  );
};
