import type { FC } from 'react';

import { SvgIcon, type SvgIconProps } from '../SvgIcon';

export const DuplicateIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 32 32" {...props}>
    <path
      d="M15 19C15 18.7348 15.1054 18.4804 15.2929 18.2929C15.4804 18.1054 15.7348 18 16 18L18 18L18 16C18 15.7348 18.1054 15.4804 18.2929 15.2929C18.4804 15.1054 18.7348 15 19 15C19.2652 15 19.5196 15.1054 19.7071 15.2929C19.8946 15.4804 20 15.7348 20 16L20 18H22C22.2652 18 22.5196 18.1054 22.7071 18.2929C22.8946 18.4804 23 18.7348 23 19C23 19.2652 22.8946 19.5196 22.7071 19.7071C22.5196 19.8946 22.2652 20 22 20L20 20V22C20 22.2652 19.8946 22.5196 19.7071 22.7071C19.5196 22.8946 19.2652 23 19 23C18.7348 23 18.4804 22.8946 18.2929 22.7071C18.1054 22.5196 18 22.2652 18 22V20H16C15.7348 20 15.4804 19.8946 15.2929 19.7071C15.1054 19.5196 15 19.2652 15 19Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 28C10.8954 28 10 27.1046 10 26V22H6C4.89543 22 4 21.1046 4 20L4 6C4 4.89543 4.89543 4 6 4H20C21.1046 4 22 4.89543 22 6V10H26C27.1046 10 28 10.8954 28 12V26C28 27.1046 27.1046 28 26 28H12ZM26 26H12V12H26V26ZM6 20H10V12C10 10.8954 10.8954 10 12 10H20V6H6L6 20Z"
      fill="currentColor"
    />
  </SvgIcon>
);
