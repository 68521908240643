import { type FC, useCallback } from 'react';

import {
  Card,
  Checkbox,
  GridContainer,
  GridItem,
  Headline,
  Icon,
  Text,
  Tooltip,
  Translatable,
  VisuallyHidden,
  styled,
  useFormatTimeDistance,
} from '@cofenster/web-components';

import type { Project } from '../../../api/hooks/project/useProjects';
import { ExpandedRouterLink } from '../../../components/button/ExpandedRouterLink';
import { useBulkSelection } from '../../../contexts/bulkSelection/useBulkSelection';
import { useWebManagerTracking } from '../../../hooks/useWebManagerTracking';

import { PopoverMenu } from './PopoverMenu';
import { ProjectPlayButton } from './ProjectPlayButton';
import { useVideoFormatTextAndIcon } from './useVideoFormatTextAndIcon';

const Container = styled('div')<{ isSelected: boolean }>(({ isSelected, theme }) => ({
  marginTop: theme.spacing(1),
  overflow: 'clip',
  overflowClipMargin: 4,

  // 1. Make sure the checkbox container sits on top of the expanded link’s hitbox
  // 2. Visually hide the checkbox (provided it’s not checked) until the tile is interacted with
  // 3. Prevent an odd gap when the checkbox is visually hidden
  '.checkboxContainer': {
    zIndex: theme.zIndex.above, // 1
    opacity: isSelected ? 1 : 0, // 2
    marginLeft: isSelected ? '1em' : '-2.5em', // 3
    transition: '200ms',
  },

  '&:focus-within .checkboxContainer, &:hover .checkboxContainer': {
    opacity: 1,
    marginLeft: '1em',
  },

  '&:hover': {
    color: theme.palette.brand.blue,
  },
}));

const TypeText = styled(Text)(({ theme }) => ({
  marginLeft: theme.spacing(0.5),
}));

const FlexContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
}));

type Props = {
  project: Project;
  projectFolderId?: string;
  withOwner?: boolean;
  withBulkSelection?: boolean;
  refetchQueries?: string[];
};

export const ProjectRow: FC<Props> = ({
  project,
  projectFolderId,
  withOwner = true,
  withBulkSelection = true,
  refetchQueries = [],
}) => {
  const formatTimeDistance = useFormatTimeDistance();
  const { selection, toggleProject } = useBulkSelection(withBulkSelection);
  const tracking = useWebManagerTracking();
  const isSelected = selection.projects.includes(project.id);

  const onChange = useCallback(() => {
    toggleProject(project.id);

    tracking.trackEvent({
      event: isSelected ? 'projectBulkDeselected' : 'projectBulkSelected',
    });
  }, [toggleProject, project.id, isSelected, tracking]);

  const { text: videoFormatText } = useVideoFormatTextAndIcon(project.videoFormat);

  return (
    <Container data-testid="project-tile" isSelected={isSelected} id={`project-${project.id}`}>
      <Card spacing={{ vertical: 3 / 2, horizontal: withBulkSelection ? 3 / 2 : 2 }}>
        <GridContainer alignItems="center">
          {withBulkSelection && (
            <GridItem className="checkboxContainer">
              <label htmlFor={`select-${project.id}`}>
                <Checkbox checked={isSelected} onChange={onChange} id={`select-${project.id}`} />
                <VisuallyHidden i18nParams={{ name: project.name }}>
                  i18n.projects.projectCard.bulk.label
                </VisuallyHidden>
              </label>
            </GridItem>
          )}

          <GridItem xs={true}>
            <Headline variant="h6" component="p" color="inherit" data-testid="project-tile-name">
              <ExpandedRouterLink to="project" params={{ projectId: project.id }} withViewTransition>
                {project.name}
              </ExpandedRouterLink>
            </Headline>
            <Text variant="s" color="grey600" component="p">
              {withOwner && project.creator && (
                <>
                  <Translatable i18nParams={{ name: project.creator.name }}>
                    i18n.projects.projectCard.creator
                  </Translatable>{' '}
                  ·{' '}
                </>
              )}
              <Translatable
                i18nParams={{
                  timeAgo: formatTimeDistance(new Date(project.updatedAt ?? project.createdAt)),
                }}
              >
                i18n.projects.projectCard.updatedAt
              </Translatable>{' '}
            </Text>
          </GridItem>
          <GridItem xs={1} display="flex" justifyContent="center">
            {!!project.videoAsset?.videoUrl && <ProjectPlayButton project={project} size="s" />}
          </GridItem>
          <GridItem alignItems="center" display={{ xs: 'none', md: 'flex' }} xs={1.5} zIndex={2}>
            <Tooltip title="i18n.common.videoFormatLong">
              <Text variant="s" color="grey600">
                {videoFormatText}
              </Text>
            </Tooltip>
          </GridItem>
          <GridItem alignItems="center" display={{ xs: 'none', md: 'flex' }} xs={1} zIndex={2}>
            <Tooltip title="i18n.projects.projectCard.uploadedScenes">
              <FlexContainer>
                <Icon size="s" type="MovieIcon" color="grey600" />
                <TypeText variant="s" color="grey600">
                  {project.completedSceneCount}
                </TypeText>
                <Text variant="s" color="grey600">
                  {'/'}
                  {project.sceneCount}
                </Text>
              </FlexContainer>
            </Tooltip>
          </GridItem>
          <GridItem
            // Not very elegant, but nice little aesthetic improvement for the
            // home page where some rows might have a popover menu and some
            // might not due to lack of permissions. This ensures all rows are
            // aligned whether or not the popover menu is present.
            minWidth={64}
          >
            <PopoverMenu project={project} projectFolderId={projectFolderId} refetchQueries={refetchQueries} />
          </GridItem>
        </GridContainer>
      </Card>
    </Container>
  );
};
