import { useCallback } from 'react';

import { useGoto } from '@cofenster/web-components';

import { type SceneTimings, useSceneTimings } from '../../contexts/editorPlayer/usePlayerScenes';
import { useRemotionPlayerRef } from '../../contexts/editorPlayer/useRemotionPlayerRef';
import { routes } from '../../routes';

export const useGotoProjectEditScene = (
  projectId?: string,
  { replace = false, withTimeTravel = false }: { replace?: boolean; withTimeTravel?: boolean } = {}
) => {
  const goto = useGoto({ replace });
  const sceneTimings = useSceneTimings({ withExcludedScenes: false });
  const { playerRef } = useRemotionPlayerRef(false);

  const setCurrentTime = useCallback(
    (sceneTiming: SceneTimings) => {
      if (!playerRef) return;
      const isBeforeSceneStart = playerRef.currentTime < sceneTiming.start;
      const isAfterSceneEnd = playerRef.currentTime > sceneTiming.end;
      const isDuringScene = !isBeforeSceneStart && !isAfterSceneEnd;
      if (!isDuringScene) playerRef.currentTime = sceneTiming.start;
    },
    [playerRef]
  );

  return useCallback(
    (sceneId?: string, search?: Record<string, string>) => {
      if (!projectId) return;

      const options = search ? { search: new URLSearchParams(search).toString() } : undefined;

      if (!sceneId) goto(routes.projectEdit, { projectId }, options);
      else goto(routes.projectEditScene, { projectId, sceneId }, options);

      if (withTimeTravel) {
        const sceneTiming = sceneTimings.find((sceneTiming) => sceneTiming.id === sceneId);
        if (sceneTiming) setCurrentTime(sceneTiming);
      }
    },
    [goto, setCurrentTime, projectId, sceneTimings, withTimeTravel]
  );
};
