import { type MouseEvent, useCallback } from 'react';

import type { VideoFormat } from '@cofenster/constants';
import { useDialogs } from '../../../contexts/dialogs/useDialogs';
import { useTeamPermissionStatus } from '../../../contexts/user/TeamPermissionRestriction';
import { useGoToProjectContributionsPage } from '../../../hooks/navigation/useGoToProjectContributionsPage';
import { useGotoProjectEditScene } from '../../../hooks/navigation/useGotoProjectEditScene';

type Props = {
  projectId: string;
  videoFormat: VideoFormat;
  isProjectTemplate: boolean;
  index: number;
  disabled?: boolean;
  hasContributionRequestListAvailable: boolean;
};

export const useAddNewSceneCard = ({
  index,
  projectId,
  videoFormat,
  isProjectTemplate,
  hasContributionRequestListAvailable,
}: Omit<Props, 'disabled'>) => {
  const { openDialog } = useDialogs();
  const gotoProjectEdit = useGotoProjectEditScene(projectId);
  const goToProjectContributionsPage = useGoToProjectContributionsPage(projectId);
  const canUpdateProject = useTeamPermissionStatus({ has: 'ProjectUpdate' }) === 'ALLOWED';

  const closeAndNavigate = useCallback((sceneId: string) => gotoProjectEdit(sceneId), [gotoProjectEdit]);

  return useCallback(
    (event?: MouseEvent<HTMLButtonElement>) => {
      event?.preventDefault();
      openDialog('CreateScenesDialog', {
        projectId,
        videoFormat,
        index,
        isProjectTemplate,
        onCloseDialog: closeAndNavigate,
        goToProjectContributionsPage,
        hasContributionRequestListAvailable,
        canUpdateProject,
      });
    },
    [
      projectId,
      index,
      isProjectTemplate,
      openDialog,
      closeAndNavigate,
      goToProjectContributionsPage,
      videoFormat,
      hasContributionRequestListAvailable,
      canUpdateProject,
    ]
  );
};
