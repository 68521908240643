import { useEffect } from 'react';
import { tinykeys } from 'tinykeys';

export const useKeypress = (
  action: (event: KeyboardEvent) => void,
  shortcut: string,
  options?: {
    disabled?: boolean;
    element?: HTMLElement | Window;
    ignoreOnFocusedInput?: boolean;
    ignoreOnFocusedButton?: boolean;
  }
) => {
  useEffect(() => {
    if (options?.disabled) return;

    const element = options?.element ?? window;
    const ignoreOnFocusedInput = options?.ignoreOnFocusedInput ?? true;
    const ignoreOnFocusedButton = options?.ignoreOnFocusedButton ?? false;

    const unsubscribe = tinykeys(element, {
      [shortcut]: (event) => {
        const target = event.target as HTMLElement;

        const fieldInFocus = target.tagName === 'INPUT' || target.tagName === 'TEXTAREA';
        const buttonInFocus = target.tagName === 'BUTTON' || target.getAttribute('role') === 'button';
        const labelInFocus = target.tagName === 'LABEL' && target.tabIndex !== undefined;

        if (fieldInFocus && ignoreOnFocusedInput) return;
        if ((buttonInFocus || labelInFocus) && ignoreOnFocusedButton) return;

        event.preventDefault();
        action(event);
      },
    });

    return () => unsubscribe();
  }, [action, shortcut, options]);
};
