import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { useKeypress } from '@cofenster/web-components';

import { useIntroOutro } from '../../../contexts/editorPlayer/useIntroOutro';
import { useScenes } from '../../../contexts/scenes/useScenes';
import { useGotoEditorScene } from '../../../hooks/navigation/useGotoEditorScene';

export const useMoveSceneKeyboardHandler = () => {
  const { projectId, sceneId } = useParams();
  const { scenes } = useScenes();
  const { isUploaded } = useIntroOutro();

  // We cannot use the `goToNextScene` and `goToPreviousScene` from the context
  // because they discard the excluded and empty scenes on purposes (since they
  // are intended for the player navigation). In this case, we want to indiscri-
  // minately move up and down the scene list without care for the state of the
  // scenes.
  const gotoEditorScene = useGotoEditorScene(projectId as string);

  const firstScene = scenes[0];
  const lastScene = scenes[scenes.length - 1];

  const currentlyOnIntro = sceneId === 'intro';
  const currentlyOnOutro = sceneId === 'outro';

  // If we are currently on the intro, move to the first scene (provided there
  // is one). If we are on any scene but the outro, check the current scene ID:
  // if we’re on the last scene, move to the outro (if present); if we’re on any
  // other scene, move down to the next scene.
  const goToNextScene = useCallback(() => {
    if (currentlyOnIntro) {
      if (firstScene) gotoEditorScene(firstScene.id);
    } else if (currentlyOnOutro) {
      // Nothing to do
    } else {
      const currentlyOnlastScene = sceneId === lastScene?.id;
      if (currentlyOnlastScene && isUploaded('outro')) {
        gotoEditorScene('outro');
      } else {
        const currentIndex = scenes.findIndex((scene) => scene.id === sceneId);
        const nextScene = scenes[currentIndex + 1];
        if (nextScene) gotoEditorScene(nextScene.id);
      }
    }
  }, [isUploaded, sceneId, scenes, gotoEditorScene, firstScene, lastScene, currentlyOnIntro, currentlyOnOutro]);

  // If we are currently on the outro, move to the last scene (provided there is
  // one). If we are on any scene but the intro, check the current scene ID: if
  // we’re on the first scene, move to the intro (if present); if we’re on any
  // other scene, move up to the previous scene.
  const goToPrevScene = useCallback(() => {
    if (currentlyOnOutro) {
      if (lastScene) gotoEditorScene(lastScene.id);
    } else if (currentlyOnIntro) {
      // Nothing to do
    } else {
      const currentlyOnfirstScene = sceneId === firstScene?.id;
      if (currentlyOnfirstScene && isUploaded('intro')) {
        gotoEditorScene('intro');
      } else {
        const currentIndex = scenes.findIndex((scene) => scene.id === sceneId);
        const previousScene = scenes[currentIndex - 1];
        if (previousScene) gotoEditorScene(previousScene.id);
      }
    }
  }, [isUploaded, sceneId, scenes, gotoEditorScene, firstScene, lastScene, currentlyOnIntro, currentlyOnOutro]);

  useKeypress(goToNextScene, 'ArrowDown');
  useKeypress(goToPrevScene, 'ArrowUp');
};
