import { Component, type ErrorInfo, type PropsWithChildren, type ReactNode } from 'react';

type State = {
  hasError: boolean;
};

export class ErrorBoundary extends Component<
  PropsWithChildren<{
    fallback: ReactNode;
    onError: (error: Error, errorInfo: ErrorInfo) => void;
  }>,
  State
> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.props.onError(error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return this.props.fallback;
    }

    return this.props.children;
  }
}
