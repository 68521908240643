import { useMemo } from 'react';

import {
  type IntroOutroRenderDescriptionQueryResult,
  type IntroOutroRenderDescriptionQueryVariables,
  useIntroOutroRenderDescriptionQuery,
} from '../../generated';

export type IntroOutroRenderDescription = NonNullable<
  NonNullable<IntroOutroRenderDescriptionQueryResult['data']>['introOutroRenderDescription']
>;

export const useIntroOutroRenderDescription = (
  projectId: IntroOutroRenderDescriptionQueryVariables['projectId'],
  videoType: IntroOutroRenderDescriptionQueryVariables['videoType'],
  skip?: boolean
) => {
  const { loading, error, data } = useIntroOutroRenderDescriptionQuery({
    variables: { projectId, videoType },
    skip,
    // It is necessary to use the `cache-and-network` policy so that the scene
    // render description gets properly refreshed after, for instance, updating
    // the project theme (such as turning off the usage of the logo).
    fetchPolicy: 'cache-and-network',
  });
  const introOutroRenderDescription = data?.introOutroRenderDescription ?? undefined;
  return useMemo(
    () => ({ introOutroRenderDescription, loading, error }),
    [introOutroRenderDescription, loading, error]
  );
};
