import type { FC } from 'react';

import {
  BlankButton,
  type I18nParams,
  Icon,
  Responsive,
  Translatable,
  Typography,
  styled,
} from '@cofenster/web-components';

const IncludeSceneButtonLink = styled(BlankButton)(({ theme }) => ({
  color: 'inherit',
  alignItems: 'center',
  display: 'inline-flex',
  gap: theme.spacing(1),

  '&:hover': { textDecoration: 'underline' },
  '&:focus-visible': theme.mixins.focusRing,
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),

  padding: theme.spacing(1.5, 3, 1.5, 2),
  whiteSpace: 'nowrap',
  maxWidth: '100%',

  color: theme.palette.brand.white,
  backgroundColor: theme.palette.brand.carbon_alpha50,
  backdropFilter: 'blur(13px)',
  borderRadius: theme.shape.borderRadius,

  position: 'absolute',
  top: theme.spacing(3),
  left: theme.spacing(3),
}));

export const EditorExcludedSceneHint: FC<{ enable: VoidFunction }> = ({ enable }) => {
  const i18nParams: I18nParams = {
    link: (chunk) => (
      <IncludeSceneButtonLink onClick={enable}>
        <Icon type="EyeIcon" size="s" />
        {chunk}
      </IncludeSceneButtonLink>
    ),
  };

  return (
    <>
      <StyledTypography variant="l" component="p">
        <Responsive up="sm" display="inline" component="span">
          <Translatable i18nParams={i18nParams}>i18n.projectEditor.hint.hidden</Translatable>
        </Responsive>
        <Responsive down="sm" display="inline" component="span">
          <Translatable i18nParams={i18nParams}>i18n.projectEditor.hint.hidden.short</Translatable>
        </Responsive>
      </StyledTypography>
    </>
  );
};
