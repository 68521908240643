import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';

export type SentryOptions = {
  stage: string;
  dsn: string;
  release?: string;
  useReactRouter?: boolean;
};

export const getClientConfig = ({ stage, dsn, release, useReactRouter }: SentryOptions): Sentry.BrowserOptions => {
  const browserTracing = useReactRouter
    ? Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      })
    : Sentry.browserTracingIntegration();

  return {
    enabled: ['staging', 'production'].includes(stage),
    dsn,
    environment: stage,
    release,
    integrations: [browserTracing, Sentry.browserProfilingIntegration()],
    // Ignore Axios network errors since they are unactionable by nature
    // E.g.: https://cofenster.sentry.io/issues/5457988783/?referrer=slack&notification_uuid=2734668d-568c-4e16-8ec9-64cda1942ac0&environment=production&alert_rule_id=1117031&alert_type=issue
    ignoreErrors: [/Network Error/, /Failed to fetch/],
    // Record 10% of transactions for performance tracing purposes (to stay
    // within the 100,000 monthly quota)
    tracesSampleRate: 0.1,
    // Since `profilesSampleRate` is relative to `tracesSampleRate`, the final
    // profiling rate can be computed as tracesSampleRate * profilesSampleRate
    // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5
    // would results in 25% of transactions being profiled (0.5 * 0.5 = 0.25)
    profilesSampleRate: 1.0,
    beforeBreadcrumb(breadcrumb, hint) {
      if (breadcrumb.category === 'ui.click' && hint) {
        try {
          const text = hint.event.target.innerText.trim();

          if (text) {
            breadcrumb.message = hint?.event.target.innerText;
          }
        } catch {}
      }

      return breadcrumb;
    },
  };
};
