import { useRefOfState } from '@cofenster/web-components';
import { useCallback, useEffect, useMemo, useState } from 'react';

export type MultiSelect<T> = {
  selectedItems: T[];
  isSelected(item: T): boolean;
  selectAll(): void;
  clearSelection(): void;
  toggleSelection(item: T): void;
  setSelection(selectedItems: T[]): void;
  selectRangeTo(item: T): void;
};

type EqualityFn<T> = (a: T, b: T) => boolean;

const uniquePreserveFirstOrder = <T>(items: T[]) => items.filter((item, index, items) => items.indexOf(item) === index);

const includedInSuperset = <T>(superset: T[], subset: T[], equals: EqualityFn<T> = Object.is) =>
  subset.filter((item) => superset.findIndex((it) => equals(it, item)) !== -1);

const EMPTY_ARRAY: unknown[] = [];

export const useMultiSelect = <T>(
  items: T[],
  initiallySelected?: T[],
  equals: EqualityFn<T> = Object.is
): MultiSelect<T> => {
  const extractItemReference = useCallback((item: T) => items.find((i) => equals(i, item)), [items, equals]);

  const [selectedItems, setSelectedItems] = useState(() =>
    includedInSuperset(items, uniquePreserveFirstOrder(initiallySelected ?? []), equals)
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: we want to run this effect whenever items change
  useEffect(() => {
    setSelectedItems(includedInSuperset(items, selectedItems, equals));
  }, [items, equals]);

  const itemsRef = useRefOfState(items);

  const selectAll = useCallback(() => setSelectedItems(itemsRef.current), [itemsRef]);
  const clearSelection = useCallback(() => setSelectedItems(EMPTY_ARRAY as T[]), []);
  const toggleSelection = useCallback(
    (item: T) => {
      const itemReference = extractItemReference(item);
      if (!itemReference) return;
      setSelectedItems((selectedItems) => {
        if (selectedItems.includes(itemReference)) {
          return selectedItems.filter((selectedItem) => selectedItem !== itemReference);
        }
        return [...selectedItems, itemReference];
      });
    },
    [extractItemReference]
  );

  const isSelected = useCallback(
    (item: T) => {
      const itemReference = extractItemReference(item);
      if (!itemReference) return false;
      return selectedItems.includes(itemReference);
    },
    [extractItemReference, selectedItems]
  );

  const setSelection = useCallback(
    (selectedItems: T[]) => setSelectedItems(includedInSuperset(itemsRef.current, selectedItems, equals)),
    [itemsRef, equals]
  );

  const selectRangeTo = useCallback(
    (item: T) => {
      const itemReference = extractItemReference(item);
      if (!itemReference) return;
      setSelectedItems((selectedItems) => {
        const first = selectedItems[0];
        if (!first) return [itemReference];
        if (first === itemReference) return selectedItems;
        const firstIndex = itemsRef.current.indexOf(first);
        const lastIndex = itemsRef.current.indexOf(itemReference);
        return firstIndex < lastIndex
          ? itemsRef.current.slice(firstIndex, lastIndex + 1)
          : itemsRef.current.slice(lastIndex, firstIndex + 1).toReversed();
      });
    },
    [itemsRef, extractItemReference]
  );

  return useMemo(
    () => ({
      selectedItems,
      isSelected,
      selectAll,
      clearSelection,
      toggleSelection,
      setSelection,
      selectRangeTo,
    }),
    [selectedItems, isSelected, selectAll, clearSelection, toggleSelection, setSelection, selectRangeTo]
  );
};
