import type { ComponentProps, FC } from 'react';
import { useProject } from '../../../contexts/project/useProject';
import { FullPageWithTitleAndActions } from '../FullPageWithTitleAndActions';
import { PROJECT_TEMPLATE_NOTIFICATION } from './ProjectLayout';

export const ProjectFullPageLayout: FC<Omit<ComponentProps<typeof FullPageWithTitleAndActions>, 'pageNotification'>> = (
  props
) => {
  const { project } = useProject();

  return (
    <FullPageWithTitleAndActions
      {...props}
      pageNotification={project?.isProjectTemplate ? PROJECT_TEMPLATE_NOTIFICATION : undefined}
    />
  );
};
