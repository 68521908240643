import { Typography } from '@cofenster/web-components';
import { useMemo } from 'react';
import { useDeleteAssetFromContributionRequest } from '../../../../../api/hooks/contributionRequest/useDeleteAssetFromContributionRequest';
import { useConfirmDialog } from '../../../../../hooks/useConfirmDialog';
import { useWebManagerTracking } from '../../../../../hooks/useWebManagerTracking';
import type { ContributionItem } from './useFilterContributionRequests';

export const useDeleteContributionsWithConfirmation = (items: ContributionItem[]) => {
  const { deleteAssetFromContributionRequest } = useDeleteAssetFromContributionRequest();
  const scenesCount = useMemo(() => items.reduce((acc, item) => acc + (item.asset.scenes?.length ?? 0), 0), [items]);
  const tracking = useWebManagerTracking();

  return useConfirmDialog({
    title: 'i18n.projectContributions.contributions.deleteMultipleAssetConfirmation.title',
    titleI18nParams: { contributionsCount: items.length },
    content: (
      <Typography variant="m" i18nParams={{ scenesCount, contributionsCount: items.length }}>
        {scenesCount === 0
          ? 'i18n.projectContributions.contributions.deleteMultipleAssetConfirmation.description.noScenes'
          : 'i18n.projectContributions.contributions.deleteMultipleAssetConfirmation.description'}
      </Typography>
    ),
    onConfirm: async () => {
      await deleteAssetFromContributionRequest(
        items.map((item) => ({
          assetId: item.asset.id,
          contributionRequestId: item.contributionRequest.id,
        }))
      );
      tracking.trackEvent({
        event: 'deleteContributions',
        details: {
          assetIds: items.map((item) => item.asset.id),
        },
      });
    },
    confirm: 'i18n.global.delete',
  });
};
