import { useSnackbars } from '@cofenster/web-components';
import { useCallback } from 'react';
import { useDuplicateProjectTemplate } from '../../../api/hooks/projectTemplate/useDuplicateProjectTemplate';

export const useOnDuplicateProjectTemplate = (projectTemplateId: string) => {
  const [duplicateProjectTemplate] = useDuplicateProjectTemplate(projectTemplateId);
  const { openSnackbar } = useSnackbars();

  return useCallback(async () => {
    await duplicateProjectTemplate();
    openSnackbar({
      children: 'i18n.projectTemplates.duplicateProjectTemplate.snackbar.success',
      variant: 'success',
    });
  }, [duplicateProjectTemplate, openSnackbar]);
};
