import type { FormikValues } from 'formik';
import { type FC, useCallback, useMemo } from 'react';
import * as Yup from 'yup';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Form,
  FormSubmitButton,
  FormTextField,
} from '@cofenster/web-components';
import { useUpdateProjectTemplate } from '../../../api/hooks/projectTemplate/useUpdateProjectTemplate';
import { useWebManagerTracking } from '../../../hooks/useWebManagerTracking';

type Values = {
  name: string;
  description?: string;
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required('i18n.form.error.generic.required'),
  description: Yup.string().notRequired(),
});

const useInitialValues = (name: string, description: string | undefined) => {
  return useMemo<Values>(
    () => ({
      name,
      description,
    }),
    [name, description]
  );
};

const useSubmit = (projectTemplateId: string, closeDialog: () => unknown) => {
  const [updateProjectTemplate] = useUpdateProjectTemplate();
  const tracking = useWebManagerTracking();

  const trackProjectTemplateRenamed = useCallback(
    (name: string) => {
      tracking.trackEvent({
        event: 'projectTemplateRename',
        details: {
          projectTemplateId,
          name,
        },
      });
    },
    [projectTemplateId, tracking]
  );

  return useCallback(
    async (values: FormikValues) => {
      const data = values as Values;
      await updateProjectTemplate(projectTemplateId, data);
      closeDialog();
      trackProjectTemplateRenamed(data.name);
    },
    [projectTemplateId, updateProjectTemplate, closeDialog, trackProjectTemplateRenamed]
  );
};

export type RenameProjectTemplateDialogProps = {
  isOpen: boolean;
  closeDialog: () => unknown;
  projectTemplateId: string;
  name: string;
  description: string | undefined;
};

export const RenameProjectTemplateDialog: FC<RenameProjectTemplateDialogProps> = ({
  isOpen,
  closeDialog,
  projectTemplateId,
  name,
  description,
}) => {
  const initialValues = useInitialValues(name, description);
  const onSubmit = useSubmit(projectTemplateId, closeDialog);

  return (
    <Dialog
      open={isOpen}
      onClose={closeDialog}
      data-testid="project-template-rename-dialog"
      title="i18n.projectTemplates.renameProjectTemplateDialog.headline"
    >
      <Form initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        <DialogContent>
          <FormTextField
            id="projectTemplateNewName"
            name="name"
            label="i18n.projectTemplates.createProjectTemplateForm.name"
            autoFocus
            data-testid="project-template-rename-name-input"
          />
          <FormTextField
            id="projectTemplateNewDescription"
            name="description"
            label="i18n.projectTemplates.createProjectTemplateForm.description"
            placeholder="i18n.projectTemplates.createProjectTemplateForm.descriptionPlaceholder"
            data-testid="project-template-rename-description-input"
            multiline
            rows={5}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="tertiary" fullWidth onClick={closeDialog}>
            i18n.global.cancel
          </Button>
          <FormSubmitButton autoDisable fullWidth data-testid="project-template-rename-dialog-button">
            i18n.global.save
          </FormSubmitButton>
        </DialogActions>
      </Form>
    </Dialog>
  );
};
