import { useMemo } from 'react';
import { type DeleteProjectTemplateMutationVariables, useDeleteProjectTemplateMutation } from '../../generated';

export const useDeleteProjectTemplate = (id: DeleteProjectTemplateMutationVariables['id']) => {
  const [deleteProjectTemplate, metadata] = useDeleteProjectTemplateMutation({
    refetchQueries: ['ProjectTemplates'],
    awaitRefetchQueries: true,
  });

  return useMemo(
    () => [() => deleteProjectTemplate({ variables: { id } }), metadata] as const,
    [deleteProjectTemplate, metadata, id]
  );
};
